import React, {useState} from 'react';
import Cards from "../reuseableComponents/Cards";
import './overview.scss';
import ProgressChart from "./ProgressChart";
import BarChart from "../../../Reports/Barchart";
import LineChart from "../../../Reports/LineChart";
import {Checkbox, Modal, Button} from "antd";
const Overview = (props) => {
    const [state, setState] = useState({
        tCustomers: false,
        mac: false
    })
    let {title, value, modalOpenFunc, isModalOpen} = props
    const data = {
        labels: ['January', 'February', 'March', 'May', 'June'],
        datasets: [
            {
                data: [65, 59, 80, 81, 56],
                backgroundColor: '#10163a', // Bar color
                borderWidth: 1, // Border width,
                width: '70%'
            },
        ],
    };
    const modalFooter = [
        <Button
            key="cancel"
            onClick={() => modalOpenFunc()}
        >
            Cancel
        </Button>
    ];
    return <div>
        <div className='count-card-container'>
            <Cards title={'Total Message Sent'} value={'1,432'}/>
            <Cards title={'Total Message Received'} value={'2,662'}/>
            <Cards title={'Total Customers'} value={'223'}/>
        </div>
        <div className='count-card-container' style={{
            height: 250,
            display: 'grid',
            gridTemplateColumns: '22% 22% 56%',
            justifyContent: 'space-between'
        }}>
            <ProgressChart
                titleOne={'Used'}
                valueOne={'11'}
                titleTwo={'Tier Limit'}
                valueTwo={'20'}
                heading={'Month Active Users (Current Month)'}
            />
            <ProgressChart
                titleOne={'Total Agent Seats'}
                valueOne={'7'}
                titleTwo={'Active Agent Seats'}
                valueTwo={'3'}
                heading={'Agent Seats'}
            />
            <div style={{width: '97%'}} className={'cards-box'}>
                <BarChart height={210} data={data} heading={'Monthly Active Users (Last Six Months)'}/>
            </div>
        </div>
        <LineChart heading={'Messages Delivered'}/>
        <LineChart heading={'Messages Received'}/>
        <Modal
            title={'Export Insights'}
            open={isModalOpen}
            onCancel={() => {
                modalOpenFunc()
                setState({...state, mac: false, tCustomers: false})
            }
            }
            footer={modalFooter}
            closable={() => {
                modalOpenFunc()
                setState({...state, mac: false, tCustomers: false})
            }}
        >
            <div style={{fontWeight: '550'}}>Include breakdown by</div>
            <div style={{marginTop: 6}}/>
            <Checkbox
                checked={state.mac}
                onChange={() => setState({...state, mac: !state.mac})}
            >Monthly Active Users</Checkbox>
            <div style={{marginTop: 4}}/>
            <Checkbox
                checked={state.tCustomers}
                onChange={() => setState({...state, tCustomers: !state.tCustomers})}
            >Total Customers</Checkbox>
        </Modal>
    </div>
}

export default Overview;