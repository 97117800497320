import React from "react"

const InfoNote = (props) => {
    return <div className="info-box">
        <div className="info-box-first">
            <div style={{width:"80%"}}>
                <div className="infoheading">{props.heading}
                </div>
                <div className="infodescription">
                    {props.description}
                </div>
            </div>
            <div className="intobtn">{props.btn}</div>
        </div>
    </div>
}
export default InfoNote