import React, {useEffect, useRef, useState} from 'react';
import {Input, Button, Space, Table} from 'antd';
import {IoIosAddCircleOutline} from 'react-icons/io';
import EditIcon from '@mui/icons-material/Edit';
import {MoreOutlined, SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import {
    activateQuickReply,
    deActivateQuickReply,
    deleteQuickReply,
    getAllQuickReply,
    saveQuickReply,
    updateQuickReply
} from "./API/Api";
import {Dropdown, Modal, Menu} from 'antd';
import Loader from "../../ResuseableComponnets/Loader";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';

const Quickreply = (props) => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        openDialogue: false,
        name: "",
        text: "",
        isLoader: false,
        data: [],
        selectedData: null,
        mode: ""
    })
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 8,
    });

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title: "Name",
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ...getColumnSearchProps('title'),
        },
        {
            title: "Text",
            dataIndex: 'text',
            key: 'text',
            width: '30%',
            ...getColumnSearchProps('text'),
        },
        {
            title: "Status",
            dataIndex: 'status',
            key: 'status',
            ...getColumnSearchProps('status')
        },
        {
            title: "Actions",
            dataIndex: 'action',
            key: 'action',
            ...getColumnSearchProps('action'),
        },
    ];

    const updatedArray = state.data.map((obj) => ({
        ...obj,
        'status': obj.status ? "Active" : "De-activated",
        action: (
            <Dropdown overlay={(
                <Menu>
                    <Menu.Item key="1">
                        <div onClick={() => setState({
                            ...state,
                            openDialogue: true,
                            selectedData: obj,
                            mode: "activate"
                        })}>
                            <DoneIcon/> {t("ActivateFlow")}
                        </div>
                    </Menu.Item>
                    <Menu.Item key="2">
                        <div onClick={() => setState({
                            ...state,
                            openDialogue: true,
                            selectedData: obj,
                            mode: "de-activate"
                        })}>
                            <RemoveIcon/> {t("De-activateFlow")}
                        </div>
                    </Menu.Item>
                    <Menu.Item key="3">
                        <div onClick={() => setState({
                            ...state,
                            openDialogue: true,
                            selectedData: obj,
                            mode: "delete"
                        })}>
                            <DeleteIcon/> {t("Delete")}
                        </div>
                    </Menu.Item>
                    <Menu.Item key="4">
                        <div onClick={() => setState({
                            ...state,
                            openDialogue: true,
                            selectedData: obj,
                            mode: "update",
                            name:obj.name,
                            text:obj.text
                        })}>
                            <EditIcon/> {t("Update")}
                        </div>
                    </Menu.Item>
                </Menu>
            )} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                    <MoreOutlined style={{fontSize: '14px'}}/>
                </a>
            </Dropdown>
        )
    }))

    const getAllFunc = () => {
        setState({...state, isLoader: true})
        getAllQuickReply().then(res => setState({
            ...state,
            openDialogue: false,
            isLoader: false,
            data: res?.data?.data,
            mode: ""
        })).catch((err) => console.log(err))
    }

    const saveFunc = () => {
        setState({...state, isLoader: true})
        let data = {
            name: state.name,
            text: state.text
        }
        saveQuickReply(data).then(() => {
            getAllFunc()
        }).catch(() => setState({...state, isLoader: false}))
    }

    const deActivateFunc = ()=> {
        let id = state.selectedData.id
        deActivateQuickReply(id)
            .then(()=>getAllFunc())
            .catch(()=>setState({...state, isLoader: false}))
    }

    const activateFunc = ()=> {
        let id = state.selectedData.id
        activateQuickReply(id)
            .then(()=>getAllFunc())
            .catch(()=>setState({...state, isLoader: false}))
    }

    const updateFunc = ()=> {
        let id = state.selectedData.id
        let data = {
            name:state.name,
            text:state.text
        }
        updateQuickReply(id, data)
            .then(()=>getAllFunc())
            .catch(()=>setState({...state, isLoader: false}))
    }

    const deleteFunc = ()=> {
        let id = state.selectedData.id
        deleteQuickReply(id)
            .then(()=>getAllFunc())
            .catch(()=>setState({...state, isLoader: false}))
    }

    useEffect(() => {
        getAllFunc()
    }, []);

    return (
        state.isLoader ? <Loader/> :
            <>
                <div className="synctic-module-container">
                    <div className='cards-box'
                         style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <div className="synctic-module-def">
                            <div className='heading'> {t("QuickReply")}</div>
                        </div>
                        <Button
                            type="primary"
                            style={{float: 'right', marginTop: '1%'}}
                            onClick={() => {
                                setState({
                                    ...state,
                                    openDialogue: true,
                                    mode: "add",
                                    name: "",
                                    text: ""
                                })
                            }}
                        >
                            <IoIosAddCircleOutline
                                style={{fontSize: '15px', color: '#fff', marginRight: '12px', marginTop: '-2%'}}
                            />
                            {t("AddQuickReply")}
                        </Button>
                    </div>
                    <div style={{marginTop: '5%'}}>
                        <Table
                            pagination={pagination}
                            onChange={(page) => {
                                setPagination(page);
                            }}
                            style={{marginTop: '5%'}}
                            columns={columns}
                            dataSource={updatedArray}
                        />
                    </div>
                </div>
                <Modal
                    title={ state.mode==="activate" ?
                        t("Areyousureyou") :
                        state.mode==="de-activate" ?
                            t("Areyousureyoude-activate") :
                            state.mode==="delete" ?
                                t("Areyousuredelete"):
                                state.mode==="update" ?
                                "Update":
                        t("AddNewReply")
                }
                    open={state.openDialogue}
                    onCancel={() => setState({
                        ...state,
                        openDialogue: false,
                        selectedData: null,
                        mode:""
                    })}
                    onOk={() => {
                        state.mode==="de-activate" ? deActivateFunc() :
                            state.mode==="activate" ? activateFunc() :
                            state.mode==="delete" ? deleteFunc() :
                            state.mode==="update" ? updateFunc() : saveFunc()
                    }}>
                    {
                        (state.mode==="update" || state.mode==="add") &&
                            <>
                            <Input style={{marginTop: 10}} value={state.name}
                                   onChange={(e) => setState({...state, name: e.target.value})}
                                   placeholder={t("Enter-Name")}/>
                            <Input style={{marginTop: 10}} value={state.text}
                                   onChange={(e) => setState({...state, text: e.target.value})}
                                   placeholder={t("Entertext")}/>
                        </>
                    }
                </Modal>
            </>
    );
};

export default Quickreply;
