import React from "react";

const SettingsHeader = (props) => {
    return <div className="cards-box">
        <div>
            <div className='synctic-module-def'>
                {props.icon}
                <div className="heading">{props.heading}</div>
                <div className="sub-heading">{props.description}</div>
            </div>
        </div>
    </div>
}
export default SettingsHeader