import React from 'react';
import {useParams} from 'react-router-dom';
import {
    addInstagramChannels,
    addMessengerChannels,
    addWhatsappChannel,
    getAllInstagramChannels,
    getAllMessengerChannels,
    getAllWhatsappChannels
} from "./API/Api";
import TableContent from "./tableContent";

const ChannelsTable = () => {
    let {id}=useParams()
    return id==="whatsapp" ? <TableContent
        mode={"whatsapp"}
        getAllData={getAllWhatsappChannels}
        addChannel={addWhatsappChannel}
    /> : id==="messenger" ? <TableContent
        mode={"facebook"}
        getAllData={getAllMessengerChannels}
        addChannel={addMessengerChannels}
    /> : <TableContent
        mode={"instagram"}
        getAllData={getAllInstagramChannels}
        addChannel={addInstagramChannels}
    />
}
export default ChannelsTable