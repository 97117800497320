import React, {useEffect, useState} from 'react';
import {SiShopify} from "react-icons/si";
import {Input, Button, Checkbox} from 'antd';
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader';
import "./Shopify.scss";
import {getShopify, saveShopify} from "./API/Api";
import Loader from "../../ResuseableComponnets/Loader";
import { useTranslation } from 'react-i18next';
const eventNotifications = [
    
];
const requireAccess = [
    
]

const Shopify = () => {
    const [state, setState] = useState({
        storeUrl: "",
        secretKey: "",
        shipped: false,
        cancelled: false,
        confirmed: false
    })
    const [isLoader, setIsLoader] = useState(false)

    const saveFunc = () => {
        let data  = {
            storeUrl: state.storeUrl,
            secretKey: state.secretKey,
            orders: {
                shipped: state.shipped,
                cancelled: state.cancelled,
                confirmed: state.confirmed
            }
        }
        setIsLoader(true)
        console.log(data, 2222211112)
        saveShopify(data).then(()=>getFunc()).catch(()=>setIsLoader(false))
    }
    const getFunc = () => {
        setIsLoader(true)
        getShopify().then(res=> {
            let data = res.data.data
            setState({...state,
                storeUrl: data.storeUrl,
                secretKey: data.secretKey,
                shipped: data.orders.shipped,
                cancelled: data.orders.canceled,
                confirmed: data.orders.confirmed
            })
            setIsLoader(false)
        }).catch(()=>setIsLoader(false))
    }

    console.log(state, 99987544111)
    useEffect(() => {
        getFunc()
    }, []);
    const { t } = useTranslation();
    return (
        isLoader ? <Loader/> :
        <div className='synctic-module-container' style={{display: "flex", flexDirection: "column"}}>
            <SettingsHeader heading="Shopify"
                            icon={<SiShopify className='icon' style={{color: "#319531"}}/>}/>
            <div className='Shopify-container'>
                <div className='Connection'>{t("Connection")}</div>
                <div className='requireAccess'>
                {t("Yourequireaccess-discription")}
                </div>
                {['Shopify Store URL', 'Token'].map((label, index) => (
                    <div key={index} style={{display: "flex", flexDirection: "column", marginTop: "2%"}}>
                        <label className='Shopify-Store'>{label}</label>
                        <Input
                            onChange={(e)=>label==="Token" ?
                                setState({...state, secretKey: e.target.value }) :
                                setState({...state, storeUrl: e.target.value })
                        }
                            value={label==="Token" ? state.secretKey : state.storeUrl}
                            style={{width: "67%"}} size="large" placeholder={`Enter ${label} here`}
                               autoComplete="off"
                        />
                    </div>
                ))}
                <div className='Event-Notifications'>{t("EventNotifications")}</div>
                <div className='Event-Notifications-pre'>
                    {eventNotifications}
                </div>
                <div className='Order-Shipped'>
                        <Checkbox checked={state.shipped}
                                  onChange={
                            ()=>setState({
                                ...state,
                                shipped: !state.shipped
                            })}
                                  style={{fontWeight: '500'}}>{t("OrderShipped")}</Checkbox>
                        <Checkbox checked={state.cancelled}
                                  onChange={()=>setState({
                            ...state,
                            cancelled: !state.cancelled
                        })} style={{fontWeight: '500'}}>{t("OrderCancelled")}</Checkbox>
                        <Checkbox checked={state.confirmed}
                                  onChange={()=>setState({
                                      ...state,
                                      confirmed: !state.confirmed
                                  })} style={{fontWeight: '500'}}>{t("OrderConfirmation")}</Checkbox>
                </div>
                <Button type="primary" style={{marginTop: "3%", backgroundColor: 'rgb(9 179 67)'}}
                        onClick={()=>saveFunc()}>{t("Connect")}</Button>
            </div>
        </div>
    );
}

export default Shopify;
