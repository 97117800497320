import React, {useState} from 'react';
import {ReactMic} from 'react-mic';
import {FaMicrophone, FaMicrophoneSlash} from "react-icons/fa";
import {MdDelete} from "react-icons/md";
import axios from '../Authentication/headers';

function Audio(props) {
    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);

    const startRecording = () => {
        setIsRecording(true);
        props.isBlobExist(true)
    };

    const stopRecording = () => {
        setIsRecording(false);
    };

    const onData = (recordedBlob) => {
        // Do something with the recorded audio data if needed
    };

    const onStop = (recordedBlob) => {
        setAudioBlob(recordedBlob.blob);
        setAudioUrl(URL.createObjectURL(recordedBlob.blob));
    };

    const sendAudio = () => {
        const formData = new FormData();
        formData.append('media', audioBlob, 'audioFile');

        formData.append('data', JSON.stringify(props.data));
        axios.post(props.url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        })
            // .then(() => {
            //     setState({...state, replyMesssage: '', fileList: [], openDrawer: false})
            // })
            // .catch((err) => {
            //     console.log(err, 'Err')
            // });


        if (!audioBlob) {
            console.error('No audio recorded');
            return;
        }

        // const formData = new FormData();
        formData.append('media', audioBlob, 'audio.wav'); // 'audio.wav' can be any filename

        fetch('/upload-audio', {
            method: 'POST',
            body: formData
        })
    };

    return (
        <div>
            <div style={{display:'flex', alignItems:'center', gap:10}}>
            <div>
                <div style={{
                    display: isRecording ? '' : 'none',
                }}>
                    <ReactMic
                        record={isRecording}
                        className="sound-wave"
                        onStop={onStop}
                        onData={onData}
                        width={170}
                        height={40}
                        strokeColor="#000000"
                    />
                </div>
            </div>
            {
                !audioBlob && isRecording === false ?
                    <FaMicrophone style={{fontSize:20, opacity:.8}} onClick={() => startRecording()}/>
                    :
                    !audioBlob && <FaMicrophoneSlash style={{fontSize:20, opacity:.8}} onClick={() => stopRecording()}/>
            }
            </div>
            {
                !!audioBlob &&
                <div style={{
                    display:'flex',
                    alignItems:'center',
                }}>
                    {
                        console.log(audioUrl, 6666535434322)
                    }
                    <audio style={{height:40}} controls src={audioUrl}></audio>
                    <MdDelete style={{fontSize:20, marginLeft:10, opacity:.8}} onClick={() => {
                        setAudioBlob(null)
                        props.isBlobExist(false)
                    }}/>
                </div>
            }
            {/*<button onClick={sendAudio} type="button">Send Audio</button>*/}
        </div>
    );
}

export default Audio;