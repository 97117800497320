import {combineReducers} from "redux";
import inboxReducer from "./inboxReducer";
import myProfileReducer from "./myProfileReducer";
import flowReducer from "../../../ChatBotBuilder/states/Reducers/flowReducer";

const reducers = combineReducers({
    inbox:inboxReducer,
    me:myProfileReducer,
    chatFlow:flowReducer
})

export default reducers