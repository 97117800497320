import Logo from "../../assets/media/misc/TieBot PART 2.gif";
import React, {useEffect, useState} from "react";
import SearchIcon from "@mui/icons-material/Search";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import MessageIcon from "@mui/icons-material/Message";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import BarChartIcon from "@mui/icons-material/BarChart";
import CampaignIcon from "@mui/icons-material/Campaign";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import FavoriteIcon from "@mui/icons-material/Favorite";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";
import profilePic from "../../assets/media/avatars/300-1.jpg";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import {useNavigate} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import ActionsCreators from "../Inbox/states";
import {useDispatch} from "react-redux";
import {useMediaQuery} from 'react-responsive';
import Drawer from "@mui/material/Drawer";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {RobotFilled} from '@ant-design/icons';

const LeftSection = (props) => {
    const [state, setState] = useState({
        collapse: true,
        open: false,
        settings: false,
        asideState: [],
        activeTab: "Analytics",
        inboxActive: "",
        isDrawerOpen: false,
        activeSubTab: "",
        subMenuData: [],
        showOrNot: false,
        isOpen: false,
        isLock: false,
        isSettingOrProfile: false
    })
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setState({...state, isOpen: true})
    };

    const handleDrawerClose = () => {
        setState({...state, isOpen: false})
    };

    const dispatch = useDispatch()
    const {t} = useTranslation();
    const Ismobile = useMediaQuery({maxWidth: 767})
    const navigate = useNavigate()
    const location = useLocation();
    let newData = [
        {
            label: "User Profile",
            stateName: "isProfileOpen",
            myValue: t('user-profile'),
            options: [
                {
                    optionName: t('optional'),
                    myState: "option1"
                },
                {
                    optionName: t('optional'),
                    myState: "option2"
                },
                {
                    optionName: t('optional'),
                    myState: "option3"
                },
                {
                    optionName: t('optional'),
                    myState: "option4"
                }
            ]
        },
        {
            label: "Settings",
            myValue: t('settings'),
            stateName: "isSettingsOpen",
            options: [
                {
                    optionName: t('optional')
                },
                {
                    optionName: t('optional')
                }
            ]
        },
        {
            label: "optional",
            myValue: t('optional'),
            stateName: "isOptional",
            options: [
                {
                    optionName: t('optional')
                },
                {
                    optionName: t('optional')
                },
                {
                    optionName: t('optional')
                }
            ]
        },
        {
            label: "Profile",
            myValue: t('profile'),
            stateName: "profile",
            options: [
                {
                    optionName: t('optional')
                },
                {
                    optionName: t('optional')
                },
            ]
        }
    ]
    const TabsData = [
        {
            label: t("Search"),
            icon: <SearchIcon style={{height: 22, width: 22}}/>,
            options: [],
            route: "/search"
        },
        {
            label: t("Analytics"),
            icon: <AutoGraphIcon style={{height: 22, width: 22}}/>,
            route: "/dashboard",
            options: [
                {
                    myValue: t('overview'),
                    label: "Overview",
                    stateName: "analytics-overview",
                    options: []
                },
                {
                    myValue: t('agent-performance'),
                    label: "Agent Performance",
                    stateName: "agent-performance",
                    options: []
                },
                {
                    myValue: t('agent-conversation'),
                    label: "Agent Conversation",
                    stateName: "agent-conversation",
                    options: []
                },
                {
                    myValue: t('voice-of-customer'),
                    label: "Voice Of Customer",
                    stateName: "voice-of-customer",
                    options: []
                },
                {
                    myValue: t('bot-conversations'),
                    label: "Bot Conversations",
                    stateName: "bot-conversations",
                    options: []
                },
                {
                    myValue: t('campaign-insights'),
                    label: "Campaign Insights",
                    stateName: "campaign-insights",
                    options: []
                },
                {
                    myValue: t('whatsapp-insights'),
                    label: "Whatsapp Insights",
                    stateName: "whatsapp-insights",
                    options: []
                },
            ]
        },
        {
            label: 'Inbox',
            icon: <MessageIcon style={{height: 22, width: 22}}/>,
            route: "/inbox/whatsapp/1",
            options: []
        },
        {
            label: 'Bot Inbox',
            icon: <RobotFilled style={{fontSize: 24}}/>,
            route: "/botInbox",
            options: []
        },
        {
            label: 'Contact',
            icon: <ContactPageIcon style={{height: 22, width: 22}}/>,
            options: [
                {
                    myValue: 'All contacts',
                    label: "All Contacts",
                    stateName: "allContacts",
                    options: []
                },
                {
                    myValue: 'Groups',
                    label: "Groups",
                    stateName: "groups",
                    options: []
                }],
            route: "/contact",
        },
        {
            label: t("Reports"),
            icon: <BarChartIcon style={{height: 22, width: 22}}/>,
            options: newData,
            route: "/reports",
        },
        {
            label: "Broadcast",
            icon: <CampaignIcon style={{height: 22, width: 22}}/>,
            options: newData,
            route: "/campaigns"
        },
        {
            label: "Bot Builder",
            icon: <SmartToyIcon style={{height: 22, width: 22}}/>,
            options: newData,
            route: "/chat-bot",
        },
        {
            label: t("Health"),
            icon: <FavoriteIcon style={{height: 22, width: 22}}/>,
            options: newData,
            route: "/channels-health",
        },
        {
            label: t("Channels"),
            icon: <QueuePlayNextIcon style={{height: 22, width: 22}}/>,
            options: newData,
            route: "/channels",
        },
        {
            label: 'Settings',
            icon: <SettingsIcon style={{height: 22, width: 22}}/>,
            route: "/settings",
            options: [
                {
                    myValue: t('workspace'),
                    label: "Workspace",
                    stateName: "Workspace",
                    options: []
                },
                {
                    myValue: t('templates'),
                    label: "Templates",
                    stateName: "templates",
                    options: []
                },
                {
                    myValue: t('user-roles'),
                    label: "Users & Roles",
                    stateName: "user-roles",
                    options: []
                },
                {
                    myValue: t('teams'),
                    label: "Teams",
                    stateName: "teams",
                    options: []
                },
                {
                    myValue: t('tags'),
                    label: "Tags",
                    stateName: "tags",
                    options: []
                },
                {
                    myValue: t('data-attributes'),
                    label: "Data Attributes",
                    stateName: "data-attributes",
                    options: []
                },
                {
                    myValue: t('ai-assistant'),
                    label: "AI Assistant",
                    stateName: "ai-assistant",
                    options: []
                },
                {
                    myValue: t('auto-replies'),
                    label: "Auto Replies",
                    stateName: "auto-replies",
                    options: []
                },
                {
                    myValue: t('inbox-rules'),
                    label: "Inbox Rules",
                    stateName: "inbox-rules",
                    options: []
                },
                {
                    myValue: t('saved-replies'),
                    label: "Saved Replies",
                    stateName: "saved-replies",
                    options: []
                },
                {
                    myValue: t('integrations'),
                    label: "Integrations",
                    stateName: "integrations",
                    options: []
                },
                {
                    myValue: t('billing'),
                    label: "Billing",
                    stateName: "billing",
                    options: []
                },
                {
                    myValue: t("Myprofile"),
                    label: "profile",
                    stateName: "my_profile",
                    options: []
                },
                {
                    myValue: t("Preference"),
                    label: "preference",
                    stateName: "Preference",
                    options: []
                },
                {
                    myValue: t("ChatWidget"),
                    label: "Chat",
                    stateName: "Chat-Widgete",
                    options: []
                },
                {
                    myValue: t("Outofoffice"),
                    label: "office",
                    stateName: "Out-of-office",
                    options: []
                },

                {
                    myValue: t("Bottoagent"),
                    label: "Bot",
                    stateName: "Bot-to-agent",
                    options: []
                },
                {
                    myValue: t("Quickreply"),
                    label: "Quick reply",
                    stateName: "Quick-reply",
                    options: []
                },
                {
                    myValue: t("Paaswordpolicy"),
                    label: "Paasword policy",
                    stateName: "Paasword-policy",
                    options: []
                },
                {
                    myValue: t("ChangePassword"),
                    label: "Change Password",
                    stateName: "Change-Password",
                    options: []
                },
                {
                    myValue: t("Businesshours"),
                    label: "Business hours",
                    stateName: "Business-hours",
                    options: []
                }


            ]
        },
        {
            myValue: "Help",
            label: t("help"),
            route: "/help",
            icon: <HelpIcon style={{height: 22, width: 22}}/>,
            options: newData
        },
        {
            myValue: "profile",
            label: t("profile"),
            route: "/profile",
            icon: <img src={profilePic} alt={'Profile'} style={{height: 28, width: 28, borderRadius: 6}}/>,
            options: [
                ...newData,
                {
                    myValue: t('logout'),
                    label: "Logout",
                    stateName: "logout",
                    options: []
                }
            ]
        },
    ]

    let activeRoute=window.location.pathname
    let setActiveTab = TabsData.find(item=>activeRoute.includes(item.route))
    let setActiveSubTab = setActiveTab?.options.length > 0 &&
        setActiveTab?.options.find(item=>activeRoute.includes(`${setActiveTab.route +'/'+ item.stateName }`))

    useEffect(() => {
        props.changeWidth(false, state.activeTab === 'Settings' || state.activeTab === 'Profile')
        setState({
            ...state,
            activeTab: setActiveTab?.label,
            subMenuData: !!setActiveTab?.options ? setActiveTab?.options : [],
            activeSubTab: !!setActiveSubTab ? setActiveSubTab?.label : ''
        })
    }, []);

    const leftsectiondata = () => {
        return ((location.pathname === '/') ||
            (location.pathname === '/signup') ||
            (location.pathname === '/account/add_password') ||
            (location.pathname === '/forgotPassword') ||
            (location.pathname === '/verify-email')) ?
            null : <div style={{display: "flex"}}>
                <div className={state.isLock ? "aside-fixed" : "aside"}>
                    <div style={{
                        padding: 18,
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <img src={Logo} alt={"Logo"} className="aside-icons-logo"
                             data-bs-toggle="tooltip"
                             data-bs-placement="top"
                             title="This is a tooltip"
                        />
                        <div className="lock-icon" style={{marginLeft: '200px'}}>
                            {
                                state.isLock ? (
                                    <LockOpenOutlinedIcon style={{cursor: "pointer"}}
                                                          onClick={() => {
                                                              setState({
                                                                  ...state,
                                                                  isLock: !state.isLock,

                                                              })
                                                          }
                                                          }

                                    />
                                ) : (
                                    <LockOutlinedIcon
                                        style={{color: state.isLock ? "" : "#fff", cursor: "pointer"}}
                                        onClick={() => {
                                            setState({
                                                ...state,
                                                isLock: !state.isLock
                                            })
                                            props.changeWidth(true, state.activeTab === 'Settings' || state.activeTab === 'Profile')
                                        }
                                        }
                                    />
                                )
                            }
                        </div>

                    </div>
                    {
                        !!TabsData && TabsData.map((item, idx) =>
                            <div className={"leftTabs"} onClick={() => {
                                setState({
                                    ...state, activeTab: item.label,
                                    isDrawerOpen: item.label === "Search" ? true : false,
                                    subMenuData: item.options,
                                })
                                navigate(item.route);
                            }
                            }
                                 style={{
                                     backgroundColor: state.activeTab === item.label ? "rgba(0,0,0,.03)" : "",
                                 }}>
                                <div key={idx} data-bs-toggle="tooltip" data-bs-placement="right" title={item.label}
                                     style={{
                                         borderRadius: 6,
                                         color: state.activeTab === item.label ? "#04c8c8" : "#B5B5C3",
                                     }}
                                     className="aside-icons">{item.icon}</div>
                                <div className={"leftSectionLabel"} style={{
                                    color: state.activeTab === item.label ? "#04c8c8" : "",
                                }}>{item.label}</div>
                            </div>
                        )
                    }
                </div>


                <div className="main-aside" style={{
                    width: state.collapse ? "240px" : "0px"
                }}>
                    <div style={{
                        fontWeight: "500",
                        color: "#99A1B7",
                        margin: 20
                    }}> {state.activeTab === "Inbox" ? "Conversations" : "Pages"}</div>

                    {
                        state.activeTab === "Search" && <div className="search-drawer">
                            <TextField
                                label="Search"
                                id="fullWidth"
                                size="small"
                                style={{width: "100%"}}
                                placeholder="Search here"
                            />
                            <div className="drawer-tabs">
                                <span className="tab">All</span>
                                <span className="tab">
                        <MessageIcon style={{width: 15, marginRight: 4}}/><span>Messages</span>
                    </span>
                                <span className="tab">
                        <PeopleAltIcon style={{width: 15, marginRight: 4}}/><span>Contacts</span>
                    </span>
                            </div>

                        </div>
                    }
                    {
                        state.activeTab !== "Search" && state.subMenuData.map((item, idx) => <>
                            <ListItemButton key={idx}
                                            onClick={() => {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    asideState: {
                                                        [item.stateName]: !prevState.asideState[item.stateName]
                                                    },
                                                    settings: false,
                                                    activeSubTab: item.label,
                                                    isOpen: false
                                                }))
                                                if (state.activeTab === "Settings") {
                                                    navigate(`settings/${item.stateName}`)
                                                }
                                            }
                                            }>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    color: state.activeSubTab === item.label ? "rgb(4, 200, 200)" : "#78829D"
                                }}>
                                    <div>
                                        <div style={{display: "flex", gap: 6, alignItems: "center"}}>{item.icon}
                                            <div style={{fontSize: "1.02rem", fontWeight: 500}}>{item.myValue}</div>
                                        </div>
                                    </div>
                                    {item.options.length > 0 ? state.asideState[item.stateName] ? <ExpandLess/> :
                                        <ExpandMore/> : ""}
                                </div>
                            </ListItemButton>
                            {
                                item.options.length > 0 &&
                                <Collapse in={state.asideState[item.stateName]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ul>
                                            {
                                                item.options.map((opt, idx) => <li key={idx} style={{
                                                    fontSize: "1.15rem",
                                                    fontWeight: 500,
                                                    color: "#78829D",
                                                    margin: 16
                                                }}>{opt.optionName}</li>)
                                            }
                                        </ul>
                                    </List>
                                </Collapse>}
                        </>)
                    }
                </div>
            </div>
    }

    return ((location.pathname === '/') ||
        (location.pathname === '/signup') ||
        (location.pathname === '/account/add_password') ||
        (location.pathname === '/forgotPassword') ||
        (location.pathname !== '/chat-bot/settings' &&
            location.pathname !== '/chat-bot/settings/createForm' &&
            !location.pathname.includes('/chat-bot/settings/editForm') &&
            location.pathname !== '/chat-bot' &&
            location.pathname.includes('chat-bot')) ||
        (location.pathname === '/verify-email')) ?
        null :
        <div style={{
            display: "flex", width:
                (state.isLock &&
                    (state.activeTab === 'Settings' ||
                        state.activeTab === 'Profile')) ? 506 :
                    state.isLock ||
                    state.activeTab === 'Settings' ||
                    state.activeTab === 'Profile' ? 290 : 74
        }}>
            <div className={state.isLock ? "aside-fixed" : "aside"} style={{
                position: 'fixed',
                display: Ismobile ? "none" : "flex",
                zIndex: 400
            }}>
                <div style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    height: 78
                }}>
                    <img src={Logo} alt={"Logo"} className="aside-icons-logo"
                         style={{marginLeft: 18}}
                         data-bs-toggle="tooltip"
                         data-bs-placement="top"
                         title="This is a tooltip"
                    />
                    <div className="lock-icon">
                        {
                            !state.isLock ? (
                                <LockOutlinedIcon style={{cursor: "pointer", color: "#fff", marginRight: 18}}
                                                      onClick={() => {
                                                          setState({
                                                              ...state,
                                                              isLock: true,

                                                          })
                                                          props.changeWidth(true, state.activeTab === 'Settings' || state.activeTab === 'Profile')
                                                      }
                                                      }

                                />
                            ) : (
                                <div>
                                    <LockOpenOutlinedIcon
                                        style={{color: "#fff", cursor: "pointer", marginRight: 18}}
                                        onClick={() => {
                                            setState({
                                                ...state,
                                                isLock: false
                                            })
                                            props.changeWidth(false, state.activeTab === 'Settings' || state.activeTab === 'Profile')
                                        }}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
                {
                    !!TabsData && TabsData.map((item, idx) =>
                        <div className={"leftTabs"} onClick={() => {
                            setState({
                                ...state, activeTab: item.label,
                                isDrawerOpen: item.label === "Search" ? true : false,
                                subMenuData: item.options,
                                isSettingOrProfile: item.label === 'Settings' ? true : false
                            })
                            props.changeWidth(state.isLock, item.label === 'Settings' || item.label === 'Profile')
                            navigate(item.route);
                        }
                        } style={{
                            backgroundColor: state.activeTab === item.label ? "#eef2f3" : "",
                            // marginRight: state.activeTab === item.label ? 15 : ""
                            marginRight: 15
                        }}>
                            <div key={idx} data-bs-toggle="tooltip" data-bs-placement="right" title={item.label}
                                 style={{
                                     borderRadius: 6,
                                     color: state.activeTab === item.label ? "#3f4faa" : "#B5B5C3",
                                 }}
                                 className="aside-icons">{item.icon}</div>
                            <div className={"leftSectionLabel"} style={{
                                color: state.activeTab === item.label ? "#3f4faa" : "",
                            }}>{item.label}</div>
                        </div>
                    )
                }
            </div>
            {
                (location.pathname.includes('/settings') ||
                    location.pathname.includes('/profile')) && !location.pathname.includes('/chat-bot/settings') &&
                // location.pathname.includes('/contact'))
                <div className="main-asid" style={{
                    width: state.collapse ? "216px" : "0px",
                    display: Ismobile ? "none" : "",
                    height: "calc(102vh - 1rem)",
                    overflow: "hidden scroll",
                    background: "#10163a",
                    marginLeft: (state.isLock &&
                        (state.activeTab === 'Settings' ||
                            state.activeTab === 'Profile')) ? 290 : 74
                }}>

                    <div style={{
                        fontWeight: "500",
                        color: "#99A1B7",
                        margin: 20
                    }}> {state.activeTab === "Inbox" ? "Conversations" : "Pages"}</div>

                    {
                        state.activeTab === "Search" && <div className="search-drawer">
                            <TextField
                                label="Search"
                                id="fullWidth"
                                size="small"
                                style={{width: "100%"}}
                                placeholder="Search here"
                            />
                            <div className="drawer-tabs">
                                <span className="tab">All</span>
                                <span className="tab">
                        <MessageIcon style={{width: 15, marginRight: 4}}/><span>Messages</span>
                    </span>
                                <span className="tab">
                        <PeopleAltIcon style={{width: 15, marginRight: 4}}/><span>Contacts</span>
                    </span>
                            </div>

                        </div>
                    }
                    {
                        state.activeTab !== "Search" && state.subMenuData.map((item, idx) => <>
                            <ListItemButton key={idx} onClick={() => {
                                setState((prevState) => ({
                                    ...prevState,
                                    asideState: {
                                        [item.stateName]: !prevState.asideState[item.stateName]
                                    },
                                    settings: false,
                                    activeSubTab: item.label
                                }))

                                if (state.activeTab !== "Settings") {
                                    if (item.label === "Logout") {
                                        navigate('/')
                                        localStorage.removeItem('token')
                                        dispatch(ActionsCreators.getWhatsappConversationAction(''))
                                        dispatch(ActionsCreators.getMessengerConversationAction(''))
                                        dispatch(ActionsCreators.getInstagramConversationAction(''))
                                        setState({
                                            ...state,
                                            activeTab: '',
                                            subMenuData: []
                                        })
                                    } else if (item.myValue === "Whatsapp") {
                                        navigate('/inbox/whatsapp/1')
                                    } else if (item.myValue === "Messenger") {
                                        navigate('/inbox/messenger')
                                    } else if (item.myValue === "Instagram") {
                                        navigate('/inbox/instagram')
                                    }
                                }
                                if (state.activeTab === "Settings") {
                                    navigate(`settings/${item.stateName}`)
                                }

                                if (state.activeTab === "Contact") {
                                    navigate(`contact/${item.stateName}`)
                                }

                            }}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    color: state.activeSubTab === item.label ? "#eef2f3" : "#78829D"
                                }}>
                                    <div>
                                        <div style={{display: "flex", gap: 6, alignItems: "center"}}>{item.icon}
                                            <div style={{fontSize: "1.02rem", fontWeight: 500}}>{item.myValue}</div>
                                        </div>
                                    </div>
                                    {item.options.length > 0 ? state.asideState[item.stateName] ? <ExpandLess/> :
                                        <ExpandMore/> : ""}
                                </div>
                            </ListItemButton>
                            {
                                item.options.length > 0 &&
                                <Collapse in={state.asideState[item.stateName]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ul>
                                            {
                                                item.options.map((opt, idx) => <li key={idx} style={{
                                                    fontSize: "1.15rem",
                                                    fontWeight: 500,
                                                    color: "#78829D",
                                                    margin: 16
                                                }}>{opt.optionName}</li>)
                                            }
                                        </ul>
                                    </List>
                                </Collapse>}
                        </>)
                    }
                </div>
            }
            <div style={{display: Ismobile ? 'block' : 'none', position: Ismobile ? "" : "fixed"}}>
                <Box sx={{display: 'flex'}}>
                    <CssBaseline/>
                    <AppBar position="fixed" open={state.isOpen}>
                        <Toolbar>
                            <IconButton aria-label="open drawer"
                                        onClick={handleDrawerOpen} style={{color: "#fff"}}

                                        edge="start" sx={{marginRight: 5, ...(open && {display: 'none'}),}}>
                                <MenuIcon style={{fontSize: "23px", marginTop: "1%", marginLeft: "-4%"}}/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Box component="div">
                        <Drawer anchor="left" open={state.isOpen} onClose={() => setState({...state, isOpen: false})}>
                            <div><IconButton onClick={handleDrawerClose}
                                             sx={{display: open ? 'initial' : 'none'}}>
                            </IconButton></div>
                            <Divider/>
                            {leftsectiondata()} </Drawer>
                    </Box>
                </Box>
            </div>
        </div>
}
export default LeftSection