import React from 'react';
import {FileFilled} from "@ant-design/icons";
import ReactAudioPlayer from "react-audio-player";

const Multimedia = (props) => {
    let {type, url} = props
    return <div>
        {type === 'image' ?
            <img src={url} alt="media"/> :
            type === 'video' ?
                <video controls>
                    <source src={url} type="video/mp4"/>
                    Your browser does not support the video element.
                </video> :
                type === 'document' ?
                    <a href={url} target={'_blank'}>
                        <FileFilled style={{fontSize: 40}}/>
                    </a> : type === 'audio' &&
                    <ReactAudioPlayer src={url} autoPlay controls/>
        }
    </div>
}
export default Multimedia