import { PlusOutlined } from '@ant-design/icons';
import { Upload, Form, Radio } from 'antd';
import { message } from 'antd';
import React from 'react';

const MediaFile = ({name, label}) => {
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e &&  e.fileList;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          width : "100%"
        }}
      >
        {label}
      </div>
    </div>
  );
  const onFinish = (values)=>{
    console.log(values)
  }
  return (
    <>

     <Form.Item
            name={name}
            // rules={[
            //     {
            //         required: true,
            //         message: label,
            //     },
            // ]}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            className="upload-input"
        >
      <Upload
          name={name}
          listType="picture-card"
          maxCount={1}
          accept="image/*, video/*, audio/*, .pdf"
        >
         { uploadButton}
        </Upload>
      </Form.Item>
    </>
  
  );
};

export default MediaFile;