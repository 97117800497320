import React, {useState} from "react";
import "./Dashboard.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Tabs, DatePicker} from "antd";
import Overview from "./components/overview/overview";
import {FilterFilled, ExportOutlined} from '@ant-design/icons';
import TiebotDateFilters from "./components/reuseableComponents/TiebotDateFilters";
import AgentPerformance from "./components/AgentPerformance/AgentPerformance";
import TuneIcon from '@mui/icons-material/Tune';
import AgentConversations from "./components/AgentConversations/AgentConversations";
import VoiceOfCustomer from "./components/VoiceOfCustmer/VoiceOfCustmer";
import BotConversataions from "./components/BotConversataions/BotConversataions";
import WhatsappInsights from "./components/WhatsappInsights/WhatsappInsights";

const Dashboard = () => {
    const [state, setState] = useState({
        activeTab: '1',
        isFilterOpen: false,
        startDate: '',
        endDate: '',
        isModalOpen:false,
        mode:''
    })

    const items = [
        {
            key: '1',
            label: 'Overview'
        },
        {
            key: '2',
            label: 'Agent Performance'
        },
        {
            key: '3',
            label: 'Agent Conversations'
        },
        {
            key: '4',
            label: 'Voice Of Customer'
        },
        {
            key: '5',
            label: 'Bot Conversations'
        },
        {
            key: '6',
            label: 'Whatsapp Insights'
        },
    ];

    function handleTabChange(key) {
        setState({...state, activeTab: key})
    }

    const closeFilterModal = (start, end) => {
        setState({
            ...state,
            isFilterOpen: !state.isFilterOpen,
            startDate: !!start ? start : '',
            endDate: !!end ? end : ''
        })
    }

    const modalOpenFunc = () => {
        setState({...state, isModalOpen: !state.isModalOpen, mode: ''})
    }

    const changeMode=()=>{
        setState({...state, mode: ''})
    }
    return <div className="synctic-module-container" style={{height:`calc(100vh - 5rem)`}}>
        <div className='cards-box' style={{
            paddingTop: 0,
            paddingBottom: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }}>
            <Tabs
                defaultActiveKey={state.activeTab}
                items={items}
                onChange={handleTabChange}
            />
            <div style={{
                display: 'flex',
                gap: 14,
                alignItems: 'center'
            }}>
                <TuneIcon
                    onClick={()=>setState({...state, isModalOpen: true, mode: 'filter'})}
                    style={{
                        fontSize: 20,
                        display:state.activeTab!=='1' && state.activeTab!=='5' ? '' : 'none',
                        cursor:'pointer'
                }}
                />
                <ExportOutlined
                    onClick={()=>setState({...state, isModalOpen: true, mode: 'export'})}
                    style={{fontSize: 20}}
                />
                <FilterFilled
                    style={{fontSize: 20}}
                    onClick={() => setState({
                        ...state,
                        isFilterOpen: !state.isFilterOpen,
                        mode: 'timeFilter'
                    })}
                />
            </div>
        </div>
        {
            state.activeTab === '1' && <Overview
                title={state.activeTab}
                modalOpenFunc={modalOpenFunc}
                isModalOpen={state.isModalOpen}
            />
        }
        {
            state.activeTab === '2' && <AgentPerformance
                title={state.activeTab}
                modalOpenFunc={modalOpenFunc}
                isModalOpen={state.isModalOpen}
                mode={state.mode}
            />
        }
        {
            state.activeTab === '3' && <AgentConversations
                title={state.activeTab}
                modalOpenFunc={modalOpenFunc}
                isModalOpen={state.isModalOpen}
                mode={state.mode}
            />
        }
        {
            state.activeTab === '4' && <VoiceOfCustomer
                title={state.activeTab}
                modalOpenFunc={modalOpenFunc}
                isModalOpen={state.isModalOpen}
                mode={state.mode}
            />
        }
        {
            state.activeTab === '5' && <BotConversataions
                title={state.activeTab}
                modalOpenFunc={modalOpenFunc}
                isModalOpen={state.isModalOpen}
                mode={state.mode}
            />
        }

        {
            state.activeTab === '6' && <WhatsappInsights
                title={state.activeTab}
                modalOpenFunc={modalOpenFunc}
                isModalOpen={state.isModalOpen}
                mode={state.mode}
            />
        }

        <TiebotDateFilters isFilterOpen={state.isFilterOpen} closeFilterModal={closeFilterModal}/>
    </div>
}
export default Dashboard