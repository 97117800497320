import { Table } from 'antd';
import React from 'react';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {removeTeamMmber} from "../Settings/Teams/API/API";
import { useTranslation } from 'react-i18next';

const AntDTable = (props) => {
    let dataContent = !!props.tableData && props.tableData.map(item=>(
    {
            ...item,
            delete:<RemoveCircleIcon style={{display:"flex", gap:10, opacity:.7}} onClick={()=>
            onDeleteTeamMember(item._id, props.teamId)
        } />

    }))
    const { t } = useTranslation();
    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
            responsive: ["sm"]
        },
        {
            title: t("title-Role"),
            dataIndex: 'role',
            key: 'role',
            width: '20%',
            responsive: ["sm"]

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '20%',
            responsive: ["sm"]

        },
        ,
        // {
        //     title: 'Actions',
        //     dataIndex: 'delete',
        //     key: 'delete',
        //     width: '20%',
        // }
    ];
    const onDeleteTeamMember = (id) => {
        console.log(id, "33311")
            let data = {
                teamId: props.teamId,
                users:[id]
            }
            removeTeamMmber(data).then(res=>console.log(res, "3311111141")).catch(error=>console.log(error, "Error"))
    }
    console.log(props, "771111")
    return <Table style={{marginTop:"2%"}} columns={columns} dataSource={dataContent} />;
};
export default AntDTable;