import React, {useEffect, useRef, useState} from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InfoIcon from '@mui/icons-material/Info';
// import Select from '@mui/material/Select';
// import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
    assignAgent,
    assignToMe,
    conversationTimer, forwardChatToUser,
    getAllAgents,
    getAllForwarders,
    getLogs,
    searchAgents
} from "../../Inbox/Apis";
import {Dropdown, Modal, Button, Input, Space, Table, Tooltip, Popover, Menu, Select} from "antd";
import {MoreOutlined, SearchOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {getAllTeams} from "./API/API";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Highlighter from "react-highlight-words";
import Loader from "../../ResuseableComponnets/Loader";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import moment from "moment/moment";
import openNotificationWithIcon from "../../ResuseableComponnets/Toaster";


const { Option } = Select;
const ChatBotHeader = (props) => {
    const [state, setState] = useState({
        status: "",
        allAgents: [],
        timer: '00:00',
        timerMessage: "",
        isModal: false,
        teamsData: [],
        logsModal: false,
        logsData: [],
        isLoader: false,
        selectedTeam: null,
        selectedMember: null,
        teamAgentId: "",
        teamId: "",
        note: "",
        searchPopper: false,
        mode:'',
        myTeams:[],
        loaderInModal:false
    })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 8,
    });

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    let myTeams = []

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const assignAgentFunc = () => {
        let data = {
            conversationId: props.allConversation.conversationId,
            agentID: state.teamAgentId,
            teamID: state.teamId,
            notes: state.note
        }
        assignAgent(data).then(res => {
            props.getAllChat(
                props.allConversation.conversationId,
                props.allConversation._id,
                props.allConversation.contactInfo
            )
            setState({...state, isModal: false})
            openNotificationWithIcon(
                'success',
                'Success message',
                'Agent assign successfully'
            )
        }).catch(err => {
            openNotificationWithIcon(
                'error',
                'Error message',
                err?.response?.data?.message
            )
            setState({...state, isModal: false})
        })
    }
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{width: 90}}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "Current State",
            dataIndex: 'currentState',
            key: 'currentState',
            width: '20%',
            ...getColumnSearchProps('currentState'),
        },
        {
            title: "Previous State",
            dataIndex: 'prevState',
            key: 'prevState',
            width: '20%',
            ...getColumnSearchProps('prevState'),
        },
        {
            title: "Log Time",
            dataIndex: 'timestamp',
            key: 'timestamp',
            ...getColumnSearchProps('timestamp'),
        },
        {
            title: "Assigned To",
            dataIndex: 'assignedTo',
            key: 'assignedTo',
            ...getColumnSearchProps('assignedTo'),
        },
        {
            title: "Notes",
            dataIndex: 'notes',
            key: 'notes',
            ...getColumnSearchProps('notes'),
        },
    ];

    const allForwardersKey = [
        {
            title: "From",
            dataIndex: 'from',
            key: 'from',
            width: '20%',
            ...getColumnSearchProps('from'),
        },
        {
            title: "To",
            dataIndex: 'to',
            key: 'to',
            width: '20%',
            ...getColumnSearchProps('to'),
        },
        {
            title: "Team",
            dataIndex: 'team',
            key: 'team',
            width: '20%',
            ...getColumnSearchProps('team'),
        },
        {
            title: "Time",
            dataIndex: state.mode==='allAgents' ? 'assignAt' : 'forwardAt',
            key: state.mode==='allAgents' ? 'assignAt' : 'forwardAt',
            ...getColumnSearchProps(state.mode==='allAgents' ? 'assignAt' :  'forwardAt'),
        },
        {
            title: "Notes",
            dataIndex: 'notes',
            key: 'notes',
            width: '20%',
            ...getColumnSearchProps('notes'),
        },
    ];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (id) => {
        let data = {
            conversationId: props.allConversation.conversationId,
            agentID: id
        }
        props.assignAgentFunc(data).then(res => console.log("Agent Assigned")).catch(err => console.log("Error: ", err))
        setAnchorEl(null)
    };


    const handleChange = (value) => {
        let data = {
            conversationId: props.allConversation.conversationId,
            status: value
        }
        props.assignStatusFunc(data)
            .then(() => {
                setState({
                    ...state,
                    status: value
                })
                openNotificationWithIcon(
                    'success',
                    'Success message',
                    'Status changed successfully'
                )
            }).catch(err => openNotificationWithIcon(
            'error',
            'Error message',
            err?.response?.data?.message
        ))
    };
    const conversationTimerFunc = () => {
        let conversationId = props?.allConversation?.conversationId
        conversationTimer(conversationId)
            .then(res => {
                setState({
                    ...state,
                    timer: res?.data?.data,
                    timerMessage: ''
                })
                props.showMessageBarFunc(state.timer)
            })
            .catch(() => {
                    setState(({
                        ...state,
                        timerMessage: "Chat has been expired."
                    }))
                    props.showMessageBarFunc('Chat has been expired.')
                }
            )
    }
    const timeOutTimer = () => {
        conversationTimerFunc()
        setTimeout(() => {
            timeOutTimer()
        }, 60000)
    }
    const assignToMeFunc = () => {
        props.loaderInChatPanel(true)
        assignToMe({conversationId: props.allConversation.conversationId}).then(() => props.getAllChat(props.allConversation.conversationId, props.allConversation._id, props.allConversation.contactInfo)).catch(() => props.loaderInChatPanel(false))
    }
    const getLogsFunc = () => {
        setState({
            ...state,
            loaderInModal: true,
            logsModal: true,
            mode:'logs'
        })
        getLogs(props.allConversation?.conversationId).then(res => {
            setState(prevState => ({
                ...prevState,
                loaderInModal: false,
                logsData: res?.data?.data.map((obj) => ({
                    ...obj,
                    currentState: obj?.currentState.replace('-', ' ')
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' '),
                    prevState: obj?.prevState.replace('-', ' ')
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' '),
                    timestamp: moment(obj?.timestamp).format('MMMM Do YYYY, h:mm a'),
                    assignedTo: obj?.assignedTo?.firstName
                })),
            }))
        }).catch(err => setState({...state, loaderInModal: false}))
    }

    const getTeamsFunc = (mode) => {
        setState({
            ...state,
            loaderInModal: true,
            isModal: true,
            mode: mode
        })
        getAllTeams()
            .then(res => {
                setState(prevState => ({
                    ...state,
                    teamsData: res.data.data,
                    loaderInModal: false,
                    isModal: true,
                    mode
                }))
                myTeams = res.data.data
            })
            .catch(() => setState({...state, isLoader: false}))
    }

    const getAllForwadersFunc = () => {
        setState({...state, mode: 'allForwarders', isModal: true, loaderInModal: true})
        getAllForwarders(props?.allConversation?.conversationId).then(res=>setState(prevState => ({
            ...prevState,
            loaderInModal: false,
            logsData: res?.data?.data.map((obj) => ({
                ...obj,
                from: obj.from.email,
                to: obj.to.email,
                forwardAt: moment(obj?.forwardAt).format('MMMM Do YYYY, h:mm a'),
                team: obj?.team?.name,
                notes:obj?.notes
            })),
        }))).catch(err=>console.log(err, 'errorrrr'))
    }
    const getAllAgentsFunc= () => {
        setState({
            ...state,
            isModal: true,
            loaderInModal: true,
            mode:'allAgents'
        })
        getAllAgents(props?.allConversation?.conversationId).then(res=>setState(prevState => ({
            ...prevState,
            loaderInModal: false,
            logsData: res?.data?.data.map((obj) => ({
                ...obj,
                from: obj?.from?.email,
                to: obj?.to?.email,
                assignAt: moment(obj?.forwardAt).format('MMMM Do YYYY, h:mm a'),
                team: obj?.team?.name,
                notes:obj?.notes
            }))}))).catch(err=>setState({
            ...state,
            isModal: false,
            loaderInModal: false,
            mode:''
        }))
    }

    const forwardChatToUserFunc = () => {
        let data = {
            conversationId:props?.allConversation?.conversationId,
            agentID:state.teamAgentId,
            teamID:state.teamId,
            notes:state.note
        }
        forwardChatToUser(data).then(res=>setState({...state, isModal: false})).catch(err=>console.log(err, 885441111))
    }

    let statusValue = props.allConversation?.contactInfo?.status

    let selectedTeamVar=  state.teamsData.find(item=>state?.teamId===item._id)

    useEffect(() => {
        conversationTimerFunc()
    }, [])

    useEffect(() => {
        props.channel === "whatsapp" && timeOutTimer()
        searchAgents().then(res => {
                setState({
                    ...state,
                    allAgents: res.data.data
                })
            }
        )
            .catch(err => console.log(err))
    }, [props.allConversation.contactInfo.id]);

    useEffect(() => {
        setState({
            ...state,
            status: props.allConversation?.contactInfo?.status,
        selectedTeam: props?.allConversation?.contactInfo?.assign?.team?.name})
    }, []);

    return state.isLoader ? <Loader/> : <div style={{
        height: "60px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: '#CAD9F0',
        borderRadius: "12px 12px 2px 2px",
        padding: 8
    }}>
        <h5>Assigned to: {props?.allConversation?.info?.assign?.to?.email ?
            props?.allConversation?.info?.assign?.to?.email :
            props?.allConversation?.contactInfo?.assign?.to?.email
        }</h5>
        {props.channel === "whatsapp" && <div style={{
            padding: '5px 8px 5px 8px',
            borderRadius: 4,
            fontWeight: 550,
            backgroundColor: "white"
        }}>
            {
                !!state?.timerMessage ? state.timerMessage : state.timer
            }
        </div>}
        {
            !!props?.isBotInbox ? <div/> :
                <div style={{
                    display: "flex",
                    gap: 6,
                    height: "fit-content",
                    alignItems: "center"
                }}>
                    <Select
                        defaultValue={statusValue}
                        value={!state.status ? statusValue : state.status}
                        style={{ width: 120, display:!props.showMessageBar ? 'none' : 'block' }}
                        onChange={handleChange}
                        options={[
                            { value: 'open', label: 'Open', disabled:state.status==='open' ? true : false },
                            { value: 'pending', label: 'Pending', disabled:state.status==='pending' ? true : false },
                            { value: 'solved', label: 'Solved', disabled:state.status==='solved' ? true : false },
                            { value: 'spam', label: 'Spam', disabled:state.status==='spam' ? true : false },
                        ]}
                    />
                    <Tooltip
                        placement="top"
                        title={"Details"}>
                        <InfoIcon style={{color:'grey'}} onClick={() => setState({...state, logsModal: true, mode:'details'})}/>
                    </Tooltip>
                    <Tooltip
                        placement="top"
                        title={"Assign"}>
                        <AssignmentIndIcon style={{
                            display:!props.showMessageBar ? 'none' : 'block',
                            color:'grey'
                        }} onClick={() => getTeamsFunc('assign')}/>
                    </Tooltip>
                    <Tooltip
                        placement="top"
                        title={"Contact info"}>
                        <AccountBoxIcon
                            style={{color:'grey'}}
                            onClick={() => {
                            setState({...state, isModal: false})
                            props.openRightSectionOpen()
                        }}/>
                    </Tooltip>
                    <Tooltip
                        placement="top"
                        title={"Assign to me"}>
                        <AccountCircleIcon style={{
                            display:props.showMessageBar ? 'none' : 'block',
                        color:'grey'}} onClick={() => assignToMeFunc()}/>
                    </Tooltip>
                    <Popover
                        content={<Input
                            id="outlined-size-small"
                            placeholder="Search here"
                            size="small"
                            value={props?.searchQuery}
                            onChange={props.handleSearchChange}
                            style={{background: 'white'}}
                        />}
                        placement={"bottom"}
                        title="Search message"
                        trigger="click"
                        open={state.searchPopper}
                        onOpenChange={() => setState({...state, searchPopper: false})}
                    >
                        <SearchIcon style={{color:'grey'}} onClick={() => setState({...state, searchPopper: true})}/>
                    </Popover>
                    <Dropdown overlay={(
                        <Menu>
                            <Menu.Item>
                                <div style={{display:!props.showMessageBar ? 'none' : 'block'}} onClick={()=> getTeamsFunc('forwardChat')}> Forward </div>
                            </Menu.Item>
                            <Menu.Item>
                                <div onClick={() => getLogsFunc()}> Chat log </div>
                            </Menu.Item>
                            <Menu.Item>
                                <div onClick={()=>props.getAllChat(
                                    props.allConversation.conversationId,
                                    props.allConversation._id,
                                    props.allConversation.contactInfo
                                )}> Refresh </div>
                            </Menu.Item>
                            <Menu.Item>
                                <div onClick={()=>props.openRangePicker()}> Export </div>
                            </Menu.Item>
                            <Menu.Item>
                                <div onClick={()=>getAllForwadersFunc()}> All Forwarders </div>
                            </Menu.Item>
                            <Menu.Item>
                                <div onClick={()=>getAllAgentsFunc()}> All Agents </div>
                            </Menu.Item>
                        </Menu>
                    )} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                            <UnorderedListOutlined style={{
                                fontSize: '14px',
                                cursor: 'pointer',
                                color:'grey'
                            }}/>
                        </a>
                    </Dropdown>
                </div>
        }
        <Modal
            title={state.mode==='assign' ?"Assign Conversation" : state.mode==='forwardChat' ? 'Forward Chat' : state.mode==='allAgents' ? 'All agents' : 'All Forwarders'}
            open={state.isModal}
            okButtonProps={{disabled: !state.teamId || !state.teamAgentId}}
            onOk={() => state.mode==='assign' ? assignAgentFunc() : forwardChatToUserFunc()}
            onCancel={() => setState({...state, isModal: false, logsData: []})}
            style={{minWidth:(state.mode==='allForwarders' || state.mode==='allAgents') ? '95%' : ''}}
            destroyOnClose={()=>setState({...state, isModal: false, logsData: []})}
            afterClose={()=>setState({...state, mode: ''})}
        >
            {
                state.loaderInModal ? <Loader height={'10vh'}/> : (state.mode==='allForwarders' || state.mode==='allAgents') ?
                    <Table
                        pagination={pagination}
                        onChange={(page) => {
                            setPagination(page);
                        }}
                        style={{marginTop: '2%'}}
                        columns={allForwardersKey}
                        dataSource={state.logsData}
                    /> :
                    (state.teamsData !== null || state.teamsData !== undefined) && <div>
                        <Select
                            style={{ marginTop: "1%", width:'100%' }}
                            value={state.teamId}
                            placeholder="Please select team"
                            onChange={e => {
                                setState({
                                    ...state,
                                    teamId: e,
                                    teamAgentId: ''
                                })
                            }}
                        >
                            {
                                !!state?.teamsData && state.teamsData.length > 0 &&
                                state.teamsData.map(item =><Option value={item.id}>{item.name}</Option>)
                            }
                        </Select>
                    <br/>
                    <br/>
                        <Select
                            style={{ marginTop: "1%", width:'100%' }}
                            value={state.teamAgentId}
                            placeholder="Please select team member"
                            onChange={e => {
                                setState({
                                    ...state,
                                    teamAgentId: e
                                })
                            }}
                        >
                            {
                                selectedTeamVar?.users?.length > 0 &&
                                selectedTeamVar.users.map(item =><Option value={item._id}>{item.email}</Option>)
                            }
                        </Select>
                    <Input.TextArea onChange={(e) => setState({...state, note: e.target.value})} style={{marginTop: 20}}
                                    placeholder={"Enter Note Here"}/>
                </div>
            }
        </Modal>

        <Modal
            title={ state.mode==='details' ? 'Details' : state.mode==='logs' && "Conversation Logs"}
            open={state.logsModal}
            onCancel={() => setState({...state, logsModal: false})}
            style={{minWidth: state.mode==='details' ? '' : state.mode==='logs' && "95%"}}
            footer={null}
            afterClose={()=>setState({...state, mode: ''})}
        >
            {
                state.mode==='details' ?
                    <div>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={'Current state'}
                            secondary={ <div style={{fontSize:14}}>{ props?.allConversation?.contactInfo?.status} </div> }
                            />
                    </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                            primary={'Assigned to'}
                            secondary={ <div style={{fontSize:14}}> { props?.allConversation?.info?.assign?.to?.email}</div>}
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                            primary={'Assigned by'}
                            secondary={<div style={{fontSize:14}}> {props?.allConversation?.contactInfo?.assign?.from?.email}</div>}
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                            primary={'Notes'}
                            secondary={<div style={{fontSize:14}}>{ props?.allConversation?.contactInfo?.assign?.notes} </div>}
                            />
                        </ListItem>
                    </div> :
                    state.loaderInModal && state.mode==='logs' ? <Loader height={'10vh'}/> : state.mode==='logs' &&
                    <Table
                    pagination={pagination}
                    onChange={(page) => {
                        setPagination(page);
                    }}
                    style={{marginTop: '2%'}}
                    columns={columns}
                    dataSource={state.logsData}
                />
            }
        </Modal>
    </div>
}
export default ChatBotHeader