import { Form, Radio } from 'antd';

const CustomRadioGroup = (props) => {
  return (
    <Form.Item
      label={props.label}
      name={props.name}
      // rules={[{ required: true, message: props.label }]}
    >
      <Radio.Group>
        {props.children}
      </Radio.Group>
    </Form.Item>
  );
};

export default CustomRadioGroup;