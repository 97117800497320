import axios from "../../../Authentication/headers"

export function saveWhatsappChatWidget(data){
    return axios.post(`/chatWidget/saveWhatsapp`, data)
}
export function getWhatsappChatWidget(){
    return axios.get(`/chatWidget/whatsapp`)
}

export function saveMessengerChatWidget(data){
    return axios.post(`/chatWidget/saveMessenger`, data)
}
export function getMessengerChatWidget(){
    return axios.get(`/chatWidget/messenger`)
}

export function saveInstagramChatWidget(data){
    return axios.post(`/chatWidget/saveInstagram`, data)
}
export function getInstagramChatWidget(){
    return axios.get(`/chatWidget/instagram`)
}

