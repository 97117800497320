import React from 'react'
import SettingsHeader from "../../ResuseableComponnets/SettingsHeader";
import { MdOutlineAutorenew } from "react-icons/md";
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { BsFillCalendar3EventFill } from "react-icons/bs";
import { BsCalendar } from "react-icons/bs";
import { useTranslation } from 'react-i18next';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

const Auto_replies = () => {
  const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="synctic-module-container">
    <SettingsHeader
          icon={<MdOutlineAutorenew className='icon'/>}
          heading={t("Auto-Replies")}
          description={t("description-Automate")}
      />
      
      <div style={{marginTop: "1%"}}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {t("New-Auto-Replies")}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <BsFillCalendar3EventFill   style={{marginRight: "10px",opacity: ".7", fontSize: "17px"}}/>
         {t("Welcome-Reply")}
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <BsCalendar style={{marginRight: "10px",opacity: ".7", fontSize: "17px"}} />
         {t("Away-Reply")}
        </MenuItem>
      </StyledMenu>
    </div>
    
    </div>
  )
}

export default Auto_replies
