import React from "react";
import axios from "../../../Authentication/headers"

export function saveShopify(data){
    return axios.post(`/integration/saveShopify`, data)
}
export function getShopify(){
    return axios.get(`/integration/shopify`)
}
export function saveOpenApi(data){
    return axios.post(`/integration/saveOpenApi`, data)
}
export function getOpenApi(){
    return axios.get(`/integration/openApi`)
}