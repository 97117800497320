import React, {useEffect, useState} from 'react'
import "./Bottoagent.scss"
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader'
import {Input, Button} from 'antd';
import {RiWhatsappFill} from "react-icons/ri";
import {getBotToAgentReply, saveBotToAgentReply} from "./API/Api";
import Loader from "../../ResuseableComponnets/Loader";
import { useTranslation } from 'react-i18next';


const Bottoagent = () => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        botToAgentReply: "",
        isLoader: false
    })
    const {TextArea} = Input;

    const saveFunc = () => {
        setState({...state, isLoader: true})
        saveBotToAgentReply({botToAgentReply: state.botToAgentReply})
            .then(() => getFunc())
            .catch(() => setState({
                ...state,
                isLoader: false
            }))
    }
    const getFunc = () => {
        setState({...state, isLoader: true})
        getBotToAgentReply()
            .then(res=>setState({...state, botToAgentReply:res.data.data, isLoader: false}))
            .catch(()=>setState({
                ...state,
                isLoader: false
            }))
    }

    useEffect(() => {
        getFunc()
    }, []);
    return (
        state.isLoader ? <Loader/> :
        <div className='synctic-module-container'>
            <SettingsHeader
                heading={t("Bottoagent")}
                description={t("Setyourbot-to-agent")}
            />
            <div className='Bottoagent'>
                <div className='Bottoagent-fluid' style={{display: "flex", gap: '10px', marginTop: "4%"}}>
                    <RiWhatsappFill style={{fontSize: "30px", color: " #25D366"}}/>
                    <div className='bot-agent-whatsapp'>{t("Whatsapp")}</div>
                </div>
                <TextArea value={state.botToAgentReply}
                          onChange={(e) => setState({
                              ...state,
                              botToAgentReply: e.target.value
                          })} placeholder={t("Enter-here")}
                          style={{height: "120px", width: "100%", marginTop: "1%", resize: "none"}}/>
                <Button onClick={()=>saveFunc()} type="primary" className='sumit-btn'>{t("Save")}</Button>
            </div>
        </div>
    )
}

export default Bottoagent
