import React from 'react'
import "./Data.scss"
import { SiMicrosoftteams } from 'react-icons/si';
import Button from '@mui/material/Button';
import { RiContactsFill } from "react-icons/ri";
import Data_table from './Data_table';
import { useTranslation } from 'react-i18next';

const Data = () => {
  const { t } = useTranslation();
  return (
    <div className="synctic-module-container">
<div className="cards-box" style={{
              display:"flex",
              width:"100%",
              justifyContent:"space-between",
          alignItems:"center"}}>
              <div>
        <div className='synctic-module-def'>
        <SiMicrosoftteams className='icon' />
          <div className="heading">{t("Data-Attribute")}</div>
          <p className="sub-heading">{t("Manage-Your-Workspace-discription")}</p>
        </div>
          </div>
              <div className='main-Channels'>
                <div className='Add-Attribute'>   
     <Button  variant="contained" >
    <RiContactsFill className='RiContactsFill' />
   {t("Add-Attribute")}
    </Button>
    </div>

              </div>
          </div>

<Data_table/>
    </div>
  )
}

export default Data
