export function isAllNodeisConnected(nodes, edges) {

  const allNodesIds = nodes.map((node) => node.id);
  const allSourceEdges = edges.map((edge) => edge.source);

  let count = 0;
  for (let i = 0; i < allNodesIds.length; i++) {
    if (allSourceEdges.includes(allNodesIds[i])) count++;
  }
  let ifStarter = 0;
  nodes.forEach(element => {
    if(element.templateStarter) {
      ifStarter++
    }
  });
  if(ifStarter === 0){
    alert("Template start node is required")
  }
  else if(ifStarter > 1){
    alert("Template starter can only be one")
  }
  else{
    return count + ' target messages has been saved in flow';
  }
  return false

}
