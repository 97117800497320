import axios from '../../../../../Authentication/headers'

export function getAllForms(){
    return axios.get(`/form/all`)
}
export function createForm(data){
    return axios.post(`/form/create`, data)
}

export function updateForm(id, data){
    return axios.put(`/form/update/${id}`, data)
}

export function deActivateAllForm(){
    return axios.get(`/form/deactivateAll`)
}

export function getActivatedForm(){
    return axios.get(`/form/activated`)
}

export function getForm(id){
    return axios.get(`/form/${id}`)
}

export function activateForm(id){
    return axios.get(`/form/activate/${id}`)
}

export function deActivateForm(id){
    return axios.get(`/form/deactivate/${id}`)
}

