import axios from "../../../Authentication/headers";
export function getAllTeams() {
    return axios.get('/team/all')
}

export function createTeam(data) {
    return axios.post('/team/create', data)
}

export function removeTeamMmber(data) {
    return axios.post('/team/removeMember', data)
}

export function updateTeam(id, data) {
    return axios.put(`/team/update/${id}`, data)
}

export function suspendTeam(id) {
    return axios.get(`/team/suspend/${id}`)
}

export function activateTeam(id) {
    return axios.get(`/team/activate/${id}`)
}

export function allAccounts() {
    return axios.get('/account/all')
}

export function deleteTeam(id) {
    return axios.get(`/team/delete/${id}`)
}

export function deleteTeamMember(data) {
    return axios.delete(`/team/removeMember`, data)
}

export function getTeam(id) {
    return axios.get(`/team/${id}`)
}

export function assignAgent(data) {
    return axios.post(`/message/assignAgent`, data)
}

