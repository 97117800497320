import { Dropdown, Menu, Switch, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { IoIosNotifications } from "react-icons/io";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Tabs } from "antd";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { PiSpeakerSimpleHighFill } from "react-icons/pi";
import "./Header.scss";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Tooltip } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  notificationlist,
  markNotificationRead,
  markallNotificationRead,
} from "./Api/Api";
import moment from "moment";
import { GoDotFill } from "react-icons/go";

const Notification = () => {
  const [state, setState] = useState({
    checked: false,
    AllData: {},
    AllNotifications: []
  });
  const { checked, AllData, AllNotifications } = state;

  const fetchNotificationList = () => {
    notificationlist()
      .then((response) => {
        const concatenated_latest_old =
          response.data.data?.latestNotification.concat(
            response.data.data?.oldNotification
          );
        setState((prevState) => ({
          ...prevState,
          AllData: response.data.data,
          AllNotifications: concatenated_latest_old,
        }));
      })
      .catch((error) => console.error("Error fetching data: ", error));
  };

  useEffect(() => {
    fetchNotificationList();
  }, []);

  const read_UnRead_Handler = (data) => {
    let result;
    data?.filter((notification) => {
      if (notification.readBy) {
        result = true;
      } else {
        result = false;
      }
    });
    return result;
  };

  const markSpecificRead = (id) => {
    if (id) {
      markNotificationRead(id)
        .then((res) => {
          fetchNotificationList();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const markAllRead = () => {
    markallNotificationRead()
      .then((res) => {
        fetchNotificationList();
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const handleChange = (value) => {
    if (value) {
      let unRead_Notifications = AllNotifications?.filter((notification) =>
        notification.to.some((v) => !v.readBy)
      );
      setState((prevState) => ({
        ...prevState,
        checked: value,
        AllData: unRead_Notifications,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        checked: value,
        AllData: fetchNotificationList(),
      }));
    }

    console.log("value", value);
  };

  const { TabPane } = Tabs;
  const text = <span>Open in a new tab</span>;

  const menu = (
    <Menu
      style={{
        width: "70vh",
        background: "white",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)"
      }}
    >
      <div key="notification">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
            padding: "15px",
          }}
        >
          <h2 style={{ fontSize: "20px", fontFamily: "inherit" }}>Activity</h2>
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <div style={{ display: "flex", gap: "5px" }}>
              <div>Only Show Unread</div>
              <Switch
                size="small"
                style={{ margin: "4px" }}
                checked={state.checked}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(value) => handleChange(value)}
              />
            </div>
            <Tooltip placement="bottom" title={text}>
              <FaExternalLinkAlt style={{ fontSize: "20px" }} />
            </Tooltip>

            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <div>
                      <PiSpeakerSimpleHighFill
                        style={{
                          fontSize: "20px",
                          transform: "rotate(330deg)",
                          marginRight: "4px",
                        }}
                      />
                      Give your feedback
                    </div>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <a href="#">
                <BsThreeDotsVertical
                  style={{ fontSize: "21px", fontWeight: "bold" }}
                />
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
      {/* heading ends here */}
      <div style={{height: "65vh",
        overflowY: "scroll"}}>
      <Tabs defaultActiveKey="1" style={{ width: "93%", margin: "auto" }}>
        <TabPane tab="Notifications" key="1">
          {checked ? (
            <div>
               {AllData?.map((notification, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", gap: "4%" }}
                    onClick={() => markSpecificRead(notification?._id)}
                  >
                    <Avatar
                      size="large"
                      icon={<UserOutlined />}
                      style={{ marginTop: "2%" }}
                    />
                    <div
                      className="notification-heading"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "2%",
                        fontFamily: "inherit",
                      }}
                    >
                      <div style={{ display: "flex", gap: "10px" }}>
                        <div style={{ fontSize: "15px", fontWeight: "500" }}>
                          {notification.title}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "10",
                            marginTop: "5px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              opacity: ".7",
                            }}
                          >
                            {moment(notification.timestamp)
                              .startOf("hour")
                              .fromNow()}
                          </span>

                          <span
                            style={{
                              fontSize: "12px",
                              opacity: ".7",
                              marginLeft: "7px",
                            }}
                          >
                            {read_UnRead_Handler(notification?.to) ? (
                              ""
                            ) : (
                              <GoDotFill
                                style={{ color: "green", fontSize: "13px" }}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                      <div>{notification.message}</div>
                      <span style={{ fontSize: "12px", opacity: ".7" }}>
                        {moment(notification.timestamp).format("MM-DD-YYYY")}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="status-notify">Latest</div>
                <div className="Mark-heading" onClick={() => markAllRead()}>
                  Mark all as read
                </div>
              </div>
              <div>
                {AllData?.latestNotification?.map((notification, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", gap: "4%" }}
                    onClick={() => markSpecificRead(notification?._id)}
                  >
                    <Avatar
                      size="large"
                      icon={<UserOutlined />}
                      style={{ marginTop: "2%" }}
                    />
                    <div
                      className="notification-heading"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "2%",
                        fontFamily: "inherit",
                      }}
                    >
                      <div style={{ display: "flex", gap: "10px" }}>
                        <div style={{ fontSize: "15px", fontWeight: "500" }}>
                          {notification.title}{" "}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "10",
                            marginTop: "5px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              opacity: ".7",
                            }}
                          >
                            {moment(notification.timestamp)
                              .startOf("hour")
                              .fromNow()}
                          </span>

                          <span
                            style={{
                              fontSize: "12px",
                              opacity: ".7",
                              marginLeft: "7px",
                            }}
                          >
                            {read_UnRead_Handler(notification?.to) ? (
                              ""
                            ) : (
                              <GoDotFill
                                style={{ color: "green", fontSize: "13px" }}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                      <div>{notification.message}</div>
                      <span style={{ fontSize: "12px", opacity: ".7" }}>
                        {moment(notification.timestamp).format("MM-DD-YYYY")}
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              {/* older notifictaion starts here */}

              <div>
                <div className="status-older">OLDER</div>
                {AllData?.oldNotification?.map((notification, index) => (
                  <div key={index} style={{ display: "flex", gap: "4%" }}>
                    <Avatar
                      size="large"
                      icon={<UserOutlined />}
                      style={{ marginTop: "2%" }}
                    />
                    <div
                      className="notification-heading"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "2%",
                        fontFamily: "inherit",
                      }}
                    >
                      <div style={{ display: "flex", gap: "10px" }}>
                        <div style={{ fontSize: "15px", fontWeight: "500" }}>
                          {notification.title}{" "}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            marginTop: "5px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              opacity: ".7",
                            }}
                          >
                            {moment(notification.timestamp)
                              .startOf("hour")
                              .fromNow()}
                          </span>

                          <span
                            style={{
                              fontSize: "12px",
                              opacity: ".7",
                              marginLeft: "7px",
                            }}
                          >
                            {read_UnRead_Handler(notification?.to) ? (
                              ""
                            ) : (
                              <GoDotFill
                                style={{ color: "green", fontSize: "13px" }}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                      <div>{notification.message}</div>
                      <span style={{ fontSize: "12px", opacity: ".7" }}>
                        {moment(notification.timestamp).format("MM-DD-YYYY")}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </TabPane>

        {/* Task Part start goes here */}

        <TabPane tab="Task" key="2"></TabPane>
      </Tabs>
      </div>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <IoIosNotifications
        color={"grey"}
        style={{
          fontSize: "22px",
          cursor: "pointer",
          transform: " rotate(4001deg)",
        }}
      />
    </Dropdown>
  );
};

export default Notification;
