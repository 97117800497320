import axios from "../Authentication/headers";
export function getAccount(id) {
    return axios.get(`/account/${id}`)
}

export function googleSignup (){
    return axios.get(`/account/social/googleSignUp`)
}

export function facebookSignup (){
    return axios.get(`/account/social/facebookSignup`)
}

export function updateSocialPass (values){
    let data = {
        password:values.password,
        confirmPassword:values.passwordConfirmation
    }
    return axios.post(`/account/updatePassword`, data)
}


export default {getAccount}