import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

const BarChart = (props) => {
    let {heading} = props
    const { t } = useTranslation();

    const options = {
        maintainAspectRatio:false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false // Hides the legend
            },
            title: {
                display: false // Hides the title
            }
        }
    };

    return  <div style={{height: props.height ? props.height : 210}}>
        <h6>{heading}</h6>
        <Bar style={{padding:10}} height={220} data={props.data} options={options} />
    </div>
};

export default BarChart;
