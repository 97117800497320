import React, {useRef, useEffect, useState} from "react";
import moment from "moment";
import ChatBotHeader from "../../Settings/Teams/chatBotHeader";
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ReactAudioPlayer from 'react-audio-player';
import InstagramBackgroundImage from "../../../assets/backgroundImages/whastappback.png";
import {Button, Tooltip, Tag, Card, List, Spin} from "antd";
import {FileFilled, LoadingOutlined} from '@ant-design/icons';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useDispatch} from "react-redux";
import {getWhatsappConversationAction} from "../states/Actions";

const ChatPanel = (props) => {
    const [state, setState] = useState({
        items: Array.from({length: 20}),
        hasMore: true,
        pageNum: 0,
        allMessages:[],
        isLoading:false,
        messagesCount:0
    })
    const messagesRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    let dispatch = useDispatch()
    let allConversation = props.conversations
    let getCustomer = allConversation.find(item => props?.allConversation?.conversationId === item?.conversationId)
    let messagesArray = getCustomer?.messages
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const scrollToBottom = () => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    };
    const openFileToNextTab = (file) => {
        window.location.href = file;
    }

    const InteractiveListMessage = ({ message }) => {
        const { sections, button } = message?.text?.action;

        return (
            <div>
                <p>{message?.message?.text?.body?.text}</p>
                {/* Render sections */}
                {sections.map((section, index) => (
                    <Card key={index} title={section?.title}>
                        {/* Render rows */}
                        <List
                            dataSource={section.rows}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={item?.title}
                                        description={item?.description}
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                ))}
                {/* Render action button */}
                <Button style={{width:'100%', marginTop:10}} type="primary">{button}</Button>
            </div>
        );
    };

    const ContactMessage = ({ message }) => {
        const contact = message?.contacts[0]; // Assuming there's only one contact in the array
        const { first_name, last_name, formatted_name } = contact?.name;
        const phoneNumber = !!contact?.phones && !!contact?.phones?.length > 0 && !!contact?.phones[0]?.phone ?
            contact?.phones[0]?.phone :
            '-';

        return (
            <Card title="Contact Information">
                <p>Name: {formatted_name}</p>
                <p>First Name: {first_name}</p>
                <p>Last Name: {last_name}</p>
                <p>Phone Number: { phoneNumber }</p>
            </Card>
        );
    };

    const fetchMoreData = async () => {
        setState({...state, isLoading: true})
        try {
            const nextPageNum = state.pageNum + 1;
            const response = await props.getChat(props.allConversation.conversationId, nextPageNum);
            const newMessages = response.data.data.messages;

            dispatch(getWhatsappConversationAction({
                messages:newMessages,
                conversationId:props?.allConversation?.conversationId
            }))
            state.pageNum===1 && newMessages.length > 0 && scrollToBottom()
            setState(prevState => ({
                ...prevState,
                isLoading: false,
                pageNum: nextPageNum,
                messagesCount:response.data.data.count
            }))
        } catch (error) {
            setState({
                ...state,
                isLoading: false,
                pageNum: state.pageNum + 1
            })
            console.error('Error fetching more messages:', error);
        }
    };

    useEffect(() => {
        fetchMoreData()
    }, []);

    useEffect(() => {
        state.pageNum===1 && scrollToBottom()
    }, [state.pageNum===1]);

    return <div style={{height: "calc(100vh - 15rem)"}}>
        <ChatBotHeader
            loaderInChatPanel={props.loaderInChatPanel}
            channel={props.channel}
            assignAgentFunc={props.assignAgentFunc}
            assignStatusFunc={props.assignStatusFunc}
            allConversation={props.allConversation}
            handleSearchChange={handleSearchChange}
            searchQuery={searchQuery}
            openRightSectionOpen={props.openRightSectionOpen}
            openRangePicker={props.openRangePicker}
            getAllChat={props.getAllChat}
            showMessageBar={props.showMessageBar}
            showMessageBarFunc={props.showMessageBarFunc}
            isBotInbox={props.isBotInbox}
        />
        <div
            id={'scrollableDiv'}
            className='messages-container'
            ref={messagesRef}
            style={{
                overflowY:'auto',
                backgroundImage: props.channel === "whatsapp" && `url(${InstagramBackgroundImage})`
            }}
        >
            {/*<InfiniteScroll*/}
            {/*    dataLength={state.allMessages.length}*/}
            {/*    next={fetchMoreData}*/}
            {/*    hasMore={true}*/}
            {/*    loader={<h4>Loading...</h4>}*/}
            {/*    scrollableTarget="scrollableDiv"*/}
            {/*    inverse={true}*/}
            {/*>*/}
            {
              messagesArray.length > 0 &&  <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}>
                {
                    state.isLoading ?
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                    spin
                                />
                            }
                        /> :
                        (messagesArray.length < state.messagesCount) && <Button onClick={() => fetchMoreData()}
                                type="primary">Load more</Button>
                }
            </div>
            }

            {
                Array.isArray(messagesArray) &&
                messagesArray.map((item, idx) =>
                    <div style={{marginTop: 16}} key={idx}>
                        {item?.messageType && item.messageType === "100" ? <div style={{
                                width: "fit-content",
                                borderRadius: 5,
                                padding: 10,
                                background: "rgba(50, 168, 82, .2)",
                                textAlign: "center",
                                margin: "auto"
                            }}>
                                <div style={{fontSize: 12, fontWeight: 550}}>{item.message?.text && item.message.text}</div>
                                <div style={{
                                    fontSize: 12,
                                    fontWeight: 550
                                }}>{moment(item.timestamp).format('MMMM Do YYYY, h:mm a')}</div>
                            </div> :
                            <div style={{display: "flex", flexDirection: !item?.isIncoming ? "row-reverse" : ""}}>
                                <div style={{
                                    marginRight: !item?.isIncoming ? 10 : "",
                                    marginLeft: item?.isIncoming ? 10 : "",
                                    maxWidth: "40%",
                                    backgroundColor: item?.isIncoming ? props.channel === "whatsapp" ? "rgba(50, 168, 82)" : "rgb(0, 132, 255)" : "rgb(217,210,202)",
                                    borderRadius: 10,
                                    padding: 10,
                                    color: !!item?.isIncoming ? "white" : "rgba(0,0,0,.7)",
                                    fontSize: 12,
                                    fontWeight: 500,
                                    marginTop: 10
                                }}>
                                    {
                                        <div style={{
                                            display:'flex',
                                            justifyContent:'flex-end',
                                            width:'100%',
                                            marginBottom:10
                                        }}>
                                            <Tag color={ item?.isIncoming ? "#87d068" : '#1677ff'}>{item?.postedBy}</Tag>
                                        </div>
                                    }
                                    {
                                        item.messageType === 'text' &&
                                        <div>{item?.message?.text}</div>
                                    }
                                    {
                                        item?.messageType === 'text' && (item?.message?.text !== item?.message?.translation) &&
                                        <div style={{
                                            padding: 10,
                                            background: 'rgba(255,255,255,.1)',
                                            borderRadius: 6
                                        }}> Translated text <br/> {item?.message?.translation && item?.message?.translation}
                                        </div>
                                    }
                                    {
                                        item.messageType==='location' && <div>
                                            <a style={{overflowWrap:'break-word'}} href={item?.message?.location} target={'_blank'}>
                                            {item?.message?.location}
                                            </a>
                                        </div>
                                    }
                                    {
                                        item.messageType==='interactive' && item?.message?.text?.type==='button' && <Card>
                                            <p>{item.message?.text?.body?.text}</p>
                                            <div style={{display:'flex', justifyContent:'space-between', gap:10, width:'100%'}}>
                                            {item?.message?.text?.action?.buttons?.length > 0 && item?.message?.text?.action?.buttons.map((button, index) => (
                                                <Button style={{width:'46%'}} type="primary">
                                                    {button?.reply?.title}
                                                </Button>
                                            ))}
                                            </div>
                                        </Card>
                                    }

                                    {
                                        item.messageType==='interactive' &&
                                        item?.message?.interactive?.type==="button_reply" &&
                                        <Card>
                                            <p>{item?.message?.interactive?.button_reply?.title}</p>
                                        </Card>
                                    }

                                    {
                                        item.messageType==='contacts' &&
                                        ContactMessage(item)
                                    }

                                    {
                                        item?.message?.text?.type==='list' &&
                                        InteractiveListMessage(item)
                                    }

                                    {
                                        item.messageType==='interactive' &&
                                        item?.message?.interactive?.type==="list_reply" &&
                                        <Card>
                                            <p style={{fontWeight:550}}>{item?.message?.interactive?.list_reply?.title}</p>
                                            <p>{item?.message?.interactive?.list_reply?.description}</p>
                                        </Card>
                                    }


                                    {
                                        (
                                            item?.messageType === 'image' ||
                                            item?.messageType === 'video' ||
                                            item?.messageType === 'document'
                                        ) &&
                                        <div>
                                            <div>{item?.message?.text}</div>
                                        <MultiMediaMessage
                                            openFileToNextTab={openFileToNextTab}
                                            url={item.message.url}
                                            type={item.messageType}
                                        />
                                        </div>
                                    }

                                    <div style={{
                                        float: "right",
                                        fontSize: 10,
                                        fontWeight: 400,
                                        display: "flex",
                                        gap: 6,
                                        alignItems: "center",
                                        marginTop: 6
                                    }}>
                                        {moment(item.timestamp).format('MMMM Do YYYY, h:mm a')}
                                        {
                                            item.readBy.status ? <DoneAllIcon/> :
                                                <DoneIcon/>
                                        }
                                        {
                                            <Tooltip placement="top" title={item?.message?.translation}>
                                                <GTranslateIcon color={"white"}/>
                                            </Tooltip>
                                        }
                                        {
                                            <Tooltip placement="top" title={'Reply with bot'}>
                                                <SmartToyIcon onClick={() => props.aiGenerateMsgFunc(item?.message?.text)}
                                                              color={'white'}/>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                )
            }

            {/*</InfiniteScroll>*/}

        </div>
    </div>
}

const MultiMediaMessage = (props) => {
    const {type, url} = props;
    const multimedia = () => {
        return type === "image" ? <img
            src={url}
            alt="Image"
            style={{width: "-webkit-fill-available"}}
            // onError={() => setImage(false)}
        /> : type === "video" ? <video style={{width: "-webkit-fill-available"}} controls>
            <source src={url} type="video/mp4"/>
        </video> : type === "audio" ? <ReactAudioPlayer
            src={url}
            autoPlay
            controls
        /> : type === 'document' && <a href={url} target={'_blank'}> <FileFilled
            // onClick={()=>openFileToNextTab(media.mediaUrl)}
            style={{fontSize: 40}}/></a>
    }
    return (
        <>{multimedia()}</>
    )
}
export default ChatPanel