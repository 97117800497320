import axios from "../../../Authentication/headers"

export function getAllChannels(){
    return axios.get(`/channel/all`)
}

export function getAllWhatsappChannels(){
    return axios.get(`/channel/whatsappChannels`)
}

export function getAllInstagramChannels(){
    return axios.get(`/channel/instagramChannels`)
}

export function getAllMessengerChannels(){
    return axios.get(`/channel/messengerChannels`)
}

export function addMessengerChannels(data){
    return axios.post(`/channel/addMessenger`, data)
}

export function addInstagramChannels(data){
    return axios.post(`/channel/addInstagram`, data)
}

export function addWhatsappChannel(data){
    return axios.post(`/channel/addWhatsapp`, data)
}

export function updateContact(id, data){
    return axios.put(`/channel/update/${id}`, data)
}

export function deactivateChannel(id){
    return axios.get(`/channel/deactivate/${id}`)
}

export function activateChannel(id){
    return axios.get(`/channel/activate/${id}`)
}

export function getChannelById(id){
    return axios.get(`/channel/${id}`)
}

export function deleteChannelById(id){
    return axios.get(`/channel/delete/${id}`)
}

