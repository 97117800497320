import React, {useEffect} from 'react';
import './channels.scss';
import Button from '@mui/material/Button';
import TelegramIcon from '@mui/icons-material/Telegram';
import {FaFacebookMessenger, FaLine} from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebook } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import {
    addInstagramChannels,
    addMessengerChannels, addWhatsappChannel,
    getAllChannels,
    getAllInstagramChannels,
    getAllMessengerChannels,
    getAllWhatsappChannels
} from "./API/Api";
import { Tabs } from 'antd';
import TableContent from "./tableContent";
import {useNavigate} from 'react-router-dom';
const Channels = () => {
    const { t } = useTranslation();
    const Ismobile = useMediaQuery ({maxWidth: 767})
    const navigation=useNavigate()
    const onChange = (key) => {
        console.log(key);
    };

    let channelsdata = [
        {
            icon:<FaFacebook style={{height: Ismobile? 20 : 40, width:Ismobile? 20 : 40, color:"#4267B2"}}/>,
            Channel:t("Facbook"),
            state:"whatsapp"
        },
        {
            icon:<FaInstagram style={{height: Ismobile? 20 : 40, width:Ismobile? 20 : 40, color:"#E1306C"}}/>,
            Channel:t("Instagram"),
            state:"instagram"
        },
        {
            icon:<FaFacebookMessenger style={{height: Ismobile? 20 : 40, width:Ismobile? 20 : 40}}/>,
            Channel:t("Messenger"),
            state:"messenger"
        },
    ]
    const items = [
        {
            key: '1',
            label: 'Whatsapp',
            children: <TableContent
                mode={"whatsapp"}
                getAllData={getAllWhatsappChannels}
                addChannel={addWhatsappChannel}
            />,
        },
        {
            key: '2',
            label: 'Messenger',
            children: <TableContent
                mode={"whatsapp"}
                getAllData={getAllMessengerChannels}
                addChannel={addMessengerChannels}
            />,
        },
        {
            key: '3',
            label: 'Instagram',
            children: <TableContent
                mode={"whatsapp"}
                getAllData={getAllInstagramChannels}
                addChannel={addInstagramChannels}
            />,
        },
    ];
  return (
      <div className={"synctic-module-container"}>
          <div className="cards-box">
              <div>
                  <div className='synctic-module-def'>
                      <QueuePlayNextIcon className="icon" />
                      <div className="heading">{t("Channels")}</div>
                      <div className="sub-heading">{t("Add-New-Channels-and-Configure-your-existing-ones")}</div>
                  </div>
              </div>
          </div>

          <div className="channel-container">
              {
                  channelsdata.map(item=><div className="cards-box" style={{marginTop:20}}>
                      <div className="channel-icon">
                          {item.icon}
                          <div onClick={()=>navigation(`/channels/${item.state}`)} style={{color:"rgb(37, 99, 235)"}}>+</div>
                      </div>
                      <div className='item-data'>{item.Channel}</div>
                      <div className='item-data-discription'>{t("Send-and-receive-messages-within-your-inbox")}</div>
                  </div>)
              }

          </div>

          {/*<Tabs defaultActiveKey="1" items={items} onChange={onChange} />*/}
      </div>
  );
}

export default Channels;
