import { useState } from "react";
import "./Workspace.scss";
import {BsFillBuildingsFill} from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader';
import {Modal, Upload, message} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import { Input , Select , Button  } from 'antd';
import { useSelector } from "react-redux";



const Workspacefunc = () => {
    const { t } = useTranslation();
    const { TextArea } = Input;
    const [state , setstate] = useState({
        address: "",
        description: "",
        vertical: "",
        about: "",
        email: "",
        websites: "",
        profile_picture_url: "",
        fileList: [],
        previewVisible: false,
        previewTitle: '',
        previewImage: '',
        Loader: false
    })

 
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setstate(prevState => ({
            ...prevState,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        }));
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const handleChange = ({fileList}) => {
        setstate(prevState => ({
            ...prevState,
            fileList
        }));
    };

    const handleCancel = () => setstate(prevState => ({
        ...prevState,
        previewVisible: false
    }));

const options = [
  "OTHER",
  "AUTO",
  "BEAUTY",
  "APPAREL",
  "EDU",
  "ENTERTAIN",
  "EVENT_PLAN",
  "FINANCE",
  "GROCERY",
  "GOVT",
  "HOTEL",
  "HEALTH",
  "NONPROFIT",
  "PROF_SERVICES",
  "RETAIL",
  "TRAVEL",
  "RESTAURAN"
];

    return (
        <div className={"synctic-module-container"}>
            <SettingsHeader
            icon={<BsFillBuildingsFill className='icon'/>}
            heading={t("Workspace")}
            description={t("Manage-Your-WorkSpace-Settings")}
            />

            <div className='cards-box' style={{marginTop:"10px"}}>
            <div className="profile-pic-container">
            <Upload
                listType="picture-card"
                fileList={state.fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                accept="image/*"
            >
                {state.fileList.length >= 1 ? null : (
                    <div>
                        <UploadOutlined style={{fontSize: '20px'}}/>
                        <div style={{textAlign: 'center'}}>Upload</div>
                    </div>
                )}
            </Upload>
            <Modal visible={state.previewVisible} title={state.previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="Preview" style={{width: '100%'}} src={state.previewImage}/>
            </Modal>
        </div>

        <form style={{display:"flex",flexDirection:"column"}}>
        <div className='main-name-api'>
          <div className='api-label-name'>Address</div>
          <Input placeholder="Address"
           className='api-name-input'/>
          </div>

          <div className='main-name-api'>
          <div className='api-label-name'>Dscription</div>
          <TextArea rows={4} placeholder="Dscription"
           className='api-name-input'/>
          </div>

          <div className='main-name-api'>
            <div className='api-label-name'>Options</div>
            <Select style={{width:"100%",marginTop:"1%"}} defaultValue="OTHER">
                {options.map((option) => (
                <Select.Option key={option} value={option}>
                    {option}
                </Select.Option>
                ))}
            </Select>
            </div>

            <div className='main-name-api'>
          <div className='api-label-name'>About</div>
          <Input placeholder="About"
           className='api-name-input'/>
          </div>

          <div className='main-name-api'>
          <div className='api-label-name'>Email</div>
          <Input placeholder="Email"
           className='api-name-input'/>
          </div>

          <div className='main-name-api' >
          <div className='api-label-name'>websites</div>
          <div style={{display:"flex",gap:"10px",marginTop:"1%"}}>
          <Input placeholder='Website' style={{width:"40%"}}/>
          <Input placeholder='Website' style={{width:"67%"}}/>
          </div>
          </div>

          </form>

          <div style={{ display: "flex", gap: "10px", marginTop: "2%" }}>
          <Button
              type="primary" style={{ margin: "2% auto", width: "50%" }}>Submit</Button>
         </div>         
            </div>
        </div>
    );
};

export default Workspacefunc;
