import React, { useEffect } from 'react';
import io from 'socket.io-client';
import ActionsCreators from "./Modules/Inbox/states";
import {useDispatch} from "react-redux";

const socket = io('wss://socket.tiebot.io');
const Sockets = () => {
    const dispatch= useDispatch()
    useEffect(() => {
        socket.on('connect', () => {
            console.log('connected to server');
        })
        socket.on('whatsapp_message', (message) => {
            {dispatch(ActionsCreators.updateWhatsappConversation(message))}
        })

        socket.on('whatsapp_inbox', (message) => {
            {dispatch(ActionsCreators.updateWhatsappAllConversation(message))}
        })

        socket.on('new_messenger_message', (message) => {
            {dispatch(ActionsCreators.updateMessengerConversation(message))}
        })

        socket.on('disconnect', () => {
            console.log('Socket disconnecting');
        })

    }, []);
};

export default Sockets;