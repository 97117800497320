import React, {useState} from 'react';
import Loader from "../ResuseableComponnets/Loader";
import {Tabs} from 'antd';
import "./styles.scss"
import BuildFromScratch from "./botsTypes/BuildFromScratch";
import {
    activateFlowApi,
    deActivateAllFlows,
    deActivateFlowApi,
    getAllFlows,
    activateTemplateFlowApi,
    deActivateTemplatesAllFlows,
    deActivateTemplatesFlowApi,
    getAllTemplatesFlows
} from "./Apis/Apis";
import {SettingFilled} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";

const AllChatBotBuilder = () => {
    const [state, setState] = useState({
        filterName:'1'
    })
    const navigate=useNavigate()
    const items = [
        {
            key: '1',
            label: 'Build from scratch'
        },
        {
            key: '2',
            label: 'Build from templates'
        }
    ];
    const onChange = (key) => {
        setState({...state, filterName: key}
        )
    };
    return (
        state.isLoader ? <Loader
                message={state.mode === "newFlow" ? "Adding New Flow" : state.mode === "edit" ? "Updating Flow" : ""}/> :
            <div className={"synctic-module-container"} style={{height:'auto', overflow:'hidden'}}>
                <div className={'cards-box tab-container'} style={{padding:0}}>
                    <Tabs
                        tabBarExtraContent={<SettingFilled onClick={() => navigate(`/chat-bot/settings`)}
                                           style={{fontSize: 24, marginRight: 30, opacity: .7}}/>}
                        defaultActiveKey={state.filterName}
                        items={items}
                        onChange={onChange}
                        indicatorSize={(origin) => origin - 16}
                        style={{paddingLeft: 24, paddingRight: 24}}
                    />
                </div>
                {
                    state.filterName==='1' ?
                        <BuildFromScratch
                            getAllFlows={getAllFlows}
                            activateFlow={activateFlowApi}
                            deActivateFlowApi={deActivateFlowApi}
                            deActivateAllFlows={deActivateAllFlows}
                            module={'BuildFromScratch'}
                        /> :
                        state.filterName==='2' &&
                        <BuildFromScratch
                            getAllFlows={getAllTemplatesFlows}
                            activateFlow={activateTemplateFlowApi}
                            deActivateFlowApi={deActivateTemplatesFlowApi}
                            deActivateAllFlows={deActivateTemplatesAllFlows}
                            module={'BuildFromTemplates'}
                        />
                }
            </div>
    );
}

export default AllChatBotBuilder;
