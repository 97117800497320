import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {useEffect, useState} from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBar(props) {
  const [state, setState] = useState({
    isOpen:false,
    message:"",
    severity:""
  })
  
  const handleClose = (event, reason) => {
  setState({...state, isOpen: false, message: "", severity: ""})
  };

  useEffect(() => {
    (!!props.severity || !!props.message) && setState({...state, isOpen: true, severity: props.severity, message: props.message})

  }, [props.notificationValue]);

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={state.isOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={props.severity} sx={{ width: '100%' }}>
          {state.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}