const mediaTypes = {
    'image/jpeg': 'image',
    'image/png': 'image',
    'image/jpg': 'image',
    'video/mp4': 'video',
    'audio/mp3': 'audio',
    'audio/mpeg': 'audio',
    'application/pdf': 'document'
};

export default function getMediaType(mimeType) {
    return mediaTypes[mimeType] || null;
}
