import React from 'react'
import axios from "../../../Authentication/headers"


export function apiall(){
  return axios.get(`/api/all`)
}

export function apicreate(data){
  console.log(data, 3333333333333)
  return axios.post(`/api/create`,data)
}

export function apiupdate(id,data){
  console.log(data)
  return axios.put(`/api/update/${id}`,data)
}

export function api(id){
  return axios.get(`/api/${id}`)
}

export function apideactivateAll(){
  return axios.get(`/api/deactivateAll`)
}

export function Apiactiveall(){
  return axios.get(`/api/activatedAPI`)
}

export function activedApi(id){
  return axios.get(`/api/activate/${id}`)
}


export function DeactivedApi(id){
  return axios.get(`/api/deactivate/${id}`)
}

export function Delete(id){
  return axios.get(`/api/delete/${id}`)
}

