import React, {useEffect} from 'react';
import Inbox from "../Inbox";
import {useSelector} from "react-redux";
import {assignAgentMessenger, assignMessengerStatus, getMessengerChat} from "../Apis";
import ActionsCreators from "../states";
import {useDispatch} from "react-redux";
import MessengerImage from "../../../assets/svg/social-logos/messenger.png";
import config from '../../../config';

const Messenger = () => {
    const dispatch = useDispatch()
    let url = config.api.baseUrl + '/messenger/send'
    let conversations= useSelector(state => state.inbox.allMessengerConversations)
    let assignStatusFunc=assignMessengerStatus
    let assignAgentFunc=assignAgentMessenger
    useEffect(() => {
        {dispatch(ActionsCreators.getAllMessengerConversations())}
    }, []);
    return <Inbox
        url = {url}
        conversations={conversations}
        getChat={getMessengerChat}
        channel={"messenger"}
        assignStatusFunc={assignStatusFunc}
        assignAgentFunc={assignAgentFunc}
        channelLogo={MessengerImage}
    />
}
export default Messenger