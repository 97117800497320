import React, {useState} from "react";
import {Button, Checkbox, Modal, Table, Tabs} from "antd";
import AgentsFilterTable from "../AgentPerformance/AgentsFilterTable";
import Cards from "../reuseableComponents/Cards";
import './AgentConversations.scss';
import Line from "../../../ResuseableComponnets/Charts/Line";

const AgentConversations = (props) => {
    const [state, setState] = useState({
        initiated: false,
        open: false,
        inProgress: false,
        closed: false,
        pending: false,
        excited: false,
        cis: false,
        cts: false,
        cwc: false,
        mode:'',
        whatsapp:'',
        instagram:'',
        messenger:''
    })
    let {title, value, isModalOpen, mode, modalOpenFunc} = props
    const modalFooter = [
        <Button
            key="cancel"
            onClick={() => modalOpenFunc()}
        >
            Cancel
        </Button>,
        <Button
            key="apply"
            onClick={() => modalOpenFunc()}
            primary
            style={{display: mode === 'export' ? 'none' : ''}}
        >
            Apply
        </Button>
    ];
    const AgentsAvailibilityColumns = [
        {
            title: 'Agent Name',
            dataIndex: 'agentName',
            key: '1',
        },
        {
            title: 'Login ID',
            dataIndex: 'loginId',
            key: '2',
        },
        {
            title: 'Login Time',
            dataIndex: 'loginTime',
            key: '3',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: '4',
        }
    ];
    const AgentsBoardColumns = [
        {
            title: 'Conversation tags',
            dataIndex: 'conversationTags',
            key: '1',
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: '2',
        }
    ];
    const AgentsAvailibilityData = [];
    const items = [
        {
            key: '1',
            label: 'Channels',
            children: <div>
                <div style={{fontWeight: '550'}}>Channel</div>
                <div style={{marginTop:6}}/>
                <Checkbox
                    checked={state.whatsapp}
                    onChange={()=>setState({...state, whatsapp: !state.whatsapp})}
                >Whatsapp</Checkbox>
                <div style={{marginTop:4}}/>
                <Checkbox
                    checked={state.messenger}
                    onChange={()=>setState({...state, messenger: !state.messenger})}
                >Messenger</Checkbox>
                <div style={{marginTop:4}}/>
                <Checkbox
                    checked={state.instagram}
                    onChange={()=>setState({...state, instagram: !state.instagram})}
                >Instagram</Checkbox>
                <div style={{marginTop:4}}/>
            </div>
        }
    ];
    const data = {
        labels: ['January', 'February', 'March', 'April', 'May'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [65, 59, 80, 81, 56],
                borderColor: 'red',
                borderWidth: 1,
                fill: false
            },
            {
                label: 'Dataset 2',
                data: [28, 48, 40, 19, 86],
                borderColor: 'blue',
                borderWidth: 1,
                fill: false
            },
            {
                label: 'Dataset 3',
                data: [2, 32, 10, 22, 16],
                borderColor: 'green',
                borderWidth: 1,
                fill: false
            }
            // Add more datasets as needed
        ]
    };
    const channelWiseData = {
        labels: ['January', 'February', 'March', 'April', 'May'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [65, 59, 80, 81, 56],
                borderColor: 'red',
                borderWidth: 1,
                fill: false
            }
        ]
    };
    return <div>
        <div className='count-card-container'>
            <Cards title={'Initiated'} value={'4'}/>
            <Cards title={'Open'} value={'5'}/>
            <Cards title={'In Progress'} value={'9'}/>
            <Cards title={'Solved'} value={'33'}/>
            <Cards title={'Pending'} value={'12'}/>
            <Cards title={'Excited By Customer'} value={'41'}/>
        </div>
        <div className={'agents-availability-container'}>
            <div className={'cards-box'} style={{ width: '30%' }}>
                <h6>Conversation tags</h6>
                <Table
                    columns={AgentsBoardColumns}
                    dataSource={AgentsAvailibilityData}
                    style={{
                        marginTop: 24,
                    }}
                />
            </div>
            <div className={'cards-box'} style={{ width: '70%' }}>
                <h6>Conversation Initiated Vs In-Progress Vs Solved</h6>
                <Line height={240} data={data}/>
            </div>
        </div>
        <div className={'cards-box'} style={{
            width: '100%',
            marginTop:10
        }}>
            <h6>Channel Wise Conversations</h6>
            <Line height={240} data={channelWiseData} />
        </div>
        <Modal
            title={ mode==='export' ? 'Export Insights' : mode==='export' && 'Filter Agent Performance'}
            open={isModalOpen}
            onCancel={() => {
                modalOpenFunc()
                setState({...state,
                    mac: false,
                    tCustomers: false,
                    whatsapp: false,
                    instagram: false,
                    messenger: false
                })
            }
            }
            footer={modalFooter}
            closable={()=> {
                modalOpenFunc()
                setState({
                    ...state,
                    mac: false,
                    tCustomers: false,
                    whatsapp: false,
                    instagram: false,
                    messenger: false
                })
            }}
        >
            {
                props.mode==='export' ?
                    <div>
                        <div style={{fontWeight: '550'}}>Include breakdown by</div>
                        <div style={{marginTop:6}}/>
                        <Checkbox
                            checked={state.initiated}
                            onChange={()=>setState({...state, initiated: !state.initiated})}
                        >Initiated</Checkbox>
                        <div style={{marginTop:4}}/>
                        <Checkbox
                            checked={state.open}
                            onChange={()=>setState({...state, open: !state.open})}
                        >Open</Checkbox>
                        <div style={{marginTop:4}}/>
                        <Checkbox
                            checked={state.inProgress}
                            onChange={()=>setState({...state, inProgress: !state.inProgress})}
                        >In Progress</Checkbox>
                        <div style={{marginTop:4}}/>
                        <Checkbox
                            checked={state.closed}
                            onChange={()=>setState({...state, closed: !state.closed})}
                        >Closed</Checkbox>
                        <div style={{marginTop:4}}/>
                        <Checkbox
                            checked={state.pending}
                            onChange={()=>setState({...state, pending: !state.pending})}
                        >Pending</Checkbox>
                        <div style={{marginTop:4}}/>
                        <Checkbox
                            checked={state.excited}
                            onChange={()=>setState({...state, excited: !state.excited})}
                        >Excited</Checkbox>
                        <div style={{marginTop:4}}/>
                        <Checkbox
                            checked={state.cis}
                            onChange={()=>setState({...state, cis: !state.cis})}
                        >Conversation Initiated Vs In-Progress Vs Solved</Checkbox>
                        <div style={{marginTop:4}}/>
                        <Checkbox
                            checked={state.cts}
                            onChange={()=>setState({...state, cts: !state.cts})}
                        >Conversation Tags Stats</Checkbox>
                        <div style={{marginTop:4}}/>
                        <Checkbox
                            checked={state.cwc}
                            onChange={()=>setState({...state, cwc: !state.cwc})}
                        >Channel-wise Conversations</Checkbox>
                    </div> : mode==='filter'
                    && <Tabs
                        defaultActiveKey="1"
                        tabPosition={'top'}
                        items={items}
                    />
            }
        </Modal>
    </div>
}

export default AgentConversations;
