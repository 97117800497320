import React from "react";
import axios from "../../../Authentication/headers"

export function getAllTags(){
    return axios.get(`/tags/all`)
}

export function getAllActivatedTags(){
    return axios.get(`/tags/activated`)
}
export function saveTags(data){
    return axios.post(`/tags/create`, data)
}

export function assignTagToChat(data){
    return axios.post(`/message/assignTagToChat`, data)
}

export function activateTags(id){
    return axios.get(`/tags/activate/${id}`)
}

export function deActivateTags(id){
    return axios.get(`/tags/deactivate/${id}`)
}

export function deleteTags(id){
    return axios.get(`/tags/delete/${id}`)
}

export function updateTags(id, data){
    return axios.put(`/tags/update/${id}`, data)
}

