import {useEffect, useState} from 'react';
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader';
import { Radio, Button } from 'antd';
import dayjs from 'dayjs';
import { TimePicker } from 'antd';
import { Checkbox } from 'antd';
import {getBusinessHours, saveBusinessHours} from "./API/Api";
import moment from "moment";
import { useTranslation } from 'react-i18next';


const Businesshour = () => {
    const {t} = useTranslation();

    const Weekdays = [
        t("Monday"),
        t("Tuesday"),
        t("Wednesday"),
        t("Thursday"),
        t("Friday"),
        t("Saturday"),
        t("Sunday")
    ]


    const [state, setState] = useState({
        allDay: {
            startTime: "2022-12-01T00:00:00",
            endTime: "2022-12-01T00:00:00"
        },
        monday: {
            startTime: "2022-12-01T00:00:00",
            endTime: "2022-12-01T00:00:00"
        },
        tuesday: {
            startTime: "2022-12-01T00:00:00",
            endTime: "2022-12-01T00:00:00"
        },
        wednesday: {
            startTime: "2022-12-01T00:00:00",
            endTime: "2022-12-01T00:00:00"
        },
        thursday: {
            startTime: "2022-12-01T00:00:00",
            endTime: "2022-12-01T00:00:00"
        },
        friday: {
            startTime: "2022-12-01T00:00:00",
            endTime: "2022-12-01T00:00:00"
        },
        saturday: {
            startTime: "2022-12-01T00:00:00",
            endTime: "2022-12-01T00:00:00"
        },
        sunday: {
            startTime: "2022-12-01T00:00:00",
            endTime: "2022-12-01T00:00:00"
        }
    })
    const [showSetTime, setShowSetTime] = useState(true);
    const [loader, setLoader] = useState(false);

    const saveFunction = () =>{
        setLoader(true)
        saveBusinessHours(!showSetTime ? state : {allDay: state.allDay} ).then(res=>
                console.log(res)
        ).catch(()=>setLoader(false))
    }
    const getFunction = () => {
        setLoader(true)
        getBusinessHours().then(res=> {
            console.log(res.data.data, 888111111)
            setLoader(false)
        })
    }
    const Set_Time = () => {
        const format = 'HH:mm';
        return (
            <div style={{ display: 'flex', gap: '23px', marginTop: '21px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ fontWeight: '600' }}>{t("StartTime")}</div>
                    <TimePicker onChange={(t, time)=> {
                        let timeFormat = moment(time, 'HH:mm').format('YYYY-MM-DDTHH:mm:ss')
                        console.log(timeFormat, "323311112")
                        setState({
                            ...state,
                            allDay: {
                                ...state.allDay,
                                startTime: timeFormat,
                            }
                        })}}
                                defaultValue = {dayjs(state.allDay?.startTime,  'HH:mm')}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ fontWeight: '600' }}>{t("EndTime")}</div>
                    <TimePicker onChange={(t, time)=> {
                        let timeFormat = moment(time, 'HH:mm').format('YYYY-MM-DDTHH:mm:ss')
                        setState({
                            ...state,
                            allDay: {
                                ...state.allDay,
                                endTime: timeFormat,
                            }
                        })}
                    }
                                defaultValue={
                                    dayjs(state.allDay?.endTime, 'HH:mm')
                                }
                    />
                </div>
            </div>
        );
    };

    useEffect(() => {
        getFunction()
    }, []);
    return (
        <>
        <div className='synctic-module-container'>
        <SettingsHeader
                    heading={t("Businesshours")}
                    description={t("EnableBusinesshours-dis")}
                />
            <div className="cards-box"style={{marginTop:"10px"}}>
                

                <Radio.Group
                    style={{ display: 'flex', gap: '4%', marginTop: '2%' }}
                    onChange={(e) => setShowSetTime(e.target.value === 'allDays')}
                    value={showSetTime ? 'allDays' : 'perDays'}
                >
                    <Radio style={{ fontWeight: '500' }} value="allDays">
                    {t("AllDays")}
                    </Radio>
                    <Radio style={{ fontWeight: '500' }} value="perDays">
                    {t("PerDays")}
                    </Radio>
                </Radio.Group>

                {showSetTime ? (
                    <Set_Time />
                ) : (
                    Weekdays.map((day, index) => (
                        <div key={index} style={{ display: 'flex', gap: '23px', marginTop: '21px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Checkbox style={{ fontWeight: '500' }}>{day}</Checkbox>
                                <div style={{ fontWeight: '600' }}>{t("StartTime")}</div>
                                <TimePicker
                                    onChange={(t, time)=> {
                                        let timeFormat = moment(time, 'HH:mm').format('YYYY-MM-DDTHH:mm:ss')
                                        day==="Monday"?
                                            setState({...state, monday: {
                                                    ...state.monday,
                                                    startTime: timeFormat,
                                                }}) :
                                            day==="Tuesday"? setState({...state, tuesday: {
                                                        ...state.tuesday,
                                                        startTime: timeFormat,
                                                    }}) :
                                                day==="Wednesday"? setState({...state, wednesday: {
                                                            ...state.wednesday,
                                                            startTime: timeFormat,
                                                        }}) :
                                                    day==="Thursday"?
                                                        setState({...state, thursday: {
                                                                ...state.thursday,
                                                                startTime: timeFormat,
                                                            }}) :
                                                        day==="Friday"?
                                                            setState({...state, friday: {
                                                                    ...state.friday,
                                                                    startTime: timeFormat,
                                                                }}) :
                                                            day==="Saturday"?
                                                                setState({...state, saturday: {
                                                                        ...state.saturday,
                                                                        startTime: timeFormat,
                                                                    }}) :
                                                                setState({...state, sunday: {
                                                                        ...state.sunday,
                                                                        startTime: timeFormat,
                                                                    }})
                                    }
                                    }
                                    defaultValue={dayjs( day==="Monday"? state.monday.startTime :
                                        day==="Tuesday"? state.tuesday.startTime :
                                            day==="Wednesday"? state.wednesday.startTime :
                                                day==="Thursday"? state.thursday.startTime :
                                                    day==="Friday"? state.friday.startTime :
                                                        day==="Saturday"? state.saturday.startTime :
                                                            state.sunday.startTime  , 'HH:mm')}
                                    format="HH:mm"
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '22px' }}>
                                <div style={{ fontWeight: '600' }}>{t("EndTime")}</div>
                                <TimePicker
                                    onChange={(t, time)=> {
                                    let timeFormat = moment(time, 'HH:mm').format('YYYY-MM-DDTHH:mm:ss')
                                    day==="Monday"?
                                        setState({...state, monday: {
                                                ...state.monday,
                                                endTime: timeFormat,
                                            }}) :
                                        day==="Tuesday"? setState({...state, tuesday: {
                                                    ...state.tuesday,
                                                    endTime: timeFormat,
                                                }}) :
                                            day==="Wednesday"? setState({...state, wednesday: {
                                                        ...state.wednesday,
                                                        endTime: timeFormat,
                                                    }}) :
                                                day==="Thursday"?
                                                    setState({...state, thursday: {
                                                            ...state.thursday,
                                                            endTime: timeFormat,
                                                        }}) :
                                                    day==="Friday"?
                                                        setState({...state, friday: {
                                                                ...state.friday,
                                                                endTime: timeFormat,
                                                            }}) :
                                                        day==="Saturday"?
                                                            setState({...state, saturday: {
                                                                    ...state.saturday,
                                                                    endTime: timeFormat,
                                                                }}) :
                                                            setState({...state, sunday: {
                                                                    ...state.sunday,
                                                                    endTime: timeFormat,
                                                                }})
                                }}
                                            defaultValue={dayjs( day==="Monday"? state.monday.endTime :
                                                    day==="Tuesday"? state.tuesday.endTime :
                                                        day==="Wednesday"? state.wednesday.endTime :
                                                            day==="Thursday"? state.thursday.endTime :
                                                                day==="Friday"? state.friday.endTime :
                                                                    day==="Saturday"? state.saturday.endTime :
                                                                        state.sunday.endTime  ,
                                                'HH:mm')} format="HH:mm" />
                            </div>
                        </div>
                    ))
                )}
                <div style={{display:"flex",justifyContent:"flex-end"}}>
                <Button onClick={()=>saveFunction()} type="primary" style={{  marginTop: '3%', backgroundColor: '#10163a' }}>
                    {t("Save")}
                </Button>
                </div>
            </div>
            </div>
        </>
    );
};

export default Businesshour;
