import  { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { RiContactsFill } from "react-icons/ri";
import Button from '@mui/material/Button';
import Table_user from './Table_user';
import { FcSettings } from "react-icons/fc";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';


const UserInput = () => {
  const Ismobile = useMediaQuery({maxWidth: 767})
  const [teamValue, setTeamValue] = useState('');
  const [roleValue, setRoleValue] = useState('');
  const [age, setAge] = useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleTeamChange = (event) => {
    setTeamValue(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRoleValue(event.target.value);
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="main-input-container">
        <div className="Invite">{t("Invite-a-Team-Member")}</div>

        <div className='input-filled'>
          <Box className='box-input-filleds' component="form" noValidate autoComplete="off">
            <TextField
              id="outlined-multiline-flexible"
              label={t("Enter")}
              multiline
              rows={1}
              size="small"
              style={{ marginBottom: Ismobile? "11px" : '16px', marginRight: '10px', width: '40%' }}
            />

            <FormControl sx={{ m: 1, minWidth: 120, marginLeft: "-1%" ,marginTop: "0%" }} size="small">
              <InputLabel id="demo-select-small-label">{t("Team")}</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                style={{width:Ismobile? "42%" : "70%", marginRight: "10%"}}
                value={age}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value="">
                  
                </MenuItem>
                
                <MenuItem value={30}>{t("My-Team")}</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 , marginLeft: Ismobile? "-1%" : "-5%", marginTop: "0%" }} size="small">
              <InputLabel id="demo-select-small-label">{t("Role")}</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                style={{width: Ismobile? "42%" :"70%"}}
                value={age}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value="">
                </MenuItem>
                <MenuItem value={10}><FcSettings style={{marginRight: "10px"}}/> {t("Admin")}</MenuItem>
                <MenuItem value={20}><RiContactsFill style={{marginRight: "10px"}}/>{t("Member")}</MenuItem>
              </Select>
            </FormControl>
            <Button style={{height:"fit-content"}} className='send-invite' variant="contained" ><RiContactsFill style={{ marginRight: "5%" }} /> Send Invite</Button>
          </Box>
        </div>
      </div>
      <Table_user />
    </>
  );
};

export default UserInput;
