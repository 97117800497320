import React, { useState } from 'react';
import './Api.scss';
import Api_table from './Api_table';
import { useTranslation } from 'react-i18next';
import { Dropdown, Menu, Button } from 'antd';
import {CaretDownOutlined, SearchOutlined} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { apideactivateAll, Apiactiveall } from "./Api/Api";
import Loader from '../../ResuseableComponnets/Loader';
import {IoIosAddCircleOutline} from 'react-icons/io';


const Api_management = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    AllData: [],
    loading: false,
    filterName:'All apis'
  });

  const deactivate = () => {
    setState(prevState => ({ ...prevState, loading: true })); 
    apideactivateAll()
      .then(response => {
        setState(prevState => ({
          ...prevState,
          AllData: response.data.data,
          loading: false
        }));
        console.log(response.data.data);
      })
      .catch(error => {
        setState(prevState => ({ ...prevState, loading: false }));
      });
  };

  const Active = () => {
    setState(prevState => ({ ...prevState, loading: true })); 
    Apiactiveall()
      .then(response => {
        setState(prevState => ({
          ...prevState,
          AllData: response.data.data,
          loading: false,
            filterName: 'All activated api'
        }));
        console.log(response.data.data);
      })
      .catch(error => {
        console.error('Error activating APIs:', error);
        setState(prevState => ({ ...prevState, loading: false }));
      });
  };

  return (
    <div className="synctic-module-container">
      <div className='cards-box' style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontSize: "20px", fontWeight: "500", opacity: ".7" }}>Api</div>
          <p style={{ opacity: '.7' }}>Manage your work description</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: "20px" }}>
          <Dropdown overlay={(
            <Menu>
              <Menu.Item key="1" onClick={()=>navigate('/settings/api-management')}>
                All Apis
              </Menu.Item>
                <Menu.Item key="2" onClick={Active}>
                    All activated api
                </Menu.Item>
            </Menu>
          )} trigger={['click']}>
            <a onClick={(e) => e.preventDefault()} style={{ display: 'flex', alignItems: 'center' }}>
                {state.filterName} <CaretDownOutlined style={{ fontSize: '14px', marginLeft: 5 }} />
            </a>
          </Dropdown>
            <Button
                onClick={deactivate}
                type="primary" 
            >
                De-activate all
            </Button>
            <Button
             type="primary"
             onClick={() => navigate("/Settings/Api_mangement/Apiform")}
             >
              <IoIosAddCircleOutline
                style={{fontSize: '15px', color: '#fff', marginRight: '12px', marginTop: '-2%'}}
                                />
               New  Api Key
                </Button>

        </div>
      </div>
      <div className='main-access-key' style={{fontSize:"20px"}}>
     </div>
      {state.loading ? <Loader /> : (
        <>
          <Api_table />
        </>
      )}
    </div>
  );
};

export default Api_management;
