import React from "react";
import ConvPerAgentChart from "./Chart";
import LineChart from "./LineChart";
import { useTranslation } from 'react-i18next';


const ReportsStats = () => {
    const { t } = useTranslation();
    const ReportsData = [
        {
            title:t("MessagesReceived")
        },
        {
            title:t("ResolutionTime")
        },{
            title:t("AvCustomerWaitingTime")
        },{
            title:t("OpenedConversation")
        },{
            title:t("ClosedConversation")
        },
    ]
    return <>
        <div className="main-states-container">
            {
                ReportsData.map(item => <div className="states-main">
                    <div className="heading">{item.title}</div>
                    <div>-</div>
                    <div>0%</div>
                </div>)
            }
        </div>
        <div  className="Team-Agents">{t("Team-Agents")}</div>
        <div style={{fontSize: 12, opacity: .7, marginLeft:"3%"}}>{t("Understand-how-your-team-and-agents-are-performing")}</div>

        <div className="chartsParent">
            <div className="cards-box">
                <div className="heading">{t("Conversations-per-agents")}</div>
                <div style={{marginTop:"4%"}}>
                    <ConvPerAgentChart/>
                </div>
            </div>
            <div className="cards-box">
                <div className="heading">{t("Resolution-Time")}</div>
                <div style={{marginTop:"4%"}}>
                    <LineChart/>
                </div>
            </div>
        </div>
        <div>

        </div>
    </>
}
export default ReportsStats