import React from 'react';
import "./Inbox_rules.scss";
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader';
import { IoIosFlash } from "react-icons/io";
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const Inbox_rules = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  return (
    <div className="synctic-module-container">
      <SettingsHeader
        icon={<IoIosFlash className='icon' />}
        heading={t("Inbox-Rules")}
        description={t("Automatically-take-action-discription")}
      />

      <div className='cards-box' style={{ width: "270px", marginTop: "1%" }}>
        <p className='inbox-rules-p'>{t("Rules-are-applied-discription")}</p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained">{t("New-Rules-btn")}</Button>
        </div>
        <hr className='new-rules-hr'></hr>
        <div className='bot-border-rules'>
          <p>{t("Your-haven't-created-any")} <br></br> {t("rules")}</p>
        </div>
      </div>
    </div>
  );
}

export default Inbox_rules;
