export const myProfile = (data) => {
    return (dispatch) => {
        try {
            dispatch({
                type:"MY_PROFILE",
                payload:data
            })
        } catch {
            console.log("Error while getting your profile.")
        }
    }
}