import React, {useState, useEffect} from 'react';
import {Modal, Upload, message} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import './Profilestyle.scss';
import axios from 'axios';
import config from '../../../config';
import {useSelector} from "react-redux";
import {GetAccountInfo} from "./Api/Api";
import Loader from '../../ResuseableComponnets/Loader';

const ProfilePicUploader = () => {
    const [state, setState] = useState({
        fileList: [],
        previewVisible: false,
        previewTitle: '',
        previewImage: '',
        Loader: false
    });

    const myId = useSelector(state => state?.me?.me?.id)
    const DataId = useSelector(state => state?.me?.me?.id)


    const ImageFrom = async () => {
        setState(prevState => ({
            ...prevState,
            Loader: true
        }));
        !!DataId && GetAccountInfo(DataId)
            .then((res) => {
                const profilePic = res?.data?.data?.profilePic;
                setState(prevState => ({
                    ...prevState,
                    Loader: false
                }))

                setState(prevState => ({
                    ...prevState,
                    fileList: profilePic ? [{
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: profilePic
                    }] : [],
                    profilePic: profilePic

                }));
            }).catch(()=>console.log())
    };


    useEffect(() => {
        ImageFrom()
    }, []);


    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setState(prevState => ({
            ...prevState,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        }));
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const handleChange = ({fileList}) => {
        setState(prevState => ({
            ...prevState,
            fileList
        }));
        const formData = new FormData();
        formData.append('profile', fileList[0]?.originFileObj);

        axios.post(`${config.api.baseUrl}/account/saveProfile/${myId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(() => {
            console.log('Profile saved successfully');
        })
    };

    const handleCancel = () => setState(prevState => ({
        ...prevState,
        previewVisible: false
    }));

    return (
        <div className="profile-pic-container">
            <Upload
                listType="picture-card"
                fileList={state.fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                accept="image/*"
            >
                {state.fileList.length >= 1 ? null : (
                    <div>
                        <UploadOutlined style={{fontSize: '20px'}}/>
                        <div style={{textAlign: 'center'}}>Upload</div>
                    </div>
                )}
            </Upload>
            <Modal visible={state.previewVisible} title={state.previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="Preview" style={{width: '100%'}} src={state.previewImage}/>
            </Modal>
        </div>
    );
};

export default ProfilePicUploader;
