import React from 'react';
import "./Workspace.scss";
import Workspacefunc from './Workspacefunc';
import ChannelList from './ChannelList';
import { Tabs  } from 'antd';
import Information from './Information';



const Workspace = () => {
    const items = [
        {
            key: '1',
            label: 'Workspace',
            children: <Workspacefunc/>,
        },
        {
            key: '2',
            label: 'Channel List',
            children: <ChannelList/>,
        },
        {
            key: '3',
            label: 'Profile Information',
            children: <Information/>,
        }  
    ];

    return (
        <div className={'synctic-module-container'} style={{overflow:'hidden'}}>
        <div className={'cards-box'} style={{padding:0}}>
            <Tabs
                defaultActiveKey="1"
                tabPosition={'left'}
                items={items}
            />
        </div>
    </div> 
    );
};

export default Workspace;
