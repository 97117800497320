import React, {useState} from "react";
import {Button, Checkbox, Modal, Table, Tabs} from "antd";
import AgentsFilterTable from "../AgentPerformance/AgentsFilterTable";
import Cards from "../reuseableComponents/Cards";

const BotConversataions = (props) => {
    const [state, setState] = useState({
        tCustomers: false,
        mac: false,
        mode:'',
        whatsapp:'',
        instagram:'',
        messenger:''
    })
    let {title, value, isModalOpen, mode, modalOpenFunc} = props
    const modalFooter = [
        <Button
            key="cancel"
            onClick={() => modalOpenFunc()}
        >
            Cancel
        </Button>,
        <Button
            key="apply"
            onClick={() => modalOpenFunc()}
            primary
        >
            Apply
        </Button>
    ];
    const engagedCustomersColumns = [
        {
            title: 'WhatsApp Number',
            dataIndex: 'whatsappNum',
            key: '1',
        },
        {
            title: 'WhatsApp Profile Name',
            dataIndex: 'whatsAppProfileName',
            key: '2',
        },
        {
            title: 'Message Volume',
            dataIndex: 'messageVolume',
            key: '3',
        }
    ];
    const AgentsBoardColumns = [
        {
            title: 'Agent',
            dataIndex: 'agent',
            key: '1',
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            key: '2',
        },
        {
            title: 'Accepted',
            dataIndex: 'accepted',
            key: '3',
        },
        {
            title: 'Solved',
            dataIndex: 'solved',
            key: '4',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: '5',
        },
        {
            title: 'Avg. Response Time',
            dataIndex: 'avgResponseTime',
            key: '6',
        },
        {
            title: 'Avg. Resolution Time',
            dataIndex: 'avgResolutionTime',
            key: '7',
        }
    ];


    const AgentsAvailibilityData = [];
    const items = [
        {
            key: '1',
            label: 'Channels',
            children: <div>
                <div style={{fontWeight: '550'}}>Channel</div>
                <div style={{marginTop:6}}/>
                <Checkbox
                    checked={state.whatsapp}
                    onChange={()=>setState({...state, whatsapp: !state.whatsapp})}
                >Whatsapp</Checkbox>
                <div style={{marginTop:4}}/>
                <Checkbox
                    checked={state.messenger}
                    onChange={()=>setState({...state, messenger: !state.messenger})}
                >Messenger</Checkbox>
                <div style={{marginTop:4}}/>
                <Checkbox
                    checked={state.instagram}
                    onChange={()=>setState({...state, instagram: !state.instagram})}
                >Instagram</Checkbox>
                <div style={{marginTop:4}}/>
            </div>,
        },
        {
            key: '2',
            label: 'Agents',
            children:<AgentsFilterTable/>,
        },
    ];


    return <div>
        <div className='count-card-container'>
            <Cards title={'Messages Responded'} value={'1'}/>
            <Cards title={'Messaged Received'} value={'5'}/>
            <Cards title={'Avg. Chat Time'} value={'22'}/>
            <Cards title={'Total Customers'} value={'2,443'}/>
        </div>
        <div className={'cards-box'} style={{marginTop:10}}>
            <h6>Frequency of Categories Inquired</h6>
        </div>
        <div className={'cards-box'} style={{ marginTop:10 }}>
            <h6>Engaged Customers</h6>
            <Table
                columns={engagedCustomersColumns}
                dataSource={AgentsAvailibilityData}
                style={{
                    marginTop: 24,
                }}
            />
        </div>

        <Modal
            title={ mode==='export' ? 'Export Insights' : mode==='export' && 'Filter Agent Performance'}
            open={isModalOpen}
            onCancel={() => {
                modalOpenFunc()
                setState({...state,
                    mac: false,
                    tCustomers: false,
                    whatsapp: false,
                    instagram: false,
                    messenger: false
                })
            }
            }
            footer={modalFooter}
            closable={()=> {
                modalOpenFunc()
                setState({
                    ...state,
                    mac: false,
                    tCustomers: false,
                    whatsapp: false,
                    instagram: false,
                    messenger: false
                })
            }}
        >
            {
                state.mode==='export' ?
                    <div>
                        <div style={{fontWeight: '550'}}>Include breakdown by</div>
                        <div style={{marginTop:6}}/>
                        <Checkbox
                            checked={state.mac}
                            onChange={()=>setState({...state, mac: !state.mac})}
                        >Total Customers</Checkbox>
                        <div style={{marginTop:4}}/>
                        <Checkbox
                            checked={state.tCustomers}
                            onChange={()=>setState({...state, tCustomers: !state.tCustomers})}
                        >Frequency Of Categorizes Inquired</Checkbox>
                        <div style={{marginTop:4}}/>
                    </div> : mode==='filter'
                    && <Tabs
                        defaultActiveKey="1"
                        tabPosition={'top'}
                        items={items}
                    />
            }
        </Modal>
    </div>

}

export default BotConversataions
