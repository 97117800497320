import React, {useState} from "react";
import {Button, Modal} from "antd";
import { Input } from 'antd';
import AddIcon from '@mui/icons-material/Add';
import { MessageOutlined, LoginOutlined, LocalFireDepartment } from "@mui/icons-material";
import { useNodes } from "../../allnode";

export default (props) => {
  const {nodes} = useNodes();
  const [state, setState] = useState({
    isDialogueOpen:false,
    name:"",
    description:""
  })

  const onDragMessage = (event, nodeType, content) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("content", content);
    event.dataTransfer.setData("customNodeType", 'message');
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <aside className="flow-setting flow-header">
       <div className="flow-information d-flex align-items-center justify-content-between">
        {nodes.length === 0 && <span   
          className="input style-icon d-flex align-items-center justify-content-between"
          onDragStart={(event) => onDragMessage(event, "node", "message")}
          draggable
          title="Drag and drop in space"
          key={1}>
          <MessageOutlined style={{height:24, width:24, margin : "0 5px 0 0"}}/>
          Add Trigger 
        </span> } 
       </div>
       {props.loader ?
       <Button className="style-icon success-bt">
       <LocalFireDepartment style={{height:24, width:24, margin : "0 5px 0 0"}}/>
         Publishing Flow...
       </Button> 
          :
        <Button className="style-icon success-btn" onClick={()=>props.saveHandler()}>
          <LoginOutlined style={{height:24, width:24, margin : "0 5px 0 0"}}/>
            Publish Now
        </Button> 
        }

        <Button className="style-icon success-btn" style={{width:140, display:'flex', justifyContent:'center', alignItems:'center'}}
                onClick={()=>props.showDevice()}>
            {props.isDeviceShow ? 'Remove App' : 'Test Flow'}
        </Button>

        <Modal
            title={`Add Information`}
            open={state.isDialogueOpen}
            onOk={() => props.saveHandler()}
            onCancel={() => setState({...state, isDialogueOpen: false})}
        >
            <div style={{
                width:"100%",
                height:180
            }}>
            <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                height:"100%"
            }}>
                <div style={{
                    height:120,
                    width:120,
                    borderRadius:60,
                    backgroundColor:'rgba(0, 0, 0, 0.05)',
                    margin:"auto",
                    border:"1px dotted black",
                    fontSize:12,
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    flexDirection:"column"
                }}>
                    <AddIcon style={{height:24, width:24, opacity:.7}}/>
                    <p style={{textAlign:"center", margin:0, marginTop:6}}>Upload <br/> Banner</p>
                </div>
            </div>
            </div>
            <Input style={{marginTop:10}} onChange={(e)=>setState({...state, name: e.target.value})} placeholder="Enter name" />
            <Input.TextArea onChange={(e)=>setState({...state, description: e.target.value})} style={{marginTop:10, marginBottom:20}} showCount maxLength={100} placeholder="Enter description" />
        </Modal>
    </aside>
  );
};
