import React, {useEffect, useState} from 'react';
import './Teams.scss';
import { SiMicrosoftteams } from 'react-icons/si';
import Button from '@mui/material/Button';
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader';
import TeamInfo from "./TeamInfo";
import Tags_table from "../Tags/Tags_table";
import {createTeam, deleteTeam, getAllTeams, getTeam} from "./API/API";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useMediaQuery } from 'react-responsive';

const Teams = () => {
  const [state, setState] = useState({
    teamName:"",
    isDialogOpen:false,
    isValueChange:false,
    isTeamInfo:false,
    allTeams: [],
    columns:[],
    status:"",
    isDelete:false,
    teamId:"",
    getTeams:false
  })
const Ismobile = useMediaQuery({maxWidth: 767})
  const handleClose = () => {
    setState({...state, isDialogOpen: false})
  };
  const selectedTeam = (id) => {
    setState(prevState => ({...prevState, teamId: id, isTeamInfo: true}))
  }

  const onSave = (value) => {
    let data = {
      name:state.teamName,
      users:state.users,
      description:"usman"
    }
    !!state.teamName && createTeam(data).then(res=>{
      let id = res.data.data._id
      setState({
        ...state,
        teamName: value,
        isDialogOpen: !!value ? false : true,
        isValueChange: !state.isValueChange,
        isTeamInfo: !!value ?  true : false,
        teamId:id
      })
    }).catch(error=>console.log(error))

  }

  const onDeleteTeam = (id) => {
    setState({...state, isDelete: true, teamId: id, isDialogOpen: true})
  }
  const DeleteTeamFunc = () => {
    deleteTeam(state.teamId)
        .then(res=> {
              setState({...state, isDialogOpen: false, getTeams: !state.getTeams, isValueChange: !state.isValueChange, isTeamInfo: false})
            }
        )
        .catch(err=>console.log(err, "44411"))
  }

  const onDelete = () => {
    setState({...state, getTeams: !state.getTeams, isValueChange: !state.isValueChange, isTeamInfo: false})
  }

  const back = () => {
    setState({...state, isTeamInfo: false})
  }
  const deleteDialogue = () => {
    setState({...state, isDialogOpen: true, isDelete: true})
    console.log(state.isDialogOpen, "4411112")
  }

  useEffect(() => {
    getAllTeams().then(res=>setState({
      ...state,
      allTeams:res?.data?.data ? res.data.data : []
    })
    ).catch(error=>console.log(""))
  }, [state.getTeams || state.isTeamInfo]);
  return <div className="synctic-module-container">
    {
      state.isTeamInfo ? <TeamInfo
              DeleteTeamFunc={DeleteTeamFunc}
              deleteDialogue={deleteDialogue}
              teamId={state.teamId}
              teamName={state.teamName}
              back={back}
              onDelete={onDelete}
          /> :
    <div>
      <SettingsHeader
          icon={<SiMicrosoftteams className='icon' />}
          heading="Teams"
          description="Group Your in Teams, manage teams permission and assignment rules."
      />

      <div className="my-team-container">
        <div className="my-team-text">My Team</div>
        <Button
            className="send-invite"
            style={{ width: '20%', height: '10%'  }}
            variant="contained"
            onClick={()=>setState({
              ...state,
              isDialogOpen: true,
              isValueChange:!state.isValueChange,
              isDelete: false
            })}
        >
          Create new team
        </Button>
      </div>
      <Tags_table columns={state.allTeams} selectedTeam={selectedTeam} onDeleteTeam={onDeleteTeam}/>
      <Dialog
          open={state.isDialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {!!state.isDelete ? "Are you sure to delete the team" : "Enter team name"}
        </DialogTitle>
        <div style={{padding:20}}>
          {
            !state.isDelete && <TextField
                  label="Team name"
                  placeholder="Team name"
                  fullWidth
                  variant="outlined"
                  size="small"
                  style={{width:400}}
                  onChange={(e)=>setState({...state, teamName: e.target.value})}
              />
          }
        </div>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={()=> !state.isDelete ?
            onSave(state.teamName) : DeleteTeamFunc()
          } autoFocus>
            {!state.isDelete ? "Save" : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    }
  </div>
};

export default Teams;
