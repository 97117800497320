import React, { createContext, useContext, useState } from 'react';
import {useNodesState, useEdgesState} from 'reactflow';

import {useSelector} from "react-redux";


const NodesContext = createContext();
export const NodesProvider = ({children})=> {
  let flowData = useSelector(state => state?.chatFlow?.flowData)
  let flowDataNodes = flowData?.flowData ? JSON.parse(flowData?.flowData) : [];
  const [nodes, setNodes, onNodesChange] = useNodesState(flowDataNodes.nodes || []);
  const [edges, setEdges, onEdgesChange] = useEdgesState(flowDataNodes.edges || []);
  const [showEdit, setShowEdit] = useState({});
  const [selectIndex, setSelectedIndex]= useState('')
  const [triggerName, setTriggerName]= useState('')
  const [keyword, setKeyword] = useState([]);

  return (
    <NodesContext.Provider value={{ nodes, setNodes, onNodesChange, showEdit, setShowEdit, edges, setEdges, onEdgesChange, selectIndex, setSelectedIndex, triggerName, setTriggerName, keyword, setKeyword }}>
      {children}
    </NodesContext.Provider>
  );
};

export const useNodes = () => useContext(NodesContext);
