import React, {useEffect} from 'react';
import Inbox from "../Inbox";
import {useSelector} from "react-redux";
import {assignAgent, assignAgentMessenger, assignStatus, getWhatsappChat} from "../Apis";
import ActionsCreators from "../states";
import {useDispatch} from "react-redux";
import WhatsappImage from "../../../assets/svg/social-logos/whatsappLogo.png"
import config from "../../../config";

const Whatsapp = () => {
    const dispatch = useDispatch()
    let url = config.api.baseUrl + "/message/send"
    let conversations = useSelector(state => state.inbox.allWhatsappConversations)
    let assignStatusFunc=assignStatus
    let assignAgentFunc=assignAgent
    useEffect(() => {
        {dispatch(ActionsCreators.getAllWhatsappConversations())}
    }, []);
    return <Inbox
        url = {url}
        conversations={conversations}
        getChat={getWhatsappChat}
        channel={"whatsapp"}
        assignStatusFunc={assignStatusFunc}
        assignAgentFunc={assignAgentFunc}
        channelLogo={WhatsappImage}
    />
}
export default Whatsapp