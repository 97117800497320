import React, {useEffect, useState} from "react";
import {GetAllContacts} from "../../Api/Api";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import Table_contacts from "../Table_contacts";
import {Dropdown, Menu, Drawer, Form, Modal, Input, Select} from "antd";
import {MoreOutlined} from "@ant-design/icons";
import moment from "moment/moment";
import {
    activateGroup,
    addGroup,
    deActivateGroup,
    deleteGroup,
    GetAllGroups,
    getGroupById,
    updateGroup
} from "../../Api/groupApis";

const {Option} = Select;
export default function Groups() {
    const [state, setState] = useState({
        isOpen: false,
        name: "",
        description: '',
        email: "",
        phone: "",
        data: [],
        value: false,
        groupId: "",
        isEmailError: false,
        emailErrorMsg: "",
        isContactInfo: false,
        contactData: "",
        isValueChange: false,
        filterModal: false,
        columnsData: [],
        openDrawer: false,
        contactInfo: null,
        mode: '',
        isDialogueOpen: false,
        contactArray: [],
        selectedContacts: null
    });
    const GetAllGroupsFunc = () => {
        GetAllGroups().then(res => {
                let data = res.data.data
                makeColumnsData(data)
            }
        ).catch(error => console.log(error))
    }

    const getContacts = () => {
        GetAllContacts().then(res => {
                let data = res.data.data
                setState(prevState => ({...prevState, contactArray: [...data], isDialogueOpen: true}))
            }
        ).catch(error => console.log(error))
    }


    const ActivateGroupFunc = (id) => {
        activateGroup(id)
            .then(() => {
                    GetAllGroupsFunc()
                }
            )
            .catch(err => console.log(err, "err"))
    }

    const deActivateGroupFunc = (id) => {
        deActivateGroup(id)
            .then(() => {
                    GetAllGroupsFunc()
                }
            )
            .catch(err => console.log(err, "err"))
    }

    const getGroupByIdFunc = (id) => {
        getGroupById(id).then(res => {
            setState(prevState => ({
                ...prevState,
                name: res.data.data.name,
                description: res.data.data.description,
                selectedContacts: res.data.data.users,
                isDialogueOpen: true,
                mode: 'edit',
                groupId: id
            }))
        }).catch(error => console.log(error, "error"))
    }
    const deleteGroupFunc = (id) => {
        deleteGroup(id)
            .then(() => {
                    GetAllGroupsFunc()
                }
            )
            .catch(err => console.log(err, "err"))
    }
    const makeColumnsData = (data) => {
        let columnsData = data.length > 0 && Object.keys(data[0])
        let indexToRemove = columnsData.indexOf("_id");
        columnsData.splice(indexToRemove, 1);
        columnsData.push("actions");
        let updatedArray = data.map((obj) => ({
            ...obj,
            addedBy: `${obj.addedBy.firstName + ' ' + obj.addedBy.lastName}`,
            status: !!obj?.status ? 'Active' : 'De-active',
            created: moment(obj.created).format('MMMM Do YYYY, h:mm a'),
            updated: moment(obj?.updated?.timestamp).format('MMMM Do YYYY, h:mm a'),
            users: obj.users.length > 0 && obj.users.map((item, idx) => idx !== 0 ? ` ,` + item : item),
            actions: <Dropdown overlay={(
                <Menu>
                    <Menu.Item onClick={() => {
                        getGroupByIdFunc(obj.id)
                        getContacts()
                    }} key="1">
                        Edit
                    </Menu.Item>
                    <Menu.Item onClick={() => deleteGroupFunc(obj.id)} key="2">
                        Delete
                    </Menu.Item>
                    <Menu.Item onClick={() => ActivateGroupFunc(obj.id)} key="3">
                        Activate
                    </Menu.Item>
                    <Menu.Item onClick={() => deActivateGroupFunc(obj.id)} key="4">
                        De-activate
                    </Menu.Item>
                </Menu>
            )} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                    <MoreOutlined style={{fontSize: '14px'}}/>
                </a>
            </Dropdown>
        }))

        setState({...state, columnsData, data: updatedArray, isDialogueOpen: false})
    }

    const selectedTeam = (data) => {
        let newData = {
            ...data,
            'name': data.name
        }
        setState(prevState => ({...prevState, contactData: newData, isContactInfo: true}))
    }
    const onSubmit = () => {
        let data = {
            name: state.name,
            description: state.description,
            users: state.selectedContacts
        }
        addGroup(data).then(() => {
            GetAllGroupsFunc()
        }).catch((err) => {
            console.log(err)
        })
    }

    const onUpdate = () => {
        let data = {
            name: state.name,
            description: state.description,
            users: state.selectedContacts
        }
        updateGroup(state.groupId, data).then(() => {
            GetAllGroupsFunc()
        }).catch((err) => {
            console.log(err)
        })
    }
    const handleSelectChange = (selectedItems) => {
        setState({...state, selectedContacts: selectedItems});
    };

    useEffect(() => {
        GetAllGroupsFunc()
    }, [state.isContactInfo]);
    const {t} = useTranslation();
    return (
        <div style={{width: "100%"}}>
            <>
                <div className='main-header'>
                    <div className='all-contacts' style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            opacity: ".7",
                            fontSize: "20px",
                            fontWeight: 600,
                            marginLeft: "3%"
                        }}>Groups
                        </div>
                        <div style={{display: "flex", gap: 10}}>
                            <Button variant="contained"
                                    onClick={() => setState({...state, filterModal: true})}>Filter</Button>
                            <Button variant="contained"
                                    onClick={() => {
                                        getContacts()
                                        setState({...state, mode: 'new'})
                                    }}>Add new group</Button>
                        </div>
                    </div>
                </div>

                {
                    state.columnsData.length > 0 && <Table_contacts
                    filterModal={state.filterModal}
                    closeFilterModal={() => setState({
                        ...state,
                        filterModal: false
                    })}
                    selectedTeam={selectedTeam}
                    tableData={state.data}
                    columns={state.columnsData}
                />
                }
            </>
            <Modal
                title={state.mode === 'new' ? `Create group` : 'Update group'}
                open={state.isDialogueOpen}
                onOk={() => state.mode === 'new' ? onSubmit() : onUpdate()}
                okText={'Submit'}
                onCancel={() => setState(prevState => ({
                    ...prevState,
                    isDialogueOpen: false,
                    name: '',
                    description: '',
                    selectedContacts: ''
                }))}
            >
                <Input value={state.name} style={{marginTop: 10}}
                       onChange={(e) => setState({...state, name: e.target.value})} placeholder="Enter name"/>
                <Input.TextArea value={state.description}
                                onChange={(e) => setState({...state, description: e.target.value})}
                                style={{marginTop: 10, marginBottom: 20}} showCount maxLength={100}
                                placeholder="Enter description"/>
                <Select
                    style={{width: '100%'}}
                    mode="multiple"
                    placeholder="Please select"
                    onChange={handleSelectChange}
                    value={state.selectedContacts}
                >
                    {
                        state?.contactArray.length > 0 && state.contactArray.map(obj => <Option
                            value={obj.id}>{obj.name}</Option>)
                    }
                </Select>
            </Modal>
        </div>
    )
}
