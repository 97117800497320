const initialState = {
    flowData:null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_FLOW_DATA":
            return {
                ...state,
                flowData: action.payload
            };
        default:
            return state;
    }
};

export default reducer