import React from 'react';

const Border_icons = (props) => {
  return (
    <div className='mainborder-icons-container'>
      <div className='icons-container'>
        <div className='prop-icon'>{props.icon1}</div>
        <div className='props-icon2'>{props.icon2}</div>
      </div>
      <div className='container-Border'>
        <div className='Heading'>{props.heading}</div>
        <p className='Heading-dis'>{props.Descriptions}</p>
      </div>
    </div>
  );
}

export default Border_icons;
