import React, { useState ,useEffect } from 'react';
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader';
import { AiOutlineApi } from "react-icons/ai";
import { Input, Select, Button } from 'antd';
import { MdDelete } from "react-icons/md";
import { apicreate , api , apiupdate} from "./Api/Api";
import "./Api.scss";
import {useParams} from 'react-router-dom'
import { Radio } from 'antd';
import {useNavigate} from "react-router-dom";
import validator from "validator";

const { TextArea } = Input;
const { Option } = Select;

  const Apiform = () => {
    const {id} = useParams();
  const [state, setState] = useState({
    name: "",
    description: "",
    type: "get",
    dataType: "param",
    URL: "",
    header: [{ key: "", value: "" }],
    data: [{ key: "", value: "" }],
    responseKey: "",
    error:"",
    loading:false
  });
  const navigate = useNavigate()

  const AllDAta = () =>{
    if(id){   
      api(id).then(res =>{
           setState({
             name: res.data.data.name,
             description: res.data.data.description,
             type: res.data.data.type,
             URL:res.data.data.url,
             header: res.data.data.header,
             data: res.data.data.data,
             dataType: res.data.data.dataType,
             responseKey:res.data.data?.responseKey
           })
      })
     }
  }

  useEffect(()=>{
    AllDAta()
    },[])

  const handleSubmit = () => {
    const formData = {
      name: state.name,
      description: state.description,
      type: state.type,
      url: state.URL,
      dataType: state.dataType,
      header: state.header,
      data: state.data,
      responseKey:state.responseKey
    };

    apicreate(formData)
      .then(() => {
        navigate("/settings/api-management")
      })
      .catch(error => {
        console.error('Error creating API:', error);
      });
  };

  const handleDataTypeChange = (e) => {
    const { value } = e.target;
    setState({
      ...state,
      dataType: value,
      data: state.data.length > 0 ? [state.data[0]] : [{ key: "", value: "" }]
    });
  };

  const handleHeaderInputChange = (index, field, value) => {
    const newHeaders = [...state.header];
    newHeaders[index][field] = value;
    setState({
      ...state,
      header: newHeaders
    });
  };

    const headerValidation = () => {
      return  state.header.find(item=>(
           item.key==='' || item.value===''
      ))}

    const bodyValidation = () => {
      return state.data.find(item=>(
        item.key==='' || item.value===''
      ))
    }

    const handleBodyInput = (index, field, value) => {
      const newBody = [...state.data];
      newBody[index][field] = value;
      setState({
        ...state,
        data: newBody
      });
    };

  const addHeader = () => {
    setState({
      ...state,
      header: [...state.header, { key: "", value: "" }]
    });
  };

    const addBody = () => {
      setState({
        ...state,
        data: [...state.data, { key: "", value: "" }]
      });
    };

  const removeHeader = (index) => {
    const newHeaders = [...state.header];
    newHeaders.splice(index, 1);
    setState({
      ...state,
      header: newHeaders
    });
  };

    const removeBody = (index) => {
      const newBody = [...state.data];
      newBody.splice(index, 1);
      setState({
        ...state,
        data: newBody
      });
    };

  const handleUpdate = () => {
    const updateData = {
      name: state.name,
      description: state.description,
      type: state.type,
      url: state.URL,
      dataType: state.dataType,
      header: state.header,
      data: state.data,
      responseKey:state.responseKey

    };

    apiupdate(id,updateData)
      .then(() => {
        navigate("/settings/api-management")
      })
      .catch(error => {
        console.error('Error creating API:', error);
      });
  };

  return (
    <div className='synctic-module-container'>
      <SettingsHeader
        heading="Create Custom Api"
        icon={<AiOutlineApi className='icon' />}
      />
      <div className='cards-box' style={{ marginTop: "1%" }}>
        <div className='main-name-api'>
          <div className='api-label-name'>API Name</div>
          <Input placeholder="Give your API a suitable name"
           className='api-name-input' value={state.name}
                 onChange={e => {
                   const inputValue = e.target.value;
                   const onlyTextRegex = /^[A-Za-z\s]+$/; 
                   if (onlyTextRegex.test(inputValue) || inputValue === '') {
                     setState({...state, name: inputValue});
                   }
                 }}
            />
        </div>
        <div className='main-name-api'>
          <div className='api-label-name'>API Description</div>
          <TextArea rows={4} placeholder="Provide a brief description"
           className='api-name-input' value={state.description}
            onChange={e => setState({...state, description:e.target.value})}/>
        </div>
        <div className='main-name-api'>
          <div className='api-label-name'>API Type</div>
          <Select
            style={{ marginTop: "1%" }}
            value={state.type}
            onChange={e => setState({...state, type: e, dataType: e==='post'? 'payload' : 'param'})}
          >
            <Option value="get">Get</Option>
            <Option value="post">Post</Option>
          </Select>
        </div>
        <div className='main-name-api'>
          <div className='api-label-name'>API Endpoint URL</div>
          <Input placeholder="Provide the endpoint URL" 
          className='api-name-input'
                 value={state.URL}
                 onChange={e => {
                  const inputValue = e.target.value;
                  const urlValidator = validator.isURL(inputValue, {require_protocol: true});
                  setState({...state, URL: inputValue, error: urlValidator ? '' : 'Invalid URL' });
              }}
                 status={(state.URL && state.error) ? "error" : ""}
          />
          {state.URL && state.error && <p style={{fontSize: 12, color: "red"}}>{state.URL && state.error}</p>}

        </div>
        <div className='main-name-api'>
          <div className='api-label-name'>Response Key</div>
          <Input placeholder="Enter response key" 
          className='api-name-input'
            value={state.responseKey}
            onChange={e => {
              const inputValue = e.target.value;
              const onlyTextRegex = /^[A-Za-z\s]+$/; 
              if (onlyTextRegex.test(inputValue) || inputValue === '') {
                setState({...state, responseKey: inputValue});
              }
            }}          
            />
        </div>
        <div style={{display:"flex",marginTop:"2%",gap:"5px"}}>
          <Radio style={{fontSize:"13px",fontWeight:"600", display:(state.type === "post") ? "none" : null}} checked={state.dataType === "param"} onChange={handleDataTypeChange} value="param">Params</Radio>
          <Radio style={{fontSize:"13px",fontWeight:"600", display:(state.type === "get") ? "none" : null}} checked={state.dataType === "payload"} onChange={handleDataTypeChange} value="payload">Payload</Radio>
          <Radio style={{fontSize:"13px",fontWeight:"600", display:(state.type === "post") ? "none" : null}} checked={state.dataType === "query"} onChange={handleDataTypeChange} value="query">Query</Radio>
          </div>
        <div className='main-name-api' style={{display:(state.type === "post") ? "none" : null}}>
          <div className='api-label-name'>API Headers</div>
              {state.header.map((header, index) => (
                <div key={index} style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                  <Input placeholder="Key" className='api-name-input' style={{ width: "40%" }} value={header.key} onChange={e => handleHeaderInputChange(index, "key", e.target.value)} />
                  <Input placeholder="Value" className='api-name-input' style={{ width: "67%" }} value={header.value} onChange={e => handleHeaderInputChange(index, "value", e.target.value)} />
                  <MdDelete style={{ fontSize: "30px", cursor: "pointer", marginTop: "1%", display: state.header.length ===1 ? 'none' : null }} onClick={() => removeHeader(index)} />
                </div>
              ))}
              <Button disabled={!!headerValidation()} type="primary" style={{ marginTop: "3%" ,width:"10%"}} onClick={addHeader}>Add</Button>
        </div>

        <div className='main-name-api'>
          <div className='api-label-name'>Body</div>
          {state.data.length > 0 && state.data.map((body, index) => (
              <div key={index} style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                <Input placeholder="Key" className='api-name-input' style={{ width: "40%" }} value={body.key} onChange={e => handleBodyInput(index, "key", e.target.value)} />
                <Input placeholder="Value" className='api-name-input' style={{ width: "67%" }} value={body.value} onChange={e => handleBodyInput(index, "value", e.target.value)} />
                <MdDelete style={{ fontSize: "30px", cursor: "pointer", marginTop: "1%" }} onClick={() => removeBody(index)} />
              </div>
          ))}
          <Button
              type="primary"
              style={{ marginTop: "3%" ,width:"10%"}}
              onClick={addBody}
              disabled={(state.type==='get' && state.dataType==='param' && state.data.length === 1) || bodyValidation() }
          >Add</Button>
        </div>


        <div style={{ display: "flex", gap: "10px", marginTop: "2%" }}>
          <Button
          disabled={
            (state.type === "post" && (
                !!bodyValidation() ||
                !state.name ||
                !state.description ||
                !!state.error ||
                !state.URL ||
                !state.responseKey
            )) ||
            (state.type === "get" && (
                 /* !!headerValidation() ||  */
                !!bodyValidation() ||
                !state.name ||
                !state.description ||
                !!state.error ||
                !state.URL ||
                !state.responseKey
            ))
        }
              type="primary" style={{ margin: "2% auto", width: "50%" }} onClick={id ? handleUpdate : handleSubmit}> {id ? "Update" : "Create"}</Button>
        </div>
      </div>
    </div>
  );
};

export default Apiform;
