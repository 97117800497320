import React, {useState} from "react";
import "./Signup.css";
import googleIcons from "../../../assets/media/svg/brand-logos/google-icon.svg";
import facebookIcon from "../../../assets/svg/social-logos/facebook.svg";
import {Link, useNavigate} from "react-router-dom";
import {SignupApi} from "./Api/SignupApi";
import SnackBar from "../../ResuseableComponnets/SnackBar";
import { useTranslation } from 'react-i18next';
import LeftSection from "../LeftSection/LeftSection";
import Drop from "../Drop/Drop";
import {Button, Input} from "antd";
import validator from "validator";
import {facebookSignup, googleSignup} from "../../API/AccountApis";

const Signup = () => {
    const [state, setState] = useState({
        email: "",
        password: "",
        passwordConfirmation: "",
        isValid: false,
        isTerms: false,
        severity: "",
        message: "",
        notificationValue: false,
        passwordError: false,
        isError: false,
        emailError:"",
        emailErrMsg:"",
        passErrMsg:"",
        confirmPasswordError:false,
        confirmPassErrMsg:""
    })

    
    console.log(state, "2222222")
    const { t } = useTranslation();
    const navigate=useNavigate();

    const emailInput = (e) => {
        let emailValidator = validator.isEmail(e.target.value)
        const email = e.target.value;
        setState({
            ...state,
            email: email,
            emailError: !emailValidator,
            isError: false,
            emailErrMsg: (state.emailError && state.email && /[!/#$%^&*()_+}{|]/.test(state.email)) ? "Please enter a valid email address" : "Invalid Email" 
        });
    };

    const SignUpFunc = () => {
        (!state.email && !state.password && !state.passwordConfirmation) ?
            setState({
                ...state,
                message: "All fields required",
                passwordError: true,
                isError: true
            }) :
            !state.isValid ?
                setState({
                ...state,
                message: "Email ID is not valid",
                isError: true
            }) :
                (state.password !== state.passwordConfirmation) ?
                    setState({
                ...state,
                message: "Passwords are not same.",
                        passwordError: true
            }) :
                    SignupApi(state)
                .then((res) => {
                    if (res?.data?.success) {
                        setState((prevState) => ({
                            ...prevState,
                            severity: "success",
                            message: "Signup Successfully",
                            notificationValue: !state.notificationValue,
                        }));
                        navigate("/verify-email");
                    } else {
                        let message = res.response.data.message
                        setState((prevState) => ({
                            ...prevState,
                            message: !!message && message==="password must contain at least 6 alphanum" ?
                                "Password must contain at least 6 alphanum" : "Email already exist",
                            isError: true
                        }));
                    }
                })
                .catch((error) => {
                    console.error("API call failed:", error);
                    setState((prevState) => ({
                        ...prevState,
                        severity: "error",
                        message: "Email already exist", // You can customize the error message
                        notificationValue: !state.notificationValue,
                    }));
                })
    }

    const SignUpBtnFunc = ()=>{
        googleSignup().then(res=>console.log(res, 'resssss')).catch(err=>console.log(err, "errrr"))
    }

    return <div className="d-flex flex-column flex-root" style={{height:"100vh"}}>
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
            <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 pb-0 pt-2 order-2 order-lg-1">
                <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                    <div>
                            <div className="text-center mb-11">
                                <h1 className="text-dark fw-bolder mb-3">{t('Sign-Up')}</h1>
                                <div className="not-a-member">{t('Your-Social-Campaigns')}</div>
                            </div>
                            <div className="row g-3 mb-9">
                                <div className="col-md-6" style={{width:"fit-content"}}>
                                    <a href={'http://backendapiv2.synctik.com/account/social/googleSignUp'} target={'_blank'} className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                                        <img alt="Logo" src={googleIcons} className="h-15px me-3" />{t('Gooogle-sign')}</a>
                                </div>
                                <div className="col-md-6" style={{width:"fit-content"}}>
                                    <a href={'http://backendapiv2.synctik.com/account/social/facebookSignup'} target={'_blank'} className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                                        <img alt="Logo" src={facebookIcon} className="theme-dark-show h-15px me-3" />{t('Sign-facbook')}</a>
                                </div>
                            </div>
                            <div className="separator separator-content my-14">
                                <span className="w-125px text-gray-500 fw-semibold fs-7">{t('Or-with-email')}</span>
                            </div>
                        <div className="fv-row mb-8" style={{height:"40px"}}>
                            <Input
                                size="large"
                                placeholder={t('placeholder-Email')}
                                value={state.email}
                                onChange={(e) => emailInput(e)}
                                autoComplete="off"
                                status={(state.emailError && state.email) && "error"}
                            />
                            {(state.emailError && state.email) && <p style={{ fontSize: 12, color: "red"}}>{state.emailErrMsg}</p>}
                        </div>
                            <div className="fv-row mb-8" data-kt-password-meter="true">
                                <div className="mb-1">
                                    <div className="position-relative mb-3">
                                        <div className="fv-row mb-3" style={{height:"40px"}}>
                                            <Input.Password
                                                size="large"
                                                placeholder={t('password')}
                                                value={state.password}
                                                onChange={(e) => setState({
                                                    ...state,
                                                    password: `${e.target.value}`,
                                                    passwordError: e.target.value.includes(" ") || e.target.value.length < 6,
                                                    passErrMsg:
                                                        e.target.value.includes(" ") ?
                                                            'Password cannot contain spaces' :
                                                            e.target.value.length < 6 ?
                                                                'Password cannot be smaller than six characters ': ""
                                                })}
                                                autoComplete="off"
                                                status={(state.passwordError && state.password) && "error"}
                                            />
                                            {(state.passwordError && state.password) && <p style={{fontSize: 12, color: "red"}}>{state.passErrMsg}</p>}
                                        </div>
                                     <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
												<i className="ki-duotone ki-eye-slash fs-2"></i>
												<i className="ki-duotone ki-eye fs-2 d-none"></i>
											</span>
                                    </div>
                                </div>
                            </div>

                        <div className="fv-row mb-3" style={{height:"40px"}}>
                            <Input.Password
                                size="large"
                                placeholder={t('Confirm-password')}
                                value={state.passwordConfirmation}
                                onChange={(e) => setState({
                                    ...state,
                                    passwordConfirmation: `${e.target.value}`,
                                    confirmPasswordError: e.target.value.includes(" ") || state.passwordConfirmation.length < 6 || state.password !== e.target.value,
                                    confirmPassErrMsg:
                                        e.target.value.includes(" ") ?
                                            'Password cannot contain spaces' :
                                            e.target.value.length < 6 ?
                                                'Password cannot be smaller than six characters ': "Password should be same."
                                })}
                                autoComplete="off"
                                status={(state.confirmPasswordError && state.passwordConfirmation) && "error"}
                            />
                            {(state.confirmPasswordError && state.passwordConfirmation) && <p style={{fontSize: 12, color: "red"}}>{state.confirmPassErrMsg}</p>}
                        </div>
                            <div className="d-grid mt-10">
                                <Button
                                    disabled={state.emailError || state.passwordError || state.confirmPasswordError || !state.email || !state.passwordConfirmation}
                                    type="primary"
                                    className="submit-btn"
                                    onClick={()=>SignUpFunc()}
                                    style={{backgroundColor: (state.emailError || state.passwordError || state.confirmPasswordError || !state.password || !state.passwordConfirmation) ? "lightgray" : "#04B440"}}
                                >{t('signup')}
                                </Button>
                            </div>
                            <div className="not-a-member" style={{marginTop:20}}>{t("Already-have-an-Account?")}
                                <Link to={"/"}>
                                <a style={{color:"rgb(4, 180, 64)"}}>{" "}{t('signin')}</a>
                                </Link>
                                </div>
                    </div>
                </div>
                <Drop/>
            </div>

            <LeftSection/>

        </div>
        <SnackBar severity={state.severity} message={state.message} notificationValue={state.notificationValue}/>
    </div>
}

export default Signup