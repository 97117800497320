import React, {useEffect} from 'react';
import Inbox from "../Inbox";
import {useDispatch, useSelector} from "react-redux";
import {assignAgentInstagram, assignInstagramStatus, getInstagramChat} from "../Apis";
import ActionsCreators from "../states";
import InstagramImage from "../../../assets/svg/social-logos/instagram.svg"
import config from "../../../config";
const Instagram = () => {
    const dispatch = useDispatch()
    let url = config.api.baseUrl + "/instMessage/send"
    let conversations= useSelector(state => state.inbox.allInstagramConversations)
    let assignStatusFunc=assignInstagramStatus
    let assignAgentFunc = assignAgentInstagram
    useEffect(() => {
        dispatch(ActionsCreators.getAllInstagramConversations())
    }, []);
    return <Inbox
        url = {url}
        conversations={conversations}
        getChat={getInstagramChat}
        channel={"instagram"}
        assignStatusFunc={assignStatusFunc}
        assignAgentFunc={assignAgentFunc}
        channelLogo={InstagramImage}
    />
}
export default Instagram