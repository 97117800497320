import React, {useState} from "react";
import "./ForgotPassword.css";
import unitedStates from "../../../assets/media/flags/united-states.svg";
import Logo from "../../../assets/media/logos/custom-1.png";
import LoginPic from "../../../assets/media/misc/auth-screens.png";
import {useNavigate} from "react-router-dom";
import {ForgotPasswordAPI} from "./API/API";
import SnackBar from "../../ResuseableComponnets/SnackBar";
import { useTranslation } from 'react-i18next';
import LeftSection from "../LeftSection/LeftSection";
import Drop from "../Drop/Drop";
import {Button, Input} from "antd";
import validator from "validator";

const ForgotPassword = () => {
    const [state, setState] = useState({
        email:'',
        severity: "",
        message: "",
        notificationValue: false,
        isValid:false,
        errorMessage:""
    })
    const navigate = useNavigate();
    const { t } = useTranslation();
    const emailInput = (e) => {
        let emailValidator = validator.isEmail(e.target.value)
        const email = e.target.value;
        setState({
            ...state,
            email: email,
            isValid: !emailValidator,
            isError: false
        });
    };
    const languageOptions = [
        { id: 1, flag: 'unitedStates', name: 'English' },
        { id: 2, flag: 'SaudiArabia', name: 'Arabic' },
        { id: 3, flag: 'France', name: 'French' },
        { id: 4, flag: 'Finland', name: 'Finland' },


      ];
      const flagImages = {
        unitedStates: require('../../../assets/media/flags/united-states.svg').default,
        SaudiArabia: require('../../../assets/media/flags/saudi-arabia.svg').default,
        France: require('../../../assets/media/flags/france.svg').default,
        Finland: require('../../../assets/media/flags/finland.svg').default
      };

    const validateEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    };
    const ForgotPasswordFunc = () => {
        (!state.isValid || state.email==="") ? setState({...state,
                isError: true,
                errorMessage:state.email==="" ? "Email cannot be empty" : "Invalid Email"
            }) : ForgotPasswordAPI(state).then((res) => {
            console.log(res, "7771111")
            if (res?.data?.success) {
                navigate("/verify-email");
            } else {
                setState((prevState) => ({
                    ...prevState,
                    severity: "error",
                    message: "Invalid Token", // You can customize the error message
                    notificationValue: !state.notificationValue,
                }));
            }
        })
            .catch((error) => {
                setState((prevState) => ({
                    ...prevState,
                    severity: "error",
                    message: "Error", // You can customize the error message
                    notificationValue: !state.notificationValue,
                }));
            })
    }


return <div className="d-flex flex-column flex-root" style={{height:"100vh"}}>
    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
        <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 pt-0 pb-0 order-2 order-lg-1">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                <div className="w-lg-500px p-10">
                    <form className="form w-100" noValidate="novalidate" id="kt_password_reset_form">
                        <div className="text-center mb-10">
                            <h1 className="text-dark fw-bolder mb-3">{t('Forgot-Password')}</h1>
                            <div className="not-a-member">{t('Enter-your-email-to reset-your-password')}</div>
                        </div>
                        <div className="fv-row mb-8" style={{height:"40px"}}>
                            <Input
                                size="large"
                                placeholder={t("placeholder-Email")}
                                value={state.email}
                                onChange={(e) => emailInput(e)}
                                autoComplete="off"
                                status={(state.isValid && state.email) && "error"}
                            />
                            {(state.isValid && state.email) && <p style={{ fontSize: 12, color: "red"}}>Invalid Email</p>}
                        </div>
                        <div className="d-flex flex-wrap justify-content-center pb-lg-0" style={{gap:10}}>
                                <Button
                                    disabled={state.isValid || state.email === "" || !state.email}
                                    type="primary"
                                    className="submit-btn"
                                    onClick={()=>ForgotPasswordFunc()}
                                    style={{backgroundColor: (state.isValid || !state.email || !state.email) ? "lightgray" : "#04B440"}}
                                >{t("signup")}</Button>
                            <a onClick={()=>navigate('/')} className="btn btn-light">{t('Cancel-btn')}</a>
                        </div>
                    </form>
                </div>
            </div>
            <Drop/>
        </div>

        <LeftSection/>
    </div>
    <SnackBar severity={state.severity} message={state.message} notificationValue={state.notificationValue}/>
</div>
}

export default ForgotPassword