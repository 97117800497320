import React, {useEffect, useState} from 'react';
import "./Templates.scss";
import { HiOutlineDocumentText } from "react-icons/hi";
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { AiOutlinePlus } from "react-icons/ai";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InfoNote from "../../ResuseableComponnets/Note";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {
    createTemplates,
    getAllTemplates,
    getApprovedTemplates,
    getPendingTemplates,
    getRejectedTemplates,
    updateTemplates
} from "./API";
import Table_Templates from "./TemplatesTable";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {AddContact, GetAllContacts, updateContactApi} from "../../Contacts/Api/Api";
import { useMediaQuery } from 'react-responsive'

const MySelect = () => {
  const { t } = useTranslation();
  const handleChange = (event) => {
    console.log(`selected ${event.target.value}`);
  };
  const Ismobile = useMediaQuery({maxWidth: 767})

  return (
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
    <Select
      defaultValue="lucy"
      style={{ width: Ismobile? 110 : 220, height:"34px !important" }}
      onChange={handleChange}
      labelId="demo-select-small-label"
      id="demo-select-small"
    >
      <MenuItem value="jack">{t("Jack")}</MenuItem>
      <MenuItem value="lucy">{t("Lucy")}</MenuItem>
      <MenuItem value="Yiminghe">{t("usman")}</MenuItem>
      <MenuItem value="disabled" disabled>{t("disabled")}</MenuItem>
    </Select>
      </FormControl>
  );
}

const Templates = () => {
    const [state, setState] = useState({
        defaultActiveTab:"1",
        activeTab:"",
        data:[],
        mode:"",
        name:"",
        body:"",
        isOpen:false,
        template_id:""
    })
  const { t } = useTranslation();
  const Ismobile = useMediaQuery({maxWidth: 767})


  const handleChange = (e, newValue) => {
      newValue==="1" ?
          getAllTemplates()
              .then(res=>setState({...state, data: res.data.data, activeTab: !!state.activeTab ? newValue : state.defaultActiveTab}))
              .catch(err=>console.log('Error: ', err)) :
          newValue==="2" ? getApprovedTemplates()
              .then(res=>setState({...state, data: res.data.data, activeTab: !!state.activeTab ? newValue : state.defaultActiveTab}))
              .catch(err=>console.log('Error: ', err)) :
              newValue==="3" ? getRejectedTemplates()
                  .then(res=>setState({...state, data: res.data.data, activeTab: !!state.activeTab ? newValue : state.defaultActiveTab}))
                  .catch(err=>console.log('Error: ', err)) :
                  getPendingTemplates()
                      .then(res=>setState({...state, data: res.data.data, activeTab: !!state.activeTab ? newValue : state.defaultActiveTab}))
                      .catch(err=>console.log('Error: ', err))
  }

    const onSubmit = () => {
        let data = {
            title:state.name,
            body:state.body
        }
        createTemplates(data).then(res=> {
            getTemplates()
        }).catch(error=> console.log(error, "Error..."))
    }

    const updateTemplate = (data) => {
        setState({
            ...state,
            isOpen: true,
            name: data.title,
            body: data.body,
            template_id:data.id,
            mode:"update"
        })
    }
    const onUpdate = () => {
        let data = {
            title: state.name,
            body: state.body
        }
        updateTemplates(state.template_id, data).then(res => {
            getTemplates()
        }).catch(err => console.log('Error: ', err))
    }
  const getTemplates = () => {
      getAllTemplates()
          .then(res=>setState({...state, data: res.data.data, activeTab: !!state.activeTab ? state.activeTab : state.defaultActiveTab, isOpen: false, name: "", body: "", mode: ""}))
          .catch(err=>console.log('Error: ', err))
  }

    useEffect(() => {
        getTemplates()
    }, []);

  return (
    <div className={"synctic-module-container"}>
      <div className='Main-fluid '>
          <div className="cards-box" style={{
              display:"flex",
              width:"100%",
              justifyContent:"space-between",
          alignItems:"center"}}>
              <div>
        <div className='synctic-module-def'>
          <HiOutlineDocumentText className="icon" />
          <div className="heading">{t("Templates")}</div>
          <p className="sub-heading">{t("Manage-All-Of-Your-Templates")}</p>
        </div>
          </div>
              <div className='main-Channels'>
                  <div>{t("Channels")}</div>
                  <MySelect />
              </div>
          </div>

      </div>

        <InfoNote heading={t("How-it-works")} description={t("description-Templates")} btn={t("Read-more-btn")}/>
        <div className='main-menu'>
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={state.activeTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display:"flex", alignItems:"center", justifyContent:"space-between" }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label={t("All")} value="1" />
                        <Tab  label={t("Accepting")} value="2" />
                        <Tab  label={t("Rejected")} value="3" />
                        <Tab  label={t("Pending")} value="4" />
                    </TabList>
                    <Button onClick={()=>setState({...state, mode: "new", isOpen: true })} style={{ height: "10%", flex: "0 0 auto" }} variant="contained" className='btn-contained'>
                        <AiOutlinePlus style={{ marginRight: "13px" }} /> {t("Add-New-Templates")}
                    </Button>
                </Box>
                <Table_Templates updateTemplate={updateTemplate} data={state.data} />
            </TabContext>
        </Box>
      </div>

        <InfoNote heading={t("We-synchronize-every-5-minutes")} description={t("Need-Faster-Sync?-Hit-the-sync-Button")} btn={t("Sync")}/>

        <Dialog
            onClose={()=>setState({
                ...state,
                isOpen: false,
                name: "",
                body:"",
                mode: ""
            })}
            open={state.isOpen}
        >
            <DialogTitle>
                {state.mode==="update" ? "Update template" : "New template"}</DialogTitle>
            <div style={{display:"flex", flexDirection:"column", gap:10, padding:30, paddingTop:10}}>
                <TextField
                    label="Template Name"
                    placeholder="Enter template name"
                    fullWidth
                    variant="outlined"
                    size="small"
                    style={{width:400}}
                    value={state.name}
                    onChange={(e)=>setState({...state, name: e.target.value})}
                />
                <TextField
                    id="outlined-multiline-static"
                    label="Body"
                    placeholder="Enter body"
                    multiline
                    rows={4}
                    style={{width:400}}
                    value={state.body}
                    onChange={(e)=>setState({...state, body: e.target.value})}
                />

                <Button
                    disabled={
                        !state.name ||
                        !state.body }
                    onClick={state.mode==="update" ? onUpdate : onSubmit} variant="contained">
                    {state.mode==="update" ? "Update" : t("Submit-btn")}

                </Button>
            </div>
        </Dialog>

      <Alert style={{ marginTop: "4%" }} severity="success">{t("This-is-success-alert—check-it-out!")}</Alert>
    </div>
  )
}

export default Templates;
