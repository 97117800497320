import React, {useEffect, useState} from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import {MuiTelInput} from "mui-tel-input";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import {useNavigate} from "react-router-dom";
import {Dropdown, Input, Menu, Tag} from "antd";
import WhatsappImage from "../../assets/svg/social-logos/whatsappLogo.png";
import MessengerLogo from "../../assets/svg/social-logos/messenger.png";
import InstagramImage from "../../assets/svg/social-logos/instagram.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import './Inbox.scss';

const AllConversations = (props) => {
    const navigate=useNavigate()
    const [state, setState] = useState({
        data: [],
        newMessage: false,
        activeValue: "Active Chats",
        isOpen: false,
        phone: "",
        selectedId: "",
        conversationsProfiles: [],
        activeStatus: "",
        channel:'WhatsApp',
        currsorting:'Newest'
    })

    const [searchQuery, setSearchQuery] = useState('');
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    let filteredMenuItems = searchQuery.length > 0 ? props?.conversations.filter((item) =>
        item.chatInitiator?.name.toLowerCase().includes(searchQuery.toLowerCase())
    ): props?.conversations

    filteredMenuItems= state.currsorting === 'Newest' ?
        filteredMenuItems.sort((a, b) => new Date(b?.lastCustomerMessage) - new Date(a?.lastCustomerMessage)) :
        filteredMenuItems.sort((a, b) => new Date(a?.lastCustomerMessage) - new Date(b?.lastCustomerMessage))

    const {showChat} = props

    let allConversations = []
    const getConversation = (id, _id, contactInfo) => {
        setState({...state, selectedId: id})
        props.getAllChat(id, _id, contactInfo)
    }

    useEffect(() => {
        allConversations = props?.conversations
        setState({...state, conversationsProfiles: allConversations, activeValue: 'All', channel: props.channel})
    }, [props?.conversations.length]);
    return <div className="allconversation-main">
        <Input
            size="large"
            style={{width: '100%'}}
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder={'Search here'}
        />
        <div style={{
            // display: "flex",
            // justifyContent: "space-between",
            // alignItems: "center",
            marginTop: 16,
            width:'100%'
        }}>
            <div style={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center', overflow:'hidden'}}>
                <Dropdown
                    overlay={(
                    <Menu>
                        <Menu.Item key="2" disabled={true}>
                            <div onClick={() => {
                                setState({
                                    ...state,
                                    channel: 'Messenger'
                                })
                                navigate(`/inbox/messenger/${props.filterName}`)
                            }
                            }>
                                <img style={{width:15}} src={MessengerLogo}/>  Messenger
                            </div>
                        </Menu.Item>
                        <Menu.Item disabled={true} key="3">
                            <div onClick={() => {
                                setState({
                                    ...state,
                                    channel: 'Instagram'
                                })
                                navigate(`/inbox/instagram/${props.filterName}`)
                            }}>
                                <img src={InstagramImage} style={{width:14}}/>  Instagram
                            </div>
                        </Menu.Item>
                    </Menu>
                )}
                    trigger={['click']}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <div style={{
                            display:'flex',
                            alignItems:'center',
                            gap:10
                        }}>
                        <div style={{
                            display:"flex",
                            alignItems:"center",
                            gap:8,
                            fontSize:16
                        }}>
                            <img style={{width:18}} src={
                            state.channel==="messenger" ?
                                MessengerLogo : state.channel==="instagram" ?
                                    InstagramImage : WhatsappImage
                        } alt=""/>
                            {state.channel==="messenger" ?
                            "Messenger" : state.channel==="instagram" ?
                                "Instagram" : "Whatsapp"}
                            </div>
                            <ArrowDropDownIcon/>
                        </div>
                    </a>
                </Dropdown>
                <div>
                    <Dropdown overlay={(
                        <Menu>
                            <Menu.Item key="1">
                                <div style={{overflow:"hidden"}} onClick={() => {
                                    setState({
                                        ...state,
                                        currsorting: 'Newest'
                                    })}
                                }>
                                    Newest
                                </div>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <div onClick={() => {
                                    setState({
                                        ...state,
                                        currsorting: 'oldest'
                                    })}
                                }>
                                    Oldest
                                </div>
                            </Menu.Item>
                        </Menu>
                    )} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                            <div style={{
                                fontWeight: 550,
                                width: "100%",
                                opacity: .7,
                                textAlign:'right'
                            }}>{ state.currsorting}</div>
                        </a>
                    </Dropdown>
                </div>
            </div>
            <div style={{
                fontWeight: 550,
                width: "100%",
                opacity: .7,
                textAlign:'right',
                overflow:"hidden"
            }}>{'Chats: ' + state.conversationsProfiles.length}</div>
            <Dialog
                onClose={() => setState({
                    ...state,
                    isOpen: false,
                    name: "",
                    contactId: "",
                    email: "",
                    phone: "",
                    isEmailError: false,
                    emailErrorMsg: ""
                })}
                open={state.isOpen}
            >
                <DialogTitle>Add Contact</DialogTitle>
                <div style={{display: "flex", flexDirection: "column", gap: 10, padding: 30, paddingTop: 10}}>
                    <MuiTelInput
                        required
                        className={'textField'}
                        size="small"
                        id="outlined-required"
                        defaultCountry="PK"
                        value={state.phone}
                        style={{width: 300}}
                        onChange={(e) => {
                            setState({...state, phone: e})
                        }}
                    />
                    <div style={{textAlign: "center"}}>Or</div>
                    <TextField
                        id="outlined-size-small"
                        placeholder="Search Contact"
                        size="small"
                        style={{width: 300}}
                    />
                    <Button variant="contained">Next</Button>
                </div>
            </Dialog>
        </div>
        <div className={'all-conversation-conatiner'}>
        <List className='all-conversation' data-aos="fade-up" sx={{
            bgcolor: 'background.paper',
            height:'fit-content'
        }}>
            {
                !!filteredMenuItems && filteredMenuItems.map((item, idx) => <div
                        key={idx}
                        onClick={() => getConversation(item.conversationId, item._id, item)}
                        style={{cursor:"pointer"}}
                    >
                        <div style={{
                            background: state.selectedId === item.conversationId ?
                                "rgba(66, 135, 245, .1)" : ""
                        }}
                             onClick={() => showChat()}
                             className="allConversationMenu">
                            <ListItem alignItems="flex-start">
                                <div style={{position:"relative"}}>
                                <Avatar alt={
                                    props.channel === "messenger" ?
                                        item.chatInitiator.first_name :
                                            item.chatInitiator?.name
                                } src={
                                    props.channel === "messenger" ?
                                        item.chatInitiator.profile_pic : "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEBwzA9VEjgBydUx2ZTVWaWKx2abMJ5daAAXmSqfl9evKnaV4Rer_PkKz_r8_m76Ph0wOd-yjd5&psid=7436819382996141&width=1024&ext=1704237886&hash=AfqcnOSUP1eLiEWW0GZvImCJkOMvK1iMJ2HDICr6RYePzg"
                                }/>
                                </div>
                                <img style={{
                                    height: 12,
                                    width: 12,
                                    marginTop: "10%",
                                    marginLeft: "-11px",
                                    zIndex: 1
                                }} src={ props.channelLogo}
                                     alt="whatappImg"/>
                                <ListItemText
                                    primary={
                                    <div style={{marginLeft:10}}>
                                        <div
                                            style={{
                                                fontSize: 14,
                                                opacity: .9,
                                                fontWeight: 500
                                            }}>{
                                            props.channel === "whatsapp" ?
                                                item.chatInitiator?.name :
                                                props.channel === "instagram" ? item.chatInitiator?.username :
                                                    item.chatInitiator?.first_name + " " + item.chatInitiator?.last_name
                                        }
                                        </div>
                                        <React.Fragment>
                                            <Typography
                                                sx={{display: 'inline',
                                                }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {
                                                    props.channel === "whatsapp" ? <span style={{
                                                        marginLeft:4,
                                                            display:'flex',
                                                            justifyContent:'space-between',
                                                            alignItems:'center'
                                                    }}>
                                                        <div>
                                                            {item.chatInitiator?.phone}
                                                        </div>
                                                        {
                                                            state.selectedId === item.conversationId &&
                                                            <Tag color="green">In progress</Tag>
                                                        }
                                                    </span>
                                                        : <span style={{marginLeft:4}}>
                                                            <span style={{fontWeight:600, }}>ID: </span> {item?.chatInitiator?.sender_id}
                                                        </span>
                                                }
                                            </Typography>
                                        </React.Fragment>
                                    </div>
                                    }
                                />
                            </ListItem>
                        </div>
                    </div>
                )
            }
        </List>
        </div>
    </div>
}

export default AllConversations