import React from 'react'
import "./Get_widgets.scss"
import { MdWidgets } from "react-icons/md";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { BsFillPlusCircleFill } from "react-icons/bs";
import whatsAppIcon from "../../../assets/svg/social-logos/whatsappLogo.png";
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';


const Get_widget = () => {
  const { t } = useTranslation();
    const [state, setState] = React.useState({
        gilad: true,
        jason: false,
        antoine: true,
      });
    
      const handleChange = (event) => {
        setState({
          ...state,
          [event.target.name]: event.target.checked,
        });
      }
  return (
    <div className="synctic-module-container">

<div className='main-customize'>

<div className='customize-text'>{t("Customize-Your-Widgets")}</div>


<TextField id="outlined-basic" placeholder={t("Company-name")} label={t("Title")} variant="outlined" size="small"  style={{marginTop: "4%", width:"60%"}}/>

<TextField id="outlined-basic" placeholder={t("We-cannot-wait-to-help-you")} label={t("Welcome-message")} variant="outlined" size="small"  style={{marginTop: "2%", width:"60%"}}/>

<div className='Minimal-Layout'>{t("Minimal-Layout")}</div>
<p className='Minimal-Layout-p'>{t("This-layout-show-your-channel-icon-only")}</p>

<FormControlLabel style={{marginTop: "-3%"}}
          control={
            <Switch checked={state.jason} onChange={handleChange} name="jason" />
          }
        />

</div>



<div className='main-whatsapp-inputs'>

<img src={whatsAppIcon} style={{height:30, width:30}}/>

<TextField
          label={t("Enter")}
          id="outlined-size-small"
          placeholder={t("label")}
          size="small"
        />
<TextField
          label={t("Whatsapp")}
          id="outlined-size-small"
          placeholder={t("Whatsapp-number")}
          size="small"
        />
<DeleteIcon style={{height:24, width:24, color:"gray"}}/>


</div>


<div className='btn-container' style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
 <Button variant="contained" style={{width: "75%", height:36, marginTop:20}}><BsFillPlusCircleFill style={{fontSize: "12px" , marginRight: "10px"}}/>{t("Add-a-channel")}</Button>
 <Button variant="contained" style={{width: "75%", height:36, marginTop:10}}><MdWidgets style={{fontSize: "12px" , marginRight: "10px"}}/>{t("Get-widget-code")}</Button>
</div>
    </div>
  )
}

export default Get_widget
