import React, {useEffect, useState} from 'react'
import "./Preference.scss"
import {Select} from 'antd';
import {IoIosInformationCircle} from "react-icons/io";
import { Switch } from 'antd';
import {Input, Button} from 'antd';
import {getPreference, savePreference} from "./API/Api";
import Loader from "../../ResuseableComponnets/Loader";
import { useTranslation } from 'react-i18next';


const Preference = () => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        timeZone: false,
        loginSecurity: false,
        autoHideConversation: false,
        autoAssignConversation: false,
        disableCSAT: false,
        manualBotToHumanHandOff: false,
        transcriptConversation: {
            allow: false,
            email: null
        }
    })
    const [loader, setLoader] = useState(false)
    const {TextArea} = Input;
    const handleChange = (value) => {
        setState({...state, timeZone: value})
    };
    const {Option} = Select;
    const timeZoneOptions = [
        {value: 'GMT', label: 'Greenwich Mean Time (GMT)'},
        {value: 'EST', label: 'Eastern Standard Time (EST)'},
        {value: 'CST', label: 'Central Standard Time (CST)'},
        {value: 'GMT', label: 'Greenwich Mean Time (GMT)'}
    ];

    const settingsData = [
        {
            label: t('LoginSecurity'),
            description: t("Enabletwo-factorAuthentication"),
            state:"loginSecurity"
        },
        {
            label: t('Auto-hideconversation'),
            description: t("Enabletwo-factorAuthentication"),
            state:"autoHideConversation"
        },
        {
            label: t('AutoAssignedconversation'),
            description: t("Enabletwo-factorAuthentication"),
            state:"autoAssignConversation"
        },
        {
            label: t('Disabledcsat'),
            description: t("Enabletwo-factorAuthentication"),
            state:"disableCSAT"
        },
        {
            label: t('ManualBottoHumanHandoff'),
            description: t("Enabletwo-factorAuthentication"),
            state:"manualBotToHumanHandOff"
        },
        {
            label: t('EnableconversationTranscript'),
            description: t("Enabletwo-factorAuthentication"),
            state:"transcriptConversation"
        },
    ];
    const renderSetting = (setting, index) => (
        <div key={index} style={{maxWidth:"97%"}}>
            <div style={{display: 'flex'}}>
                <div className='login'>{setting.label}</div>
            </div>
            <p className='setting-description'>{setting.description}</p>
            <div className='FormControlLabel' style={{ float: 'right' ,marginTop:"-4%"}}>
    <Switch
        defaultChecked
        size='small'
        checked={setting.state === "loginSecurity" ?
            state.loginSecurity :
            setting.state === "autoHideConversation" ?
                state.autoHideConversation :
                setting.state === "autoAssignConversation" ?
                    state.autoAssignConversation :
                    setting.state === "disableCSAT" ?
                        state.disableCSAT :
                        setting.state === "manualBotToHumanHandOff" ?
                            state.manualBotToHumanHandOff :
                            setting.state === "transcriptConversation" &&
                            state.transcriptConversation.allow
        }
        onChange={() =>
            setting.state === "loginSecurity" ?
                setState({ ...state, loginSecurity: !state.loginSecurity }) :
                setting.state === "autoHideConversation" ?
                    setState({ ...state, autoHideConversation: !state.autoHideConversation }) :
                    setting.state === "autoAssignConversation" ?
                        setState({ ...state, autoAssignConversation: !state.autoAssignConversation }) :
                        setting.state === "disableCSAT" ?
                            setState({ ...state, disableCSAT: !state.disableCSAT }) :
                            setting.state === "manualBotToHumanHandOff" ?
                                setState({ ...state, manualBotToHumanHandOff: !state.manualBotToHumanHandOff }) :
                                setState({
                                    ...state, transcriptConversation: {
                                        ...state.transcriptConversation,
                                        allow: !state.transcriptConversation.allow,
                                        email: null
                                    }
                                })
        }
        name={`switch-${index}`}
    />
</div>
        </div>
    );
    const getPreferenceFunction = () => {
        setLoader(true)
        getPreference().then(res=> {
            setState(res.data.data)
            setLoader(false)
        } ).catch(err=>setLoader(false))
    }

    const savePreferenceFunc = () => {
        setLoader(true)
        savePreference(state).then(()=>{
            getPreferenceFunction()
        }).catch(()=>setLoader(false))
    }

    useEffect(() => {
        getPreferenceFunction()
    }, []);
    return (
        loader ? <Loader/> :
            <div className='synctic-module-container'>
                <div className='cards-box'>
                <div className='Preference-container'>

                    
                    <div className='Preference'>{t("Preference")}</div>
                    <div className='TimeZone'>{t("TimeZone")}</div>
                    <Select
                        value={state.timeZone}
                        defaultValue="Time Zone"
                        style={{width: "44%", height: 34, marginTop: "1%"}}
                        onChange={handleChange}
                        placeholder="Select a time zone"
                    >
                        {timeZoneOptions.map((zone) => (
                            <Option key={zone.value} value={zone.value}>
                                {zone.label}
                            </Option>
                        ))}
                    </Select>
                    <div>
                    {settingsData.map((setting, index) => renderSetting(setting, index))}
                    {
                        state.transcriptConversation.allow && <div>
                            <p style={{marginTop: "3%", opacity: ".7"}}>{t("Automaticalyallowconversation-dis")}
                            </p>
                            <div style={{display: "flex", gap: "8px"}}>
                                <div className='login'>{t("EnterEmail-address")}</div>
                                <IoIosInformationCircle style={{marginTop: "21px", fontSize: "18px"}}/>
                            </div>
                            <TextArea value={state.transcriptConversation.email}
                                      onChange={(e)=>setState({
                                          ...state,
                                          transcriptConversation: {
                                    ...state.transcriptConversation,
                                    allow: state.transcriptConversation.allow,
                                    email: e.target.value
                                }})} rows={4} placeholder={t("Enter-Email")}
                                      style={{height: "120px", width: "87%", marginTop: "1%", resize: "none"}}/>
                        </div>
                    }
                    <Button onClick={()=>savePreferenceFunc()} className='submit' type="primary">{t("Save")}</Button>
                    </div>
                </div>
                </div>
            </div>
    )
}

export default Preference
