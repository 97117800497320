import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {DatePicker, Modal, Radio} from "antd";

const {RangePicker} = DatePicker;
const TiebotDateFilters = (props) => {

    const [state, setState] = useState({
        startDate: '',
        endDate: '',
        isOpen: false,
        dateRangeType: 'today'
    })
    const handleDateRangeChange = (e) => {
        let value = e.target.value
        setState({...state, dateRangeType: value})
        return value === 'today' ? getTodayRange(value) :
            value === 'yesterday' ? getYesterdayRange(value) :
                value === 'last7days' ? getLast7DaysRange(value) :
                    value === 'last30days' ? getLast30DaysRange(value) :
                        value === 'thismonth' ? getThisMonthRange(value) :
                            value === 'lastmonth' ? getLastMonthRange(value) : null
    };
    const getTodayRange = (activeFilter) => {
        const today = moment();
        setState({
            ...state,
            startDate: today.startOf('day').format('YYYY-MM-DD'),
            endDate: today.endOf('day').format('YYYY-MM-DD'),
            dateRangeType: activeFilter
        })
    };

// Function to get the date range for Yesterday
    const getYesterdayRange = (activeFilter) => {
        const yesterday = moment().subtract(1, 'days');
        return setState({
            ...state,
            startDate: yesterday.startOf('day').format('YYYY-MM-DD'),
            endDate: yesterday.endOf('day').format('YYYY-MM-DD'),
            dateRangeType: activeFilter
        })
    };

// Function to get the date range for Last 7 Days
    const getLast7DaysRange = (activeFilter) => {
        const endDate = moment().endOf('day');
        return setState({
            ...state,
            endDate: moment().endOf('day').format('YYYY-MM-DD'),
            startDate: moment(endDate).subtract(6, 'days').startOf('day').format('YYYY-MM-DD'),
            dateRangeType: activeFilter
        })
    };

// Function to get the date range for Last 30 Days
    const getLast30DaysRange = (activeFilter) => {
        const endDate = moment().endOf('day');
        return setState({
            ...state,
            startDate: moment(endDate).subtract(29, 'days').startOf('day').format('YYYY-MM-DD'),
            endDate: moment().endOf('day').format('YYYY-MM-DD'),
            dateRangeType: activeFilter
        })
    };

// Function to get the date range for This Month
    const getThisMonthRange = (activeFilter) => {
        return setState({
            ...state,
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD'),
            dateRangeType: activeFilter
        })
    };

// Function to get the date range for Last Month
    const getLastMonthRange = (activeFilter) => {
        const startDate = moment().subtract(1, 'month').startOf('month');
        return setState({
            ...state,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: moment(startDate).endOf('month').format('YYYY-MM-DD'),
            dateRangeType: activeFilter
        })
    };

// Function to get the date range for Custom Range
    const getCustomRange = (start, end) => {
        const startDate = moment(start).startOf('day').format('YYYY-MM-DD');
        const endDate = moment(end).endOf('day').format('YYYY-MM-DD');
        return {
            startDate,
            endDate
        };
    };

    const handleDateChange = (dates, dateStrings) => {
        setState({
            ...state,
            dateRangeType: 'custom',
            startDate: dateStrings[0],
            endDate: dateStrings[1]
        })
    };

    // Example usage:
//     const todayRange = getTodayRange();
//     console.log('Today:', todayRange.startDate.format('YYYY-MM-DD'), 'to', todayRange.endDate.format('YYYY-MM-DD'));
//
//     const yesterdayRange = getYesterdayRange();
//     console.log('Yesterday:', yesterdayRange.startDate.format('YYYY-MM-DD'), 'to', yesterdayRange.endDate.format('YYYY-MM-DD'));
//
//     const last7DaysRange = getLast7DaysRange();
//     console.log('Last 7 Days:', last7DaysRange.startDate.format('YYYY-MM-DD'), 'to', last7DaysRange.endDate.format('YYYY-MM-DD'));
//
//     const last30DaysRange = getLast30DaysRange();
//     console.log('Last 30 Days:', last30DaysRange.startDate.format('YYYY-MM-DD'), 'to', last30DaysRange.endDate.format('YYYY-MM-DD'));
//
//     const thisMonthRange = getThisMonthRange();
//     console.log('This Month:', thisMonthRange.startDate.format('YYYY-MM-DD'), 'to', thisMonthRange.endDate.format('YYYY-MM-DD'));
//
//     const lastMonthRange = getLastMonthRange();
//     console.log('Last Month:', lastMonthRange.startDate.format('YYYY-MM-DD'), 'to', lastMonthRange.endDate.format('YYYY-MM-DD'));
//
// // Example for Custom Range
//     const customRange = getCustomRange('2024-03-01', '2024-03-15');
//     console.log('Custom Range:', customRange.startDate.format('YYYY-MM-DD'), 'to', customRange.endDate.format('YYYY-MM-DD'));


    return <Modal
        title={'Filter'}
        open={props.isFilterOpen}
        onOk={() => {
            let start = state.startDate
            let end = state.endDate
            props.closeFilterModal(start, end)
            setState({...state, dateRangeType: 'today'})
        }}
        okText={'Apply'}
        onCancel={()=> {
            let start = state.startDate
            let end = state.endDate
            props.closeFilterModal(start, end)
            setState({...state, dateRangeType: 'today'})
        }}
    >
        <Radio.Group style={{
            display:'flex',
            flexDirection:'column',
            gap:10
        }} onChange={handleDateRangeChange} value={state.dateRangeType}>
            <Radio value="today">Today</Radio>
            <Radio value="yesterday">Yesterday</Radio>
            <Radio value="last7days">Last 7 Days</Radio>
            <Radio value="last30days">Last 30 Days</Radio>
            <Radio value="thismonth">This Month</Radio>
            <Radio value="lastmonth">Last Month</Radio>
            <Radio value="custom">Custom Range</Radio>
        </Radio.Group>
        {
            state.dateRangeType==='custom' &&
            <RangePicker
                onChange={handleDateChange}
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                style={{marginTop:10}}
            />
        }
    </Modal>
}

export default TiebotDateFilters;