import React from 'react';
import { Line } from 'react-chartjs-2';

const LineChart = (props) => {

    // Chart configuration
    const options = {
        scales: {
            x: {
                type: 'category',
                labels: props.data.labels
            },
            y: {
                beginAtZero: true
            }
        },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
            }}
    };

    return (
        <div>
            <Line style={{height:props.height ? props.height : ''}} data={props.data} options={options} />
        </div>
    );
};

export default LineChart;
