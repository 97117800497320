import React from 'react';
import { Line } from 'react-chartjs-2';
import {Chart, registerables} from 'chart.js'
import { useTranslation } from 'react-i18next';
Chart.register(...registerables);
const LineChart = (props) => {
    let {heading} = props
    const { t } = useTranslation();
    const data = {
        labels: [t("January"), t("February"), t("March"), t("April"), t("May")],
        datasets: [
            {
                label: t("Monthly-Data"),
                data: [65, 59, 80, 81, 56],
                fill: false,
                borderColor: '#10163a',
                tension: 0.1, // Adjust the line curve (0.1 is the default)
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        maintainAspectRatio: false, // Add this option to make the chart responsive
        plugins: {
            legend: {
                display: false // Hides the legend
            },
            title: {
                display: false // Hides the title
            }
        }
    };

    return <>
        {/*{*/}
        {/*    props?.isAnalytics ?*/}
        <div className='cards-box'
             style={{
                 height:250,
                 marginTop:10,
                 width:'100%'
             }}>
            <h6>{heading}</h6>
                <Line style={{margin:"auto", width:"100%", padding:10}} data={data} options={options} />
        </div>
        {/*        :*/}
        {/*        <Line style={{margin:"auto"}} data={data} options={options} />*/}
        {/*}*/}
    </>
}

export default LineChart;