import React, {useEffect, useState} from "react";
import "../ForgotPassword/ForgotPassword.css";
import {Link, useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import {ResetPasswordApi} from "./API";
import LeftSection from "../LeftSection/LeftSection";
import Drop from "../Drop/Drop";
import {Button, Input} from 'antd';
import googleIcons from "../../../assets/media/svg/brand-logos/google-icon.svg";
import facebookIcon from "../../../assets/svg/social-logos/facebook.svg";
import SnackBar from "../../ResuseableComponnets/SnackBar";

const ResetPassword = () => {
    const [state, setState] = useState({
        passwordConfirmation:'',
        password:'',
        token:'',
        severity: "",
        message: "",
        notificationValue: false,
        passwordError:false,
        passErrMsg:"",
        confirmPasswordError:'',
        confirmPassErrMsg:''
    })
    const { id } =useParams()
    const navigate = useNavigate()
    const ResetPasswordFunc = () => {
        (!state.password || !state.passwordConfirmation) ? setState({
            ...state,
            message: "All field required",
            passwordError:true
        }) : ResetPasswordApi(state).then((res) => {
            if (res?.data?.success) {
                navigate("/");
            } else {
                setState((prevState) => ({
                    ...prevState,
                    message: state.password.length < 6 ? "Password should be at least 6 character.": "Passowrd must match",
                    passwordError:true
                }));
            }
        })
            .catch(() => {
                setState((prevState) => ({
                    ...prevState,
                    severity: "error",
                    message: "Error", // You can customize the error message
                    notificationValue: !state.notificationValue,
                }));
            })
    }

    useEffect(() => {
        setState({...state,token: id})
    }, []);

    return <div className="d-flex flex-column flex-root" style={{height:"100vh"}}>
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
            <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 pb-0 order-2 order-lg-1">
                <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                    <div className="w-lg-500px p-10">
                        <form className="form w-100" noValidate="novalidate" id="kt_password_reset_form">
                            <div className="text-center mb-10">
                                <h1 className="text-dark fw-bolder mb-3">Reset Password ?</h1>
                            </div>
                            <div className="fv-row mb-8" style={{height:"40px"}}>
                                <Input.Password
                                    size="large"
                                    placeholder={'Password'}
                                    value={state.password}
                                    onChange={(e) => setState({
                                        ...state,
                                        password: `${e.target.value}`,
                                        passwordError: e.target.value.includes(" ") || e.target.value.length < 6,
                                        passErrMsg:
                                            e.target.value.includes(" ") ?
                                                'Password cannot contain spaces' :
                                                e.target.value.length < 6 ?
                                                    'Password cannot be smaller than six characters ': ""
                                    })}
                                    autoComplete="off"
                                    status={(state.passwordError && state.password) && "error"}
                                />
                                {(state.passwordError && state.password) && <p style={{fontSize: 12, color: "red"}}>{state.passErrMsg}</p>}
                            </div>

                            <div className="fv-row mb-8" style={{height:"40px"}}>
                                <Input.Password
                                    size="large"
                                    placeholder={'Confirm-password'}
                                    value={state.passwordConfirmation}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            passwordConfirmation: `${e.target.value}`,
                                            confirmPasswordError:
                                                e.target.value.includes(" ") ||
                                                e.target.value.length < 6 ||
                                                state.password !== e.target.value,
                                            confirmPassErrMsg:
                                                e.target.value.includes(" ") ?
                                                    'Password cannot contain spaces' :
                                                    e.target.value.length < 6 ?
                                                        'Password cannot be smaller than six characters ' : "Password should be same."
                                        })
                                    }
                                    }
                                    autoComplete="off"
                                    status={(state.confirmPasswordError && state.passwordConfirmation) && "error"}
                                />
                                {(state.confirmPasswordError && state.passwordConfirmation) && <p style={{fontSize: 12, color: "red"}}>{state.confirmPassErrMsg}</p>}
                            </div>
                            <div className="d-flex flex-wrap justify-content-center pb-lg-0" style={{gap:"20px !important"}}>
                                <Button
                                    disabled={
                                        state.passwordError ||
                                        state.confirmPasswordError
                                    }
                                    type="primary"
                                    className="submit-btn"
                                    onClick={()=>ResetPasswordFunc()}
                                    style={{backgroundColor: (
                                            state.passwordError ||
                                            state.confirmPasswordError ||
                                            !state.password ||
                                            !state.passwordConfirmation
                                        ) ? "lightgray" : "#04B440"}}
                                >Submit
                                </Button>
                                <a onClick={()=>navigate('/')} className="btn btn-light">Cancel</a>
                            </div>
                        </form>
                    </div>
                </div>
                <Drop/>
            </div>
            <LeftSection/>
        </div>
    </div>
}

export default ResetPassword