import React, { memo, useState} from "react";
import { Handle, Position } from "reactflow";
import NodeActions from "../hooks/nodeAction";
import EditMessage from "../edit/question";
import { useNodes } from "../../allnode";


const Node = (completeNode) => {
  const {showEdit, setShowEdit} = useNodes();


  const data = completeNode?.data;
  const selected = completeNode?.selected;
    return (
      <>
      <div className={`option-node flow-node ${completeNode.templateStarter ? 'starter' : ''}`}>
        <div className={`style-body option-body ${selected ? 'style-selected' : ''}`} onClick={()=> setShowEdit(completeNode)}>
          <div className="style-title">Ask A Question <NodeActions completeNode={completeNode}  /></div>
            <div className="style-contentWrapper">{data.content}</div>
        </div>
        <Handle type="source" position={Position.Left} id="a" className="option-node-target left"  />
      </div>
      {/* {editMessage &&
        <EditMessage editedNode={completeNode} editMessageFn={editMessageFn} />
      } */}
      </>
    );
  }


export default memo(Node);
