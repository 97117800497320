import React from "react";
import axios from "../../Authentication/headers"

export function notificationlist(){
    return axios.get(`/notification/list`)
}

export function markNotificationRead(id){
    return axios.patch(`/notification/read/${id}`)
}

export function markallNotificationRead(){
    return axios.patch(`/notification/readAll`)
}
