import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import React, { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';


const Table_Templates = (props) => {
    const [state, setState] = useState({
        isGoingToInfo:false
    })
    const [pagination, setPagination] = React.useState({
        current: 1, // Use a state variable to manage the current page
        pageSize: 8,
    });

    let updatedArray =  props.data.map((obj) => ({
        ...obj,
        action: <div style={{display:"flex", gap:6, opacity:.7}}>
            <EditIcon onClick={()=> props.updateTemplate(obj)}/>
        </div>
    }))

    updatedArray.sort((a, b) => new Date(b.created) - new Date(a.created));
    const { t } = useTranslation();

    const handleRowClick = (data) => {
        setState({...state, isGoingToInfo: true})
        // props.selectedTeam(data)
    };

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title: "Title",
            dataIndex: 'title',
            key: 'title',
            width: '20%',
            ...getColumnSearchProps('title'),
        },
        {
            title: "Body",
            dataIndex: 'body',
            key: 'body',
            width: '30%',
            ...getColumnSearchProps('body'),
        },
        {
            title: "Status",
            dataIndex: 'status',
            key: 'status',
            ...getColumnSearchProps('status')
        },
        {
            title: "Actions",
            dataIndex: 'action',
            key: 'action',
            ...getColumnSearchProps('action'),
        },
    ];
    return <Table
        // onRow={(record, rowIndex) => ({
        //     onClick: () => {
        //         handleRowClick(record);
        //     }
        // })}
        pagination={pagination}
        onChange={(page) => {
            setPagination(page);
        }} style={{marginTop:"2%"}} columns={columns} dataSource={updatedArray} />;
};
export default Table_Templates;