import React, {useState, useEffect, useRef} from "react";
import "./Inbox.scss";
import TextField from '@mui/material/TextField';
import AllConversations from "./AllConversations";
import ChatPanel from "./ChatPanel/chatPanel";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SendIcon from '@mui/icons-material/Send';
import EmojiPicker from 'emoji-picker-react';
import Popover from '@mui/material/Popover';
import axios from 'axios';
import {Dropdown, Menu, Modal, Upload, Drawer, Tabs, Radio, Tooltip, DatePicker, Button} from "antd";
import {useNavigate, useLocation} from 'react-router-dom';
import Loader from "../ResuseableComponnets/Loader";
import ContactInfo from "./ContactInfo";
import {getAllQuickReply} from "../Settings/Quickreply/API/Api";
import {useDispatch, useSelector} from "react-redux";
import {MoreOutlined, UnorderedListOutlined} from "@ant-design/icons";
import pdfImage from '../../assets/svg/files/pdf.svg';
import excelImage from '../../assets/svg/files/excel.png';
import {FaArrowLeft} from "react-icons/fa";

import {
    aiGenerateMsg,
    exportReport,
    getForwardChats,
    getInboxContactInformation
} from "./Apis";
import openNotificationWithIcon from "../ResuseableComponnets/Toaster";
import ActionsCreators from "./states";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Audio from "./Audio";

const {RangePicker} = DatePicker;
const Inbox = (props) => {
    const [state, setState] = useState({
        attachmentsModal: false,
        newChatSelection: false,
        showChat: false,
        open: false,
        allConversation: null,
        replyMesssage: "",
        attachments: [],
        channel: 'WhatApp',
        chatPanelLoader: false,
        filterName: "all",
        allQuickReply: [],
        fileList: [],
        openDrawer: false,
        mode: '',
        selectedFile: 'pdf',
        exportDateRange: [],
        exportDateFormatted: [],
        isLoader: false,
        conatactFilter: '1',
        interactiveHistory: [],
        collapse: true,
        isChatExpired: true,
        isAudioBlob:false,
        removeFiles:false
    })
    const [anchorEl, setAnchorEl] = React.useState(null);

    const location = useLocation()
    const dispatch = useDispatch()
    const pathName = location.pathname.split('/inbox/')[1]
    let channelName = !!pathName && pathName.split('/')[0]
    let filterName = !!pathName && pathName.split('/')[1]
    let forwardChats = []
    const handleChange = (info) => {
        let fileList = [...info.fileList];

        fileList = fileList.slice(-1);

        setState({...state, fileList: fileList});
    };

    const multimediaSendFunc = () => {
        const formData = new FormData();
        Array.from(state.fileList).forEach((file) => {
            formData.append(`media`, file.originFileObj);
        });

        const dataObject = {
            body: {
                conversationId: state.allConversation.conversationId,
                message: state.replyMesssage,
            },
        };

        formData.append('data', JSON.stringify(dataObject.body));
        axios.post(props.url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        })
            .then(() => {
                setState({...state, replyMesssage: '', fileList: [], openDrawer: false, removeFiles:false})
            })
            .catch((err) => {
                console.log(err, 'Err')
            });
    }

    const handleUpload = () => {
        let formData = new FormData()
        const dataObject = {
            body: {
                conversationId: state.allConversation.conversationId,
                message: state.replyMesssage,
            },
        };

        formData.append('data', JSON.stringify(dataObject.body));
        axios.post(props.url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        })
            .then(() => setState({...state, replyMesssage: '', removeFiles:false}))
            .catch((err) => console.log(err, 'Err'));
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const showChat = () => {
        setState({...state, showChat: true, newChatSelection: false})
    }

    const getAllConversation = (data) => {
        setState({...state, showChat: true, allConversation: data, chatPanelLoader: false})
    }
    const onEmojiClick = (event) => {
        setState({
            ...state, replyMesssage: state.replyMesssage + event.emoji
        })
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleUpload();
        }
    };

    const loaderInChatPanel = (value) => {
        setState({...state, chatPanelLoader: value})
    }

    const onChange = (key) => {
        setState({...state, filterName: key}
        )
    };

    const onChangeContacts = (key) => {
        setState({...state, conatactFilter: key}
        )
        key === '2' && getInboxContactInformation(state?.allConversation?.conversationId)
            .then(res => {
                setState(prevState => ({...state, interactiveHistory: res.data?.data, conatactFilter: '2'}))
            })
            .catch(err => console.log(err, 'Error'))
    };
    const quickReplyFunc = () => {
        getAllQuickReply().then(res => setState({
            ...state,
            allQuickReply: res.data.data
        })).catch(err => console.log(err))
    }

    const exportReportFunc = () => {
        setState({...state, isLoader: true})
        let data = {
            startDate: new Date(state.exportDateRange[0]),
            endDate: new Date(state.exportDateRange[1]),
            exportType: state.selectedFile,
            conversationId: state?.allConversation?.conversationId
        }
        exportReport(data)
            .then(res => {
                setState({
                    ...state,
                    exportDateRange: [],
                    attachmentsModal: false,
                    isLoader: false
                })
                window.location.href = res.data.data.file;
                openNotificationWithIcon(
                    'success',
                    'Success message',
                    res.data?.message
                )
            })
            .catch(err => console.log(err, 'Error'))
    }
    const items = [
        {
            key: '1',
            label: 'All'
        },
        {
            key: '2',
            label: 'Opened'
        },
        {
            key: '3',
            label: 'Pending'
        },
        {
            key: '4',
            label: 'Closed'
        },
        {
            key: '5',
            label: 'Spam'
        },
        {
            key: '6',
            label: 'Forwarded'
        }
    ];

    const contactTabs = [
        {
            key: '1',
            label: 'Profile'
        },
        {
            key: '2',
            label: 'History'
        }
    ]

    const getForwardChatsFunc = () => {
        getForwardChats().then(res => forwardChats = res.data.data).catch(err => console.log(err, 'Error'))
    }
    const handleDateRangeChange = (dates, dateStrings) => {
        setState({...state, exportDateRange: dates, exportDateFormatted: dateStrings})
    };

    let assignedAgentId = state?.allConversation?.info?.assign?.to?.email ?
        state?.allConversation?.info?.assign?.to?.id :
        state?.allConversation?.contactInfo?.assign?.to?.id

    let myId = useSelector(state => state?.me?.me?.id)
    let showMessageBar = assignedAgentId === myId
    const showMessageBarFunc = () => {
        // console.log()
    }

    let conversations = state.filterName === "3" ?
        props.conversations.filter(item => item.status === "pending") :
        state.filterName === "4" ?
            props.conversations.filter(item => item.status === "solved") :
            state.filterName === "2" ?
                props.conversations.filter(item => item.status === "open") :
                state.filterName === "5" ?
                    props.conversations.filter(item => item.status === "spam") :
                    state.filterName === "6" ?
                        forwardChats :
                        props.conversations

    forwardChats = getForwardChats().then(res => console.log(res, 777112221)).catch(err => console.log(err, 'Error'))

    const openRightSection = () => {
        setState({
            ...state,
            openDrawer: true,
            mode: '',
            selectedFile: 'pdf',
            conatactFilter: '1'
        })
    }
    const openRangePicker = () => {
        setState({
            ...state,
            attachmentsModal: true,
            mode: '',
            selectedFile: 'pdf'
        })
    }

    const isBlobExist = (value) => {
        setState({...state, isAudioBlob: value})
    }

    const aiGenerateMsgFunc = (data) => {
        aiGenerateMsg({question: data}).then(res => setState({
            ...state,
            replyMesssage: res.data.data.aiReply
        })).catch(() => console.log('errrrrr'))
    }

    const getAllChat = (id, _id, contactInfo) => {
        setState({...state, chatPanelLoader: true})
        // let chatApiFunc = !!props?.isBotInbox ? props.getChat(id, 1) : props.getChat(id, 1)
        props.getChat(id, 1).then(res => {
            setState({...state, isLoader: false})
            let responseData = {
                messages: res.data.data,
                conversationId: id,
                _id,
                contactInfo
            }
            {
                props.channel === "whatsapp" ?
                    dispatch(ActionsCreators.deleteWhatsappConversationAction({
                        conversationId: id
                    }))
                    :
                    props.channel === "messenger" ?
                        dispatch(ActionsCreators.getMessengerConversationAction(responseData)) :
                        dispatch(ActionsCreators.getInstagramConversationAction(responseData))
            }
            res?.data?.data && getAllConversation(responseData)
        })
            .catch(() => {
                setState({...state, isLoader: false})
                openNotificationWithIcon('error',
                    'Error Message',
                    'Error while getting messages.'
                )
            })
    }

    useEffect(() => {
        getForwardChatsFunc()
    }, []);

    useEffect(() => {
        quickReplyFunc()
        setState({...state, channel: channelName, filterName: filterName})
    }, []);

    console.log()
    return <div className={'synctic-module-container'}>
        <div className={'cards-box tab-container'} style={{padding: 0}}>
            <Tabs
                defaultActiveKey={filterName}
                items={items}
                onChange={onChange}
                indicatorSize={(origin) => origin - 16}
                style={{paddingLeft: 24, paddingRight: 24}}
            />
        </div>
        <div className="chat-main-container">
            <div style={{position: 'relative', overflow: 'visible'}}
                 className={`chat-left-panel cards-box ${state.collapse ? 'collapse' : ""}`}>
                <Button
                    style={{
                        position: 'absolute',
                        zIndex: 1,
                        right: -20,
                        bottom: 90,
                        background: '#10163a'
                    }}
                    icon={<FaArrowLeft color={'white'} style={{
                        color: 'white',
                        transform: !state.collapse ? 'rotate(180deg)' : ''
                    }}/>}
                    onClick={() => {
                        setState({...state, collapse: !state.collapse});
                    }}
                />
                <AllConversations
                    conversations={conversations}
                    showChat={showChat}
                    getAllConversation={getAllConversation}
                    getChat={props.getChat}
                    channel={props.channel}
                    channelLogo={props.channelLogo}
                    loaderInChatPanel={loaderInChatPanel}
                    filterName={state.filterName}
                    getAllChat={getAllChat}
                />
            </div>
            {
                state.chatPanelLoader ? <Loader width={'75%'}/> :
                    !!state.allConversation && !!state.showChat &&
                    <div
                        className="chat-right-panel cards-box"
                    >
                        <ChatPanel
                            allConversation={state.allConversation}
                            conversations={props.conversations}
                            assignStatusFunc={props.assignStatusFunc}
                            assignAgentFunc={props.assignAgentFunc}
                            channel={props.channel}
                            loaderInChatPanel={loaderInChatPanel}
                            openRightSectionOpen={openRightSection}
                            openRangePicker={openRangePicker}
                            aiGenerateMsgFunc={aiGenerateMsgFunc}
                            getAllChat={getAllChat}
                            showMessageBar={showMessageBar}
                            showMessageBarFunc={showMessageBarFunc}
                            isBotInbox={props?.isBotInbox}
                            getChat={props.getChat}
                        />
                        {
                            !props?.isBotInbox && showMessageBar &&
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: 10,
                                width: "100%",
                                gap: 10,
                                paddingBottom: 6
                            }}>
                                <div style={{display: "flex", alignItems: "center", gap: 10, width: "100%"}}>
                                    {
                                        !state.isAudioBlob &&
                                        <Dropdown overlay={(
                                        <Menu>
                                            {
                                                state?.allQuickReply.length > 0 &&
                                                state?.allQuickReply.map((item, idx) => <Menu.Item key={idx}>
                                                        <div onClick={() => {
                                                            setState({...state, replyMesssage: item.name})
                                                        }
                                                        }> {item.name} </div>
                                                    </Menu.Item>
                                                )
                                            }
                                        </Menu>
                                    )} trigger={['click']}>
                                        <a onClick={(e) => e.preventDefault()}>
                                            <Tooltip
                                                placement="top"
                                                title={"Quick Replies"}>
                                                <UnorderedListOutlined style={{
                                                    fontSize: '14px',
                                                    cursor: 'pointer'
                                                }}/>
                                            </Tooltip>
                                        </a>
                                    </Dropdown>}
                                    {
                                        !state.isAudioBlob && <Tooltip
                                        placement="top"
                                        title={"Add attachment"}>
                                        <MoreOutlined onClick={() => setState({
                                            ...state,
                                            openDrawer: true,
                                            removeFiles:true,
                                            mode: 'attachment'
                                        })} style={{
                                            fontSize: 22,
                                            opacity: 0.8,
                                            cursor: 'pointer'
                                        }}/>
                                    </Tooltip>}
                                    {
                                        !state.isAudioBlob &&
                                        <Tooltip
                                        placement="top"
                                        title={"Add emoji"}>
                                        <EmojiEmotionsIcon
                                            aria-describedby={id}
                                            variant="contained"
                                            onClick={handleClick}
                                            style={{
                                                fontSize: 18,
                                                opacity: .8,
                                                cursor: 'pointer'
                                            }}/>
                                    </Tooltip>
                                    }
                                    {
                                        !state.isAudioBlob &&
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <EmojiPicker onEmojiClick={onEmojiClick}/>
                                    </Popover>
                                    }
                                    {
                                        !state.isAudioBlob &&
                                        <TextField
                                            id="outlined-size-small"
                                            placeholder="Enter message"
                                            size="small"
                                            style={{width: "92%"}}
                                            value={state.replyMesssage}
                                            onKeyDown={handleKeyDown}
                                            onChange={(e) => setState({...state, replyMesssage: e.target.value})}
                                        />
                                    }
                                </div>
                                {
                                    console.log(props.url, 55513141111)
                                }
                                <Audio
                                    isBlobExist={isBlobExist}
                                    data={{
                                    conversationId: state.allConversation.conversationId,
                                    message: state.replyMesssage,
                                }}
                                url={props.url}
                                />
                                <SendIcon onClick={!!state.replyMesssage ? handleUpload : null}
                                          style={{fontSize: 28, color: 'rgb(4, 200, 200)'}}/>
                            </div>
                        }
                    </div>
            }

            <Drawer closable={state.mode !== 'attachment' && false} 
            height={state.mode !== 'attachment' && 200}
                    placement={state.mode !== 'attachment' ? 'right' : 'bottom'}
                    title={!state.mode === 'attachment' ? "Contact Information" : null}
                    onOpen={() => setState({
                        ...state,
                        conatactFilter: '1'
                    })}
                    onClose={() => {
                        setState({
                            ...state, 
                            openDrawer: false, 
                            fileList: [], 
                            conatactFilter: null, 
                            removeFiles:false
                        })
                    }} 
                    open={state.openDrawer}
                    >
                <div style={{height: '100%'}}>
                    {
                        state.mode !== 'attachment' && state.conatactFilter !== null ?
                            <div>
                                <Tabs
                                    defaultActiveKey={state.conatactFilter}
                                    items={contactTabs}
                                    onChange={onChangeContacts}
                                    indicatorSize={(origin) => origin - 16}
                                    style={{paddingLeft: 24, paddingRight: 24}}
                                />
                                {
                                    state.conatactFilter === '1' ? !!state.allConversation && !!state.showChat &&
                                        <ContactInfo
                                            contactInfo={state.allConversation}
                                            showMessageBar={showMessageBar}
                                        /> :
                                        <ListItem alignItems="flex-start" style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 10
                                        }}>
                                            {
                                                !!state.interactiveHistory?.interactiveHistory && state.interactiveHistory.interactiveHistory.map((item, idx) =>
                                                    <ListItemText
                                                        primary={<div style={{
                                                            fontWeight: 600,
                                                            fontSize: 14,
                                                            opacity: .8
                                                        }}>{item?.assignBy?.email}</div>}
                                                        secondary={<div key={idx}>
                                                            <div style={{
                                                                fontSize: 13,
                                                                fontWeight: 550
                                                            }}>Topic: {item?.topic}</div>
                                                            <div
                                                                style={{fontSize: 12}}>{moment(item?.assignAt).format('MMMM Do YYYY, h:mm a')}</div>
                                                            <hr/>
                                                        </div>
                                                        }
                                                        style={{width: '100%'}}
                                                    />)
                                            }
                                        </ListItem>
                                }

                            </div>
                            :
                            state.mode === 'attachment' && state.removeFiles &&
                            <div style={{
                                width: 'fit-content',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                                flexDirection: 'column'
                            }}>
                                <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                                    <Upload
                                        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                        listType="picture"
                                        defaultFileList={[...state.fileList]}
                                        className="upload-list-inline"
                                        onChange={handleChange}
                                        // onRemove={() => handleRemove}
                                    >
                                        {
                                            state.fileList.length < 1 &&
                                            <div style={{
                                                padding: 20,
                                                borderRadius: '50%',
                                                background: 'rgba(255, 219, 38, 0.4)',
                                                width: 'fit-content'
                                            }}>
                                                <AttachFileIcon style={{
                                                    fontSize: 30,
                                                    transform: 'rotate(45deg)',
                                                    opacity: 0.8,
                                                    cursor: 'pointer',
                                                }}/>
                                            </div>
                                        }
                                    </Upload>
                                    {
                                        state.fileList.length > 0 && <SendIcon
                                            onClick={() => multimediaSendFunc()}
                                            style={{
                                                fontSize: 28,
                                                color: 'rgb(4, 200, 200)',
                                                cursor: 'pointer'
                                            }}/>}
                                </div>
                                <p style={{textAlign: 'center'}}>Attachment</p>
                            </div>
                    }
                </div>
            </Drawer>

            <Modal
                title={'Export Conversation'}
                open={state.attachmentsModal}
                onOk={() => exportReportFunc()}
                okButtonProps={{disabled: state.isLoader || !state?.exportDateRange || state?.exportDateRange?.length < 1}}
                cancelButtonProps={{disabled: state.isLoader}}
                okText={'Export'}
                onCancel={() => setState({
                    ...state,
                    mode: '',
                    attachmentsModal: false,
                    selectedFile: 'pdf',
                    exportDateRange: []
                })
                }
            >
                {
                    state.isLoader ? <Loader height={'10vh'}/> :
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <RangePicker value={state.exportDateRange} onChange={handleDateRangeChange}/>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 30,
                                marginTop: 30
                            }}>
                                <Radio.Group
                                    value={state.selectedFile}
                                    onChange={e => setState({
                                        ...state,
                                        selectedFile: e.target.value
                                    })}
                                    name="radiogroup"
                                    defaultValue={state.selectedFile}
                                >
                                    <Radio value={'pdf'} checked={state.selectedFile === 'pdf'}><img style={{width: 50}}
                                                                                                     src={pdfImage}/></Radio>
                                    <Radio value={'xlsx'} checked={state.selectedFile === 'xlsx'}><img
                                        style={{width: 50}}
                                        src={excelImage}/></Radio>
                                </Radio.Group>
                            </div>
                        </div>
                }
            </Modal>
        </div>
    </div>
}

export default Inbox