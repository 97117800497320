import React, {useState} from "react";
import "./Login.css";
import googleIcons from "../../../assets/media/svg/brand-logos/google-icon.svg";
import facebookIcon from "../../../assets/svg/social-logos/facebook.svg";
import {Link} from "react-router-dom";
import {LoginApis} from "../Login/Api/LoginApis";
import {useNavigate} from "react-router-dom";
import SnackBar from "../../ResuseableComponnets/SnackBar";
import {useTranslation} from 'react-i18next';
import {useDispatch} from "react-redux";
import ActionsCreators from "../../Inbox/states";
import LeftSection from "../LeftSection/LeftSection";
import Drop from "../Drop/Drop";
import {Input, Button, Form} from 'antd';
import openNotificationWithIcon from "../../ResuseableComponnets/Toaster";
import Komkast from "../../../assets/media/misc/Kom Kast.png"

const Login = () => {
    const [state, setState] = useState({
        emailError: false,
        emailErrMsg: '',
        passwordError: false,
        passErrMsg: '',
        isLoader: false
    });
    const dispatch = useDispatch()

    const onFinish = (values) => {
        setState({...state, isLoader: true})
        LoginApis(values)
            .then((res) => {
                if (res?.data?.success) {
                    localStorage.setItem("token", res.data.data.jwtToken)
                    dispatch((ActionsCreators.myProfile(res.data.data)))
                    setState({...state, isLoader: false})
                    navigate("/dashboard");
                    openNotificationWithIcon('success',
                        'Success Message',
                        res.data?.message
                    )
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        isError: true,
                        passErrMsg: "Please enter correct password",
                        emailErrMsg: "Please enter correct email",
                        emailError: true,
                        passwordError: true,
                        isLoader: false
                    }))
                    openNotificationWithIcon('error',
                        'Error Message',
                        res?.message
                    )
                }
            })
            .catch((err) => {
                setState((prevState) => ({
                    ...prevState,
                    severity: "error",
                    message: "Email or Password is incorrect",
                    notificationValue: !state.notificationValue,
                    isLoader: false
                }));
                openNotificationWithIcon('error',
                    'Error Message',
                    ''
                )
            })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const [form] = Form.useForm();

    const {t} = useTranslation();
    const navigate = useNavigate()
    return <div className="d-flex flex-column flex-root" style={{height: "100vh"}}>
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
            <LeftSection/>
            <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 pb-0 pt-2 order-2 order-lg-1">
                <div style={{display: "flex", marginTop: "-5%"}}>
                    <img src={Komkast} alt="" style={{width: "29%", marginTop: "8%"}}/>
                    <div style={{
                        width: '2px',
                        height: '55%',
                        border: "1px solid #363a77",
                        marginTop: "8%",
                        marginLeft: "10px",
                        opacity: ".7"
                    }}></div>
                </div>
                <div style={{
                    marginLeft: "6%",
                    marginTop: "-28px",
                    fontSize: "19px",
                    textAlign: "center",
                    fontFamily: "rial, Helvetica, sans-serif",
                    color: "#363a77"
                }}>Connect Communicate Conquer
                </div>
                <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                    <div style={{marginTop: "-21%"}}>
                        <div style={{visibility: "hidden"}} className="row g-3 mb-9">
                            <div className="col-md-6" style={{width: "max-content"}}>
                                <a href={'http://backendapiv2.synctik.com/account/social/googleSignUp'}
                                   target={'_blank'}
                                   className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                                    <img alt="Logo" src={googleIcons} className="h-15px me-3"/>{t('Gooogle-sign')}</a>
                            </div>
                            <div className="col-md-6" style={{width: "max-content"}}>
                                <div
                                    className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                                    <img alt="Logo" src={facebookIcon}
                                         className="theme-dark-show h-15px me-3"/>{t('Sign-facbook')}
                                </div>
                            </div>
                        </div>

                        <div style={{visibility: "hidden"}} className="separator separator-content my-14">
                            <span className="w-125px text-gray-500 fw-semibold fs-7"
                                  style={{fontWeight: "550px !important"}}>{t('Or-with-email')}</span>
                        </div>
                        <div className="text-center mb-11">
                            <h1 className="text-dark fw-bolder mb-3">{t('Login')}</h1>
                            <div className="not-a-member">Welcome to Tiebot</div>
                            {/*<div className="not-a-member">{t('SocialCampaigns')}</div>*/}
                        </div>

                        <Form
                            form={form}
                            name="email_form"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                    {
                                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        message: 'Please enter a valid email address',
                                    }
                                ]}
                            >
                                <Input
                                    size={'large'}
                                    placeholder={'Email'}
                                    value={state.email}
                                />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    }
                                ]}
                            >
                                <Input.Password
                                    value={state.password}
                                    size={'large'}
                                    placeholder={'Password'}
                                />
                            </Form.Item>
                            <div style={{textAlign: 'right', width: '100%'}}>
                                <Link to={"/forgotPassword"}>
                                    <a style={{color: "rgb(54, 58, 119)"}}>{t('Forgot Password')}</a>
                                </Link>
                            </div>

                            <Form.Item shouldUpdate>
                                {() => (
                                    <Button
                                        size={'large'}
                                        type="primary"
                                        htmlType="submit"
                                        style={{
                                            background: "#04B440",
                                            marginTop: 16,
                                            width: '100%'
                                        }}
                                        disabled={
                                            state.isLoader ||
                                            !form.isFieldsTouched(true) ||
                                            form.getFieldsError().filter(({errors}) => errors.length).length
                                        }
                                    >
                                        {
                                            !!state.isLoader ? 'Loading...' : 'Login'
                                        }
                                    </Button>
                                )}
                            </Form.Item>
                        </Form>

                        <div className="not-a-member">{t('Not-a-Member-yet')}
                            <Link to={"/signup"}>
                                <a style={{color: "rgb(54, 58, 119)"}}>{t('Sign-up')}</a>
                            </Link></div>
                    </div>
                </div>
                <Drop/>
            </div>
        </div>
        <SnackBar severity={state.severity} message={state.message} notificationValue={state.notificationValue}/>
    </div>
}

export default Login