import React, {useEffect, useState} from 'react';
import {
    Button,
    Form,
    Input,
    Select,
    Drawer
} from 'antd';
import {AddContact, updateContactApi} from "../../Api/Api";
import {getAllActivatedTags} from "../../../Settings/Tags/API/Api";
import openNotificationWithIcon from "../../../ResuseableComponnets/Toaster";

const {Option} = Select;
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const ContactForm = (props) => {
    const [state, setState] = useState({
        allTags:[]
    })
    const [form] = Form.useForm();
    const onFinish = (values) => {
        props.mode === 'new' ?
            onSubmit(values) : onUpdate(values)
        form.resetFields()
    };
    const onSubmit = (data) => {
        AddContact(data).then(() => {
            props.getContacts()
        }).catch((err) => {
            console.log(err)
        })
    }
    const onUpdate = (data) => {
        updateContactApi(props.contactId, data).then(() => {
            props.getContacts()
        }).catch(error => console.log(error))
    }

    const getAllTagsFunc = () => {
        getAllActivatedTags().then(res => {
                setState(prevState => ({
                    ...prevState,
                    allTags: res.data.data
                }))
            }
        ).catch(() => {
            console.log('Error while getting activated tags.')
        })
    }


    useEffect(() => {
        getAllTagsFunc()
    }, []);
    return (
        <Drawer placement={'right'}
                title={props.mode === 'new' ? 'Create contact' : 'Update contact'}
                open={props.openDrawer}
                width={800}
                onClose={() => {
                    props.closeDialogue()
                    form.resetFields()
                }}
        >
            <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
                initialValues={props.mode !== 'new' && props.stateData}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        {
                            type: 'string',
                            message: 'Invalid name',
                        },
                        {
                            required: true,
                            message: 'Please enter your name',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'Please input your E-mail!',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="Phone Number"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your phone number!',
                        },
                    ]}
                >
                    <Input style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item
                    name="tags"
                    label="Tags"
                    rules={[
                        {
                            required: true,
                            message: 'Please add tags!',
                            type: 'array',
                        },
                    ]}
                >
                    <Select mode="multiple" placeholder="Please select favourite colors">
                        {
                            state?.allTags.length > 0 && state.allTags.map(obj => <Option
                                value={obj.name}>{obj.name}</Option>)
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    name="opted"
                    label="Opted In"
                    rules={[
                        {
                            type: 'string',
                            message: 'String only',
                        },
                        {
                            required: true,
                            message: 'Please enter ',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    name="address"
                    label="Address"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter ',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    name="experience"
                    label="Experience"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter ',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    name="age"
                    label="Age"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter ',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="interest"
                    label="Interest"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter ',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="city"
                    label="City"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter ',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="state"
                    label="State"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter ',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[
                        {
                            required: true,
                            message: 'Please select gender!',
                        },
                    ]}
                >
                    <Select placeholder="select your gender">
                        <Option value="male">Male</Option>
                        <Option value="female">Female</Option>
                        <Option value="other">Other</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="source"
                    label="Source"
                    rules={[
                        {
                            required: true,
                            message: 'Please select source!',
                        },
                    ]}
                >
                    <Select placeholder="select source">
                        <Option value="whatsapp">Whatsapp</Option>
                        <Option value="facebook">Facebook</Option>
                        <Option value="instagram">Instagram</Option>
                    </Select>
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {props.mode === 'new' ? 'Submit' : 'Update'}
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
};
export default ContactForm;