import React from 'react'
import "./Integration.scss"
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader'
import {AiFillApi} from "react-icons/ai";
import Button from '@mui/material/Button';
import {MdHighlightAlt} from "react-icons/md";
import Border_icons from '../../ResuseableComponnets/Border_icons';
import {SiHubspot} from "react-icons/si";
import {AiOutlinePlus} from "react-icons/ai";
import {TbBrandZapier} from "react-icons/tb";
import {SiShopify} from "react-icons/si";
import {GiMedusaHead} from "react-icons/gi";
import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";

const Integration = () => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    return (
        <div className="synctic-module-container">
            <SettingsHeader
                icon={<AiFillApi className='icon'/>}
                heading={t("Integrations")}
                description={t("Integrate-discription")}
            />
            <div className='Integrations'>
                <Button variant="contained"><MdHighlightAlt
                    style={{marginRight: "10px", fontSize: "20px"}}/>{t("Request-Integrations")}</Button>
            </div>
            <div style={{marginTop: "2%"}}>

                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Border_icons
                        icon1={<SiHubspot style={{color: "#d79416"}}/>}
                        icon2={<AiOutlinePlus/>}
                        heading={t("Hubspot")}
                        Descriptions={t("Sent-contact-discription")}
                    />
                    <Border_icons
                        icon1={<TbBrandZapier style={{color: "#e34343"}}/>}
                        icon2={<AiOutlinePlus/>}
                        heading={t("Zapier")}
                        Descriptions={t("Connect-synctik-discription")}
                    />
                    <Border_icons
                        icon1={<SiShopify style={{color: "#319531"}}/>}
                        icon2={<AiOutlinePlus onClick={
                            ()=>navigate('/settings/integrations/shopify')
                        }/>}
                        heading={t("Shopify")}
                        Descriptions={t("Integrate-Shopify-discription")}
                    />
                </div>
                <div style={{marginTop: "1%", display: "flex", gap: "8px"}}>

                    <Border_icons
                        icon1={<GiMedusaHead style={{color: "blue"}}/>}
                        icon2={<AiOutlinePlus onClick={
                            ()=>navigate('/settings/integrations/openApi')
                        }/>}
                        heading={t("Open Api")}
                        Descriptions={t("Intigrate-medusa-discription")}
                    />
                </div>
            </div>
        </div>
    )
}

export default Integration
