import React, {useState} from "react";
import {Button, Checkbox, Modal, Table, Tabs} from "antd";
import AgentsFilterTable from "../AgentPerformance/AgentsFilterTable";
import WhatsappCard from "./WhatsappCard";
import './WhatsappInsights.scss';
import Line from "../../../ResuseableComponnets/Charts/Line";
const WhatsappInsights = (props) => {
    const [state, setState] = useState({
        tCustomers: false,
        mac: false,
        mode:'',
        whatsapp:'',
        instagram:'',
        messenger:''
    })
    let {title, value, isModalOpen, mode, modalOpenFunc} = props
    const modalFooter = [
        <Button
            key="cancel"
            onClick={() => modalOpenFunc()}
        >
            Cancel
        </Button>,
        <Button
            key="apply"
            onClick={() => modalOpenFunc()}
            primary
        >
            Apply
        </Button>
    ];
    const engagedCustomersColumns = [
        {
            title: 'WhatsApp Number',
            dataIndex: 'whatsappNum',
            key: '1',
        },
        {
            title: 'WhatsApp Profile Name',
            dataIndex: 'whatsAppProfileName',
            key: '2',
        },
        {
            title: 'Message Volume',
            dataIndex: 'messageVolume',
            key: '3',
        }
    ];
    const AgentsBoardColumns = [
        {
            title: 'Agent',
            dataIndex: 'agent',
            key: '1',
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            key: '2',
        },
        {
            title: 'Accepted',
            dataIndex: 'accepted',
            key: '3',
        },
        {
            title: 'Solved',
            dataIndex: 'solved',
            key: '4',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: '5',
        },
        {
            title: 'Avg. Response Time',
            dataIndex: 'avgResponseTime',
            key: '6',
        },
        {
            title: 'Avg. Resolution Time',
            dataIndex: 'avgResolutionTime',
            key: '7',
        }
    ];


    const AgentsAvailibilityData = [];
    const items = [
        {
            key: '1',
            label: 'Channels',
            children: <div>
                <div style={{fontWeight: '550'}}>Channel</div>
                <div style={{marginTop:6}}/>
                <Checkbox
                    checked={state.whatsapp}
                    onChange={()=>setState({...state, whatsapp: !state.whatsapp})}
                >Whatsapp</Checkbox>
                <div style={{marginTop:4}}/>
                <Checkbox
                    checked={state.messenger}
                    onChange={()=>setState({...state, messenger: !state.messenger})}
                >Messenger</Checkbox>
                <div style={{marginTop:4}}/>
                <Checkbox
                    checked={state.instagram}
                    onChange={()=>setState({...state, instagram: !state.instagram})}
                >Instagram</Checkbox>
                <div style={{marginTop:4}}/>
            </div>,
        },
        {
            key: '2',
            label: 'Agents',
            children:<AgentsFilterTable/>,
        },
    ];
    const channelWiseData = {
        labels: ['January', 'February', 'March', 'April', 'May'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [65, 59, 80, 81, 56],
                borderColor: 'red',
                borderWidth: 1,
                fill: false
            }
        ]
    };


    return <div>
        <div className='count-card-container'>
            <WhatsappCard data={[
                {
                    key:'All Conversations',
                    value:'113'
                },
                {
                    key:'Marketing',
                    value:'4'
                },
                {
                    key:'Utility',
                    value:'2'
                },
                {
                    key:'Authentication',
                    value:'0'
                },
                {
                    key:'Service',
                    value:'107'
                }
            ]} />
            <WhatsappCard data={[
                {
                    key:'Free Conversations',
                    value:'108'
                },
                {
                    key:'Free Tier',
                    value:'106'
                },
                {
                    key:'Free Entry Point',
                    value:'2'
                }
            ]} />
            <WhatsappCard data={[
                {
                    key:'Paid Conversations',
                    value:'6'
                },
                {
                    key:'Marketing',
                    value:'106'
                },
                {
                    key:'Utility',
                    value:'2'
                },
                {
                    key:'Authentication',
                    value:'106'
                },
                {
                    key:'Services',
                    value:'2'
                }
            ]} />
            <WhatsappCard data={[
                {
                    key:'Approximate Charges',
                    value:'$0.5'
                },
                {
                    key:'Marketing',
                    value:'$0.18'
                },
                {
                    key:'Utility',
                    value:'$2'
                },
                {
                    key:'Authentication',
                    value:'$1'
                },
                {
                    key:'Services',
                    value:'$2'
                }
            ]} />
        </div>
        <div className={'cards-box'} style={{
            width: '100%',
            marginTop:10
        }}>
            <h6>All Conversations</h6>
            <Line height={240} data={channelWiseData} />
        </div>
        <div className={'cards-box'} style={{
            width: '100%',
            marginTop:10
        }}>
            <h6>Free Conversations</h6>
            <Line height={240} data={channelWiseData} />
        </div>
        <div className={'cards-box'} style={{
            width: '100%',
            marginTop:10
        }}>
            <h6>Paid Conversations</h6>
            <Line height={240} data={channelWiseData} />
        </div>
        <div className={'cards-box'} style={{
            width: '100%',
            marginTop:10
        }}>
            <h6>Approximate Charges</h6>
            <Line height={240} data={channelWiseData} />
        </div>

        <Modal
            title={ mode==='export' ? 'Export Insights' : mode==='export' && 'Filter Agent Performance'}
            open={isModalOpen}
            onCancel={() => {
                modalOpenFunc()
                setState({...state,
                    mac: false,
                    tCustomers: false,
                    whatsapp: false,
                    instagram: false,
                    messenger: false
                })
            }
            }
            footer={modalFooter}
            closable={()=> {
                modalOpenFunc()
                setState({
                    ...state,
                    mac: false,
                    tCustomers: false,
                    whatsapp: false,
                    instagram: false,
                    messenger: false
                })
            }}
        >
            {
                state.mode==='export' ?
                    <div>
                        <div style={{fontWeight: '550'}}>Include breakdown by</div>
                        <div style={{marginTop:6}}/>
                        <Checkbox
                            checked={state.mac}
                            onChange={()=>setState({...state, mac: !state.mac})}
                        >Total Customers</Checkbox>
                        <div style={{marginTop:4}}/>
                        <Checkbox
                            checked={state.tCustomers}
                            onChange={()=>setState({...state, tCustomers: !state.tCustomers})}
                        >Frequency Of Categorizes Inquired</Checkbox>
                        <div style={{marginTop:4}}/>
                    </div> : mode==='filter'
                    && <Tabs
                        defaultActiveKey="1"
                        tabPosition={'top'}
                        items={items}
                    />
            }
        </Modal>
    </div>

}

export default WhatsappInsights
