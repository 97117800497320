import Axios from 'axios';
import openNotificationWithIcon from "../ResuseableComponnets/Toaster";
import config from "../../config";
const axios = Axios.create({
    baseURL:config.api.baseUrl,
});

axios.interceptors.request.use(
    (config) => {
        config.headers['Authorization'] = `}${localStorage.getItem('token')}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response
        // return response;
    },
    (error) => {
        // Handle errors in the response
        if (error.response) {
            return error
        } else if (error.request) {
            // The request was made but no response was received (e.g., network error)
            console.error('Request error:', error.request);
        } else {
            // Something happened in setting up the request that triggered an error
            console.error('Request setup error:', error.message);
        }
        return Promise.reject(error); // Propagate the error for further handling
    }
);

export default axios;