import React, { memo, useState } from "react";
import { Handle, Position } from "reactflow";
import NodeActions from "../hooks/nodeAction";
import { useNodes } from "../../allnode";
import { EditNoteOutlined } from "@mui/icons-material";
import ContentRenderer from "../hooks/nodeContent";

const Node = (completeNode) => {
  const {nodes, showEdit, setShowEdit, selectIndex, setSelectedIndex} = useNodes();
  const data = completeNode?.data;
  const selected = completeNode?.selected;
  const showEditWindow = () =>{
    const nodeIndex = nodes.findIndex(node => node.id ===  completeNode.id)
    setSelectedIndex(nodeIndex)
    setShowEdit(completeNode)
  }
  return (
    <>
      <div className={`text-updater-node flow-node ${completeNode.templateStarter ? 'starter' : ''}`}>
        <div className="message-container" >
          <div className={`style-body ${selected ? 'style-selected' : ''}`}>
            <div className="style-title"> <span onClick={showEditWindow}> <EditNoteOutlined style={{height : '25px', width : '25px', fontSize : '25px'}} /> {data.triggerName}</span><NodeActions completeNode={completeNode} /></div>
              <div className="style-contentWrapper content-wrapper"><ContentRenderer data={data} /></div>
          </div>
        </div>
    
      {completeNode?.data?.options?.length > 0 ?
       <Handle type="target" position={Position.Left} id="a" />
        :
        <>
         <Handle type="target" position={Position.Left} id="a" />
         <Handle type="source" position={Position.Right} id="b" />
        </>
      }
      </div>
    </>
  );
};

export default memo(Node);
