import React, {useEffect, useState} from "react";
import {Dropdown} from "react-bootstrap";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const Drop = () => {
    const [state, setState] = useState({
        selectedLanguage :""
    })
    const {t} = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };
    const languageOptions = [
        {
            id: 1,
            name: t('English'),
            language:"en",
            flag: require('../../../assets/media/flags/united-states.svg').default
        },
        {
            id: 2,
            name: t("Arabic"),
            language:"ea",
            flag: require('../../../assets/media/flags/saudi-arabia.svg').default
        },
        {
            id: 3,
            name: t('Spanish'),
            language:"es",
            flag:require('../../../assets/media/flags/spain.svg').default
        }
    ];

    let selectedOption = languageOptions.find(item=>item.language===state.selectedLanguage)

    useEffect(() => {
        setState({ selectedLanguage: i18n.language})
    }, []);

    return <div className="w-lg-500px d-flex flex-stack px-10 mx-auto">
        <div className="me-10" style={{gap: 10, display: "flex"}}>
            <Dropdown>
                <Dropdown.Toggle variant="link"
                                 className="btn-flex btn-color-gray-700 btn-active-color-primary rotate fs-base"
                                 id="dropdown-basic">
                    <img data-kt-element="current-lang-flag" className="w-20px h-20px rounded me-3"
                         src={selectedOption?.flag} alt=""/>
                    <span data-kt-element="current-lang-name"
                          className="me-1">{selectedOption?.name}</span>
                    <span className="d-flex flex-center rotate-180">
      <i className="ki-duotone ki-down fs-5 text-muted m-0"></i>
    </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {languageOptions.map((option) => (
                        <Dropdown.Item key={option.id}  onClick={()=> {
                            setState({...state, selectedLanguage: option.language})
                            changeLanguage(option.language)
                        }} >
                            <img data-kt-element="current-lang-flag" className="w-20px h-20px rounded me-3"
                                 src={option.flag} alt=""/>
                            <span data-kt-element="current-lang-name" className="me-1">{option.name}</span>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
        <div className="d-flex fw-semibold text-primary fs-base gap-5">
            <div style={{color:"rgb(4, 180, 64)"}}>{t("Terms")}</div>
            <div style={{color:"rgb(4, 180, 64)"}}>{t('Plans')}</div>
            <div style={{color:"rgb(4, 180, 64)"}}>{t('Contact-Us')}</div>
        </div>
    </div>
}
export default Drop