import axios from "../../../../Authentication/headers";


const templateList = async () =>{
    const response = await axios.get('/templateFlow/dropdown')
    return response.data
 }
  
const apiList = async () =>{
    const response = await axios.get('/api/dropdown')
    return response.data
}

const formList = async () =>{
    const response = await axios.get('/form/dropdown')
    return response.data
}

const uploadFile = async (file)=>{
    const formData = new FormData();
    formData.append('media', file)
    const response = await axios.post('/flow/addMedia', formData)
    return response?.data?.data || null;
}

const deleteFile = async (fileKey)=>{
    const response = await axios.get(`/flow/deleteMedia/${fileKey}`)
    return response?.data?.data || null;
}


export {templateList, apiList, formList, uploadFile, deleteFile}