import {Flex, Progress} from "antd";
import React from "react";
import '../../components/overview/overview.scss'

const twoColors = {
    '0%': '#108ee9',
    '100%': '#87d068',
};
const conicColors = {
    '0%': '#87d068',
    '50%': '#ffe58f',
    '100%': '#ffccc7',
};
const ProgressBarVOC = (props) => {
    let {titleOne, titleTwo, valueOne, valueTwo, titleThree, valueThree} = props;
    return <div>
        <Flex vertical gap="middle">
            <Flex gap="small" wrap="wrap">
                <Progress style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin:'auto'
                }}
                          type="dashboard" percent={93} format={(percent)=>`${percent}`}
                          strokeColor={conicColors}/>
            </Flex>
        </Flex>
        <h6 style={{textAlign:'center'}}>Based on responses</h6>
        <div className={'progress-info-container'}>
            <div>
                <div className={'value'}>{valueOne}</div>
                <div className={'label'}>{titleOne}</div>
            </div>
            <div>
                <div className={'value'}>{valueTwo}</div>
                <div className={'label'}>{titleTwo}</div>
            </div>
            <div>
                <div className={'value'}>{valueThree}</div>
                <div className={'label'}>{titleThree}</div>
            </div>
        </div>
    </div>

};

export default ProgressBarVOC