import Cards from "../reuseableComponents/Cards";
import {Button, Checkbox, Modal, Table, Tabs} from "antd";
import React, {useState} from "react";
import {ProgressBar} from "react-bootstrap";
import ProgressChart from "../overview/ProgressChart";
import DoughnutComponent from "../../../ResuseableComponnets/Charts/Doughnut ";
import BarChart from "../../../Reports/Barchart";
import AgentsFilterTable from "../AgentPerformance/AgentsFilterTable";
import Line from "../../../ResuseableComponnets/Charts/Line";
import ProgressBarVOC from "./ProgressBar";
import './VoiceOfCustmer.scss';

const VoiceOfCustomer = (props) => {
    const [state, setState] = useState({
       fd:false,
        fc:false,
        mode: '',
        whatsapp: '',
        instagram: '',
        messenger: '',
        poor: false,
        avg: false,
        great: false
    })
    let {title, value, isModalOpen, mode, modalOpenFunc} = props
    const modalFooter = [
        <Button
            key="cancel"
            onClick={() => modalOpenFunc()}
        >
            Cancel
        </Button>,
        <Button
            key="apply"
            onClick={() => modalOpenFunc()}
            primary
            style={{display: mode === 'export' ? 'none' : ''}}
        >
            Apply
        </Button>
    ];
    const items = [
        {
            key: '1',
            label: 'Channels',
            children: <div>
                <div style={{marginTop:6}}/>
                <Checkbox
                    checked={state.whatsapp}
                    onChange={()=>setState({...state, whatsapp: !state.whatsapp})}
                >Whatsapp</Checkbox>
                <div style={{marginTop:4}}/>
                <Checkbox
                    checked={state.messenger}
                    onChange={()=>setState({...state, messenger: !state.messenger})}
                >Messenger</Checkbox>
                <div style={{marginTop:4}}/>
                <Checkbox
                    checked={state.instagram}
                    onChange={()=>setState({...state, instagram: !state.instagram})}
                >Instagram</Checkbox>
                <div style={{marginTop:4}}/>
            </div>,
        },
        {
            key: '2',
            label: 'Agents',
            children:<AgentsFilterTable/>,
        },
        {
            key: '3',
            label: 'Score',
            children: <div>
                <div style={{marginTop:6}}/>
                <Checkbox
                    checked={state.poor}
                    onChange={()=>setState({...state, poor: !state.poor})}
                >Poor</Checkbox>
                <div style={{marginTop:4}}/>
                <Checkbox
                    checked={state.avg}
                    onChange={()=>setState({...state, avg: !state.avg})}
                >Average</Checkbox>
                <div style={{marginTop:4}}/>
                <Checkbox
                    checked={state.great}
                    onChange={()=>setState({...state, great: !state.great})}
                >Great</Checkbox>
                <div style={{marginTop:4}}/>
            </div>,
        }
    ];


    const SurveysColumns = [
        {
            title: 'Customers',
            dataIndex: 'customers',
            key: '1',
        },
        {
            title: 'Conversation',
            dataIndex: 'conversation',
            key: '2',
        },
        {
            title: 'Identifier',
            dataIndex: 'identifier',
            key: '3',
        },
        {
            title: 'Score',
            dataIndex: 'surveyed',
            key: '4',
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            key: '5',
        }
    ];
    const AgentsAvailibilityData = [];

    const feeddata = {
        labels: ['January', 'February', 'March', 'April', 'May'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [65, 59, 80, 81, 56],
                borderColor: 'red',
                borderWidth: 1,
                fill: false
            },
            {
                label: 'Dataset 2',
                data: [28, 48, 40, 19, 86],
                borderColor: 'blue',
                borderWidth: 1,
                fill: false
            },
            {
                label: 'Dataset 3',
                data: [2, 32, 10, 22, 16],
                borderColor: 'green',
                borderWidth: 1,
                fill: false
            }
            // Add more datasets as needed
        ]
    };

    const data = {
        labels: ['January', 'February', 'March', 'May', 'June'],
        datasets: [
            {
                data: [65, 59, 80, 81, 56],
                backgroundColor: '#10163a', // Bar color
                borderWidth: 1, // Border width,
                width:'70%'
            },
        ],
    };

    return <div>
        <div className={'agents-availability-container'}>
            <div className={'cards-box voc-progress-bar'} style={{width: '30%'}}>
                <h6>CSAT Breakdown By Response</h6>
                <ProgressBarVOC
                    titleOne={'Poor'}
                    valueOne={'1'}
                    titleTwo={'Average'}
                    valueTwo={'0'}
                    titleThree={'Good'}
                    valueThree={'0'}
                />
            </div>
            <div className={'cards-box'} style={{width: '70%'}}>
                <h6>Performance</h6>
                <Line height={268} data={feeddata}/>
            </div>
        </div>
        <div className={'cards-box'} style={{marginTop: 10}}>
            <h6>Surveys</h6>
            <Table
                columns={SurveysColumns}
                dataSource={AgentsAvailibilityData}
                style={{marginTop: 10}}
            />
        </div>

        <div style={{marginTop: 10}} className={'cards-box'}>
            <BarChart height={210} data={data} heading={'Feedback'} />
        </div>
        <Modal
            title={mode === 'export' ? 'Export Insights' : mode === 'export' && 'Filter Agent Performance'}
            open={isModalOpen}
            onCancel={() => {
                modalOpenFunc()
                setState({
                    ...state,
                    fc: false,
                    fd: false,
                    whatsapp: false,
                    instagram: false,
                    messenger: false
                })
            }
            }
            footer={modalFooter}
            closable={() => {
                modalOpenFunc()
                setState({
                    ...state,
                    fd: false,
                    fc: false,
                    whatsapp: false,
                    instagram: false,
                    messenger: false
                })
            }}
        >
            {
                mode === 'export' ?
                    <div>
                        <div style={{fontWeight: '550'}}>Include breakdown by</div>
                        <div style={{marginTop: 6}}/>
                        <Checkbox
                            checked={state.fd}
                            onChange={() => setState({...state, fd: !state.fd})}
                        >Feedback Details</Checkbox>
                        <div style={{marginTop: 4}}/>
                        <Checkbox
                            checked={state.fc}
                            onChange={() => setState({...state, fc: !state.fc})}
                        >Feedback Chart</Checkbox>
                    </div> : mode === 'filter'
                    && <Tabs
                        defaultActiveKey="1"
                        tabPosition={'top'}
                        items={items}
                    />
            }
        </Modal>
    </div>
}

export default VoiceOfCustomer;