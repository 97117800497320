import React, {useEffect, useState} from 'react';
import Inbox from "../Inbox/Inbox";
import WhatsappImage from "../../assets/svg/social-logos/whatsappLogo.png";
import {getBotChats, getBotMessageById} from "./Apis";
import {useDispatch, useSelector} from 'react-redux';

const BotInbox = () => {
    let conversations = useSelector(state => state.inbox.allWhatsappConversations)

    let dispatch = useDispatch()
    useEffect(() => {
        getBotChats().then(res=> {
            dispatch({
                type:"GET_ALL_WHATSAPP_CONVERSATIONS",
                payload:res.data.data
            })
        })
            .catch(err=>console.log(err))
    }, []);
    return <Inbox
        conversations={conversations}
        getChat={getBotMessageById}
        channel={"whatsapp"}
        channelLogo={WhatsappImage}
        isBotInbox={true}
    />
}
export default BotInbox