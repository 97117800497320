import React, { useRef, useState ,useEffect} from 'react';
import { Button, Input, Space, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { ChannelListApi , profileInformation } from "./Api/Api" 
import Loader from "../../ResuseableComponnets/Loader";
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader';
import {BsFillBuildingsFill} from "react-icons/bs";




const Information = ({ id }) => {
  const [state, setState] = useState({
    AllData: [],
    loading: true,
  });
  
  console.log(id,8888)

  const fetchData = () => {
    setState({ ...state, loading: true });
    profileInformation()
      .then(response => {
        setState(prevState => ({
          ...prevState,
          loading: false,
          AllData:response.data.data
        }));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setState(prevState => ({ ...prevState, loading: false }));
      });
  };
  
  
  

useEffect(() => {
  fetchData();
}, []);






  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex )}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
             
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

 const columns = [
  {
    title: "Name",
    dataIndex: 'name',
    key: 'name',
    ...getColumnSearchProps('name'),
  },
  {
    title: "Channel",
    dataIndex: 'channelType',
    key: 'channelType',
  },
  {
    title: "Verified Name",
    dataIndex: ['profile', 'verified_name'],
    key: 'verified_name',
  },
  {
    title: "Status",
    dataIndex: ['profile', 'code_verification_status'],
    key: 'code_verification_status',
  },
  {
    title: "numbers",
    dataIndex: ['profile', 'display_phone_number'],
    key: 'display_phone_numbers',
  }
];


  return (
    <div className='synctic-module-container'>
       <SettingsHeader
        icon={<BsFillBuildingsFill className='icon'/>}
        heading="Profile Information"
        description={t("Manage-Your-WorkSpace-Settings")}
            />
    {state.loading ? (
      <Loader />
    ) : (
      <Table
        columns={columns}
        dataSource={state.AllData}
        style={{marginTop:"10px"}}
      />
    )}



    
  </div>
  );
};

export default Information;
