import { Form } from 'antd';
import { Select } from 'antd';
import React from 'react';
const { Option } = Select;


export default function CustomSelectBox(props) {
    return (
           <Form.Item
            name={props.name}
            className='style-select'
            // rules={[
            //     {
            //         required: true,
            //         message: message,
            //     },
            // ]}
        >
            <Select
                showSearch
                placeholder={'Select'}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                className="custom-select"
                {...props}
                >
                {
                    props.options && props.options.length > 0 && props.options.map((val, key) => {
                        if (val) return <Option value={val.value} key={key}>{val.label}</Option>
                    })
                }

            </Select>
        </Form.Item>    
    )
}