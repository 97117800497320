export default function MediaRenderer({ type, url, showMedia }) {
    if (type === 'image') {
      return<img src={url} alt="Image" />;
    } else if (type === 'document') {
      // Render a link to the document
      return <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>;
    } else if (type === 'audio') {
      return (
        <audio controls>
          <source src={url} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      );
    } else if (type === 'video') {
      return (
            <video controls>
                <source src={url} type="video/mp4" />
                Your browser does not support the video element.
            </video> 
      );
    }
    else {
      return <p className="label-text text-center">Invalid media type</p>;
    }
  }
  