import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement} from 'chart.js'
import { useTranslation } from 'react-i18next';
Chart.register(ArcElement);
const ConvPerAgentChart = (props) => {
    const { t } = useTranslation();
    const data = {
        labels: [t("Red"), t("Blue"), t("Yellow")],
        datasets: [
            {
                data: [300, 50, 100],
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            },
        ],
    };

    const options = {
        // Add any chart options here
    };
    return <>
        {
            // props?.analytics ?
                <Doughnut style={{margin:"auto"}} height="200px" width="200px" data={data} options={options} />
                // :
                // <Doughnut style={{margin:"auto"}} height="250px" width="250px" data={data} options={options} />
        }
            </>
}

export default ConvPerAgentChart;