import React from "react";
import "./Reports.scss";
import ReportsStats from "./ReportsStats";
import { useTranslation } from 'react-i18next';


const Reports = () => {
    const { t } = useTranslation();

    return <div className="reports-main synctic-module-container">
        <div>
            <div className="cards-box">
            <div className="reports-heading">{t("Conversation-Reports")}</div>
            <div className="reports-tabs-parent">
                <div className="reports-tabs-sub-parent">
                    <p className="reports-tabs" style={{background:"rgba(255, 165, 0, .15)"}}>{t("Custom")}</p>
                    <p className="reports-tabs">{t("Yesterday")}</p>
                    <p className="reports-tabs">7D</p>
                    <p className="reports-tabs">30D</p>
                    <p className="reports-tabs">3M</p>
                    <p className="reports-tabs">6M</p>
                    <p className="reports-tabs">12M</p>
                </div>
                {/*<div className="reports-tabs-sub-parent">*/}
                {/*    <div className="right-elem"><div>All Channels</div><KeyboardArrowDownIcon style={{width:18}}/></div>*/}
                {/*    <div className="right-elem"><div>All Tags</div><KeyboardArrowDownIcon style={{width:18}}/></div>*/}
                {/*    <div className="right-elem"><div>All Agents</div><KeyboardArrowDownIcon style={{width:18}}/></div>*/}
                {/*</div>*/}
            </div>
            </div>
            {/*<hr style={{marginTop:"-7px", opacity:.5}}/>*/}
            <div className="overveiw">{t("Overview")}</div>
            <div style={{fontSize:12, opacity:.7, marginLeft:"3%"}}>{t("How-are-you-performing-on-conversations")}</div>
            <ReportsStats/>
        </div>
    </div>
}

export default Reports;