import React from 'react';
import {Flex, Spin} from 'antd';

const Loader = (props) => {
    return <div
        style={{width: props?.width ? props.width : "100%", height: props?.height ? props?.height : "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 20
        }}><Flex align="center" gap="middle">
            <Spin size="large"/>
        </Flex>
            <h3 style={{opacity: .7}}>{props?.message}</h3>
        </div>
    </div>
}
export default Loader