import React from 'react';
const Border = (props) => {
    const { style } = props;
  return (
    <div className='main-container-border'  >
    <div className='main-border-x1'>
      <div className='mainBorderStyle'>
        <div className='iconstyle'>{props.icon}</div> 
        <div className='headingStyle'>{props.defaultHeading}</div>
      </div>

      <div className='seacond-sub-heading'>
        <div className='secondaryHeadingStyle'>{props.secondaryHeading}</div>
        <p className='descriptionStyle'>{props.description}</p>
      </div>
    </div>
    </div>
  );
}

export default Border;
