import React, { memo, useState } from "react";
import { Handle, Position } from "reactflow";
import { useNodes } from "../../allnode";
import NodeActions from "../hooks/nodeAction";

const Node = (completeNode) => {
  const [editMessage, setEditMessage] = useState(false)

  const data = completeNode?.data;
  const selected = completeNode?.selected;

  const editMessageFn = () =>{
    setEditMessage((val) => val === true ?  false :  true)
  }

  return (
    <>
      <div className={`condition-opt-node text-updater-node flow-node ${Boolean(data.label) === true ? 'green-box' : 'red-box' }`}>
          <div className="style-contentWrapper" onClick={editMessageFn}>{data.content}</div>
          <Handle type="source" position={Position.Right} id="b" className="node-pointer" />
      </div>
    </>
  );
};

export default memo(Node);
