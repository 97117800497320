import React, { useEffect } from 'react';
import io from 'socket.io-client';
import ActionsCreators from "./Modules/Inbox/states";
import {useDispatch} from "react-redux";

const socket = io('54.75.100.43:3009');
const InstagramSockets = () => {
    const dispatch= useDispatch()
    useEffect(() => {
        socket.on('connect', () => {
            console.log('connected to server');
        })

        socket.on('new_insta_message', (message) => {
            {dispatch(ActionsCreators.updateInstagramConversation(message))}
        })

        socket.on('disconnect', () => {
            console.log('Socket disconnecting');
        })

    }, []);
};

export default InstagramSockets;