import React, {useEffect, useState} from 'react';
import "./TeamInfo.scss";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Select } from 'antd';
import {getAllTeams, createTeam, allAccounts, updateTeam, getTeam, deleteTeam} from "./API/API";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import AntDTable from "../../ResuseableComponnets/Table";
import {getAccount} from "../../API/AccountApis";
import { useTranslation } from 'react-i18next';
import TextField from "@mui/material/TextField";


const TeamInfo = (props) => {
    const [state, setState] = useState({
        users: [],
        isOpen:false,
        allTeams:[],
        allOptions:[],
        tableData:[],
        teamName:"",
        isDialogue:false,
        teamId:"",
        lastIndex:[],
        prevName:""
    })
    const { t } = useTranslation();

    const removeUserFromArray = (id) => {
        let data = state.tableData.filter(item => item.id !== id);
        setState({...state, tableData: data})
    }
    const DeleteTeamFunc = () => {
        deleteTeam(state.teamId)
            .then(res=> {
                    setState({...state, isDialogOpen: false})
                props.onDelete()
                }
            )
            .catch(err=>console.log(err, "err"))
    }
    const getSpecificTeam = () => {
        getTeam(props.teamId)
            .then(res=>{
                let data=res.data.data
                setState(prevState => ({
                    ...prevState,
                    teamName: data.name,
                    tableData:data.users,
                    teamId:data.id,
                    users: data.users.map(item=>item._id)
                }))
            })
            .catch(err=>console.log(err, "Error..."))
    }
    console.log(state.users, "3333331111")
    useEffect(() => {
        allAccounts().then(res=>{
            let responseData = res.data.data;
            setState(prevState => ({...prevState, allOptions: responseData}))

        }).catch(error=>console.log(error, "error"))
        getSpecificTeam()
    }, []);
    return <>
<div className="teamDescriptionTop cards-box" style={{width:"70%"}}>
<div className="teamHeading headinggg">
    <ArrowBackIcon onClick={()=>props.back()}/>
    <div className="header_input">
    <TextField
        fullWidth
        size="small"
        value={state.teamName}
        onChange={(e)=>setState({...state, teamName: e.target.value})}
    />
    </div>
</div>
    <div>
        <Button onClick={()=>{
            let data = {
                name:state.teamName,
                users: state.users,
                description:"",
            }
            updateTeam(props.teamId, data).then(res=>getSpecificTeam()).catch(error=>console.log(error))
        }}>
            Update
        </Button>
    <Button onClick={()=>{
        setState({...state, isDialogue: true})
    }} variant="contained" startIcon={<DeleteIcon />}>
        {t("Delete")}
    </Button>
    </div>
</div>
        <div className="teamHeading" style={{marginTop:"2%", fontSize:18}}>
            {t("Channel-Visibility")}
        </div>
    <div className="teamHeading" style={{marginTop:"1.5%", fontSize:16}}>
        {t("Team-users")}
    </div>
        <div className="teamHeading" style={{marginTop:"1.5%", fontSize:16, color:"#2563eb"}}>
            {t("Add-Users")}
        </div>
        <Select
            mode="multiple"
            placeholder={t("Inserted-are-removed")}
            value={state.users}
            onChange={ (e)=> {
                e.length > 0 && setState({...state, users: e, lastIndex:e.length > 0 ? e[0] : state.lastIndex })
                let data = {
                    name:state.teamName,
                    users: e.length > 1 ? e : [state.lastIndex],
                    description:"",
                }
                updateTeam(props.teamId, data).then(res=>getSpecificTeam()).catch(error=>console.log(error))

            }}
            style={{ width: '70%', marginTop:"1.5%" }}
            options={state.allOptions.length > 0 && state.allOptions.filter((o) => !state.users.includes(o.email)).map((item) => (
                {
                    value: item.id,
                    label: item.email,
                }
            ))}
        />

        <Dialog
            open={state.isDialogue}
            onClose={()=>setState({...state, isDialogue: false})}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
               Are you sure to delete the team
            </DialogTitle>
            <DialogActions>
                <Button onClick={()=>setState({...state, isDialogue: false})}>Close</Button>
                <Button onClick={()=> DeleteTeamFunc()
                } autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
        <AntDTable tableData={state.tableData} teamId={state.teamId} />
</>
}

export default TeamInfo