import React from 'react'
import "./Saved.scss"
import Button from '@mui/material/Button';
import {BiSolidMessageRoundedDots} from "react-icons/bi";
import {AiOutlineFolderAdd} from "react-icons/ai";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Saved_table from './Saved_table';
import { useTranslation } from 'react-i18next';

const Saved_replies = () => {
    const { t } = useTranslation();

    return (
        <div className="synctic-module-container">
            <div className="cards-box" style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <div>
                    <div className='synctic-module-def'>
                        <BiSolidMessageRoundedDots className='icon'/>
                        <div className="heading">{t("Saved-Replies")}</div>
                        <p className="sub-heading">{t("Use-Saved-Replies-discription")}</p>
                    </div>
                </div>
                <div className='main-Channels'>
                    <div className='Attribute-container'>
                    <Button variant="contained">
                        <BiSolidMessageRoundedDots className='RiContactsFill'/>
                        Add Attribute
                    </Button>
                    </div>
                </div>
            </div>
            <div className='your-folder'>
                <div className='your-folder-icon'>
                    <div className='Your-folder-text'>Your folder</div>
                    <AiOutlineFolderAdd style={{fontSize: "31px", opacity: ".5"}}/>
                </div>
                <div className='MoreHorizIcon'>
                    <MoreHorizIcon/>
                </div>
            </div>
            <Saved_table/>

        </div>
    )
}

export default Saved_replies
