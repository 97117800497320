import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import React, { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {deleteTeam, getTeam} from "../Teams/API/API";
import { useTranslation } from 'react-i18next';
const Tags_table = (props) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [state, setState]=useState({
        teamName:"",
        allData:[]
    })

    let updatedArray =  props.columns.map((obj) => ({
        ...obj,  // Copy the existing object properties
        delete: <div style={{display:"flex", gap:10, opacity:.7}}> <RemoveRedEyeIcon onClick={()=> handleRowClick(obj.id)}/> <RemoveCircleIcon onClick={()=>
            props.onDeleteTeam(obj.id)
        } /></div>
    }));
    const { t } = useTranslation();

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const handleRowClick = (id) => {
        props.selectedTeam(id)
    };
    const columns = [
        {
            title: t("title-Name"),
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            ...getColumnSearchProps('name'),
        },
        {
            title: t("title-Status"),
            dataIndex: 'status',
            key: 'status',
            width: '20%',
            ...getColumnSearchProps('status'), // Assuming this is a search-related functionality
            render: (status) => (status ? 'true' : 'false'),
        },
        {
            title: t("title-Members"),
            dataIndex: 'users',
            key: 'users',
            ...getColumnSearchProps('users'),
            sorter: (a, b) => a.address.length - b.address.length,
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => record.users.length,
        },
        {
            title: t("title-Actions"),
            dataIndex: 'delete',
            key: 'delete'
        }
    ];
    return <Table
        // onRow={(record, rowIndex) => ({
        //     onClick: () => {
        //             handleRowClick(record);
        //     }
        // })}
        style={{marginTop:"2%"}} columns={columns} dataSource={updatedArray} />;
};
export default Tags_table;