import React from "react";
import axios from "../../../Authentication/headers"

export function getAllQuickReply(){
    return axios.get(`/quickReply/all`)
}
export function saveQuickReply(data){
    return axios.post(`/quickReply/create`, data)
}

export function activateQuickReply(id){
    return axios.get(`/quickReply/activate/${id}`)
}

export function deActivateQuickReply(id){
    return axios.get(`/quickReply/deactivate/${id}`)
}

export function deleteQuickReply(id){
    return axios.get(`/quickReply/delete/${id}`)
}

export function updateQuickReply(id, data){
    return axios.put(`/quickReply/update/${id}`, data)
}

