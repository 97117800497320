import React from 'react';
import './WhatsappInsights.scss';

const WhatsappCard = (props) => {
    return <div className={'cards-box'}>
        <div style={{width: '100%'}}>

            {
                props.data.map((item, idx) =>
                    <div className={'whatsapp-card-data'} style={{marginTop:4}}>
                        <div style={{fontWeight:idx===0 && 550}}>
                            {item.key}
                        </div>
                        <div style={{textAlign:'end', fontWeight:idx===0 && 550}}>
                            {item.value}
                        </div>
                    </div>)
            }

        </div>
    </div>
}

export default WhatsappCard