import React, {useState} from 'react';
import Cards from "../reuseableComponents/Cards";
import '../overview/overview.scss';
import './AgentPerformance.scss';
import {Checkbox, Modal, Button, Table, Tabs} from "antd";
import AgentsFilterTable from "./AgentsFilterTable";

const AgentPerformance = (props) => {
    const [state, setState] = useState({
        tCustomers: false,
        mac: false,
        mode:'',
        whatsapp:'',
        instagram:'',
        messenger:''
    })
    let {title, value, isModalOpen, mode, modalOpenFunc} = props
    const modalFooter = [
        <Button
            key="cancel"
            onClick={() => modalOpenFunc()}
        >
            Cancel
        </Button>,
        <Button
            key="apply"
            onClick={() => modalOpenFunc()}
            primary
        >
            Apply
        </Button>
    ];
    const AgentsAvailibilityColumns = [
        {
            title: 'Agent Name',
            dataIndex: 'agentName',
            key: '1',
        },
        {
            title: 'Login ID',
            dataIndex: 'loginId',
            key: '2',
        },
        {
            title: 'Login Time',
            dataIndex: 'loginTime',
            key: '3',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: '4',
        }
    ];
    const AgentsBoardColumns = [
        {
            title: 'Agent',
            dataIndex: 'agent',
            key: '1',
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            key: '2',
        },
        {
            title: 'Accepted',
            dataIndex: 'accepted',
            key: '3',
        },
        {
            title: 'Solved',
            dataIndex: 'solved',
            key: '4',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: '5',
        },
        {
            title: 'Avg. Response Time',
            dataIndex: 'avgResponseTime',
            key: '6',
        },
        {
            title: 'Avg. Resolution Time',
            dataIndex: 'avgResolutionTime',
            key: '7',
        }
    ];


    const AgentsAvailibilityData = [];
    const items = [
        {
            key: '1',
            label: 'Channels',
            children: <div>
                <div style={{fontWeight: '550'}}>Channel</div>
                <div style={{marginTop:6}}/>
                <Checkbox
                    checked={state.whatsapp}
                    onChange={()=>setState({...state, whatsapp: !state.whatsapp})}
                >Whatsapp</Checkbox>
                <div style={{marginTop:4}}/>
                <Checkbox
                    checked={state.messenger}
                    onChange={()=>setState({...state, messenger: !state.messenger})}
                >Messenger</Checkbox>
                <div style={{marginTop:4}}/>
                <Checkbox
                    checked={state.instagram}
                    onChange={()=>setState({...state, instagram: !state.instagram})}
                >Instagram</Checkbox>
                <div style={{marginTop:4}}/>
            </div>,
        },
        {
            key: '2',
            label: 'Agents',
            children:<AgentsFilterTable/>,
        },
    ];


    return <div>
        <div className='count-card-container'>
            <Cards title={'Assigned'} value={'1'}/>
            <Cards title={'In Progress'} value={'5'}/>
            <Cards title={'Solved By Agent'} value={'22'}/>
            <Cards title={'Messages Sent'} value={'2,443'}/>
            <Cards title={'Avg. Response Time'} value={'0 hr 0 mins'}/>
            <Cards title={'Avg. Resolution Time'} value={'0 hr 0 mins'}/>
        </div>
        <div className={'agents-availability-container'}>
            <div className={'cards-box'} style={{ width: '22%' }}>
                <h6>Agents Availability</h6>
            </div>
            <div className={'cards-box'} style={{ width: '78%' }}>
                <h6>Agents Availability</h6>
                <Table
                    columns={AgentsAvailibilityColumns}
                    dataSource={AgentsAvailibilityData}
                    style={{
                        marginTop: 24,
                    }}
                />
            </div>
        </div>
        <div className={'cards-box'} style={{
            width: '100%',
            marginTop:10
        }}>
            <h6>Agents Board</h6>
            <Table
                columns={AgentsBoardColumns}
                dataSource={AgentsAvailibilityData}
                style={{
                    marginTop: 24,
                }}
            />
        </div>
        <Modal
            title={ mode==='export' ? 'Export Insights' : mode==='export' && 'Filter Agent Performance'}
            open={isModalOpen}
            onCancel={() => {
                modalOpenFunc()
                setState({...state,
                    mac: false,
                    tCustomers: false,
                    whatsapp: false,
                    instagram: false,
                    messenger: false
                })
            }
        }
            footer={modalFooter}
            closable={()=> {
                modalOpenFunc()
                setState({
                    ...state,
                    mac: false,
                    tCustomers: false,
                    whatsapp: false,
                    instagram: false,
                    messenger: false
                })
            }}
        >
            {
                state.mode==='export' ?
                    <div>
                        <div style={{fontWeight: '550'}}>Include breakdown by</div>
                        <div style={{marginTop:6}}/>
                        <Checkbox
                            checked={state.mac}
                            onChange={()=>setState({...state, mac: !state.mac})}
                        >Agent Board Summary</Checkbox>
                        <div style={{marginTop:4}}/>
                        <Checkbox
                            checked={state.tCustomers}
                            onChange={()=>setState({...state, tCustomers: !state.tCustomers})}
                        >Agent Board Details</Checkbox>
                        <div style={{marginTop:4}}/>
                        <Checkbox
                            checked={state.tCustomers}
                            onChange={()=>setState({...state, tCustomers: !state.tCustomers})}
                        >Agent Away Logs</Checkbox>
                        <div style={{marginTop:4}}/>
                        <Checkbox
                            checked={state.tCustomers}
                            onChange={()=>setState({...state, tCustomers: !state.tCustomers})}
                        >Agent Login Logs</Checkbox>
                    </div> : mode==='filter'
                    && <Tabs
                        defaultActiveKey="1"
                        tabPosition={'top'}
                        items={items}
                    />
            }
        </Modal>
    </div>

}

export default AgentPerformance;