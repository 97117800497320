import React from 'react'
import "./User-roles.scss"
import { FcSettings } from "react-icons/fc";
import { RiContactsFill } from "react-icons/ri";
import {AiOutlinePlus} from "react-icons/ai";
import Button from '@mui/material/Button';
import User_input from './User_input';
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import Border from '../../ResuseableComponnets/Border';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';


const User_roles = () => {
  const Ismobile = useMediaQuery({maxWidth: 767});
  const { t } = useTranslation();

  return (
    <>    <div className="synctic-module-container">
    <div className="cards-box">
              <div>
                  <div className='synctic-module-def'>
                      <RiContactsFill className="icon" />
                      <div className="heading">{t("User-And-Roles")}</div>
                      <div className="sub-heading">{t("Assign-Roles-description")}</div>
                  </div>
              </div>
          </div>

<div className='Create-New-Role'>
<Button  style={{ marginTop: "4%", width: "20%", left: "80%" /* float:"right" */}} variant="contained" ><AiOutlinePlus style={{ marginRight: "5%" }} />{t("Create-New-Role")}</Button>
</div>
<div className='border-setting-user'>

<Border
  icon={<FcSettings />}
  defaultHeading={t("Default")}
  secondaryHeading={t("Admin")}
  description={t("Admin-description")}
/>

<Border
  icon={<RiContactsFill />}
 defaultHeading={t("Default")}
  secondaryHeading={t("Member")}
  description={t("Admin-description")}
/>
</div>

<User_input/>
    </div>
    </>

  )
}

export default User_roles
