const generateLoopBack = async (nodes, edges, currentNode) => {
  const loopbackList = [];
  let targetId = currentNode.id;
  for (let i = 0; i < edges.length; i++) {
    const edge = edges.find(edge => edge.target === targetId)

    if(edge){
      const edgeOption = edge.source.replace(/\d/g, '');
      if (edgeOption === 'option_') {
        const findNode = nodes.find(node => node.id === edge.source);
        if (findNode) {
          targetId = findNode.parentNode;
        } 
      } else {
        targetId = edge.source;
      }
    }
  

    const checkIfNotExist = loopbackList.find(node => node.value === targetId)
    if(!checkIfNotExist){
      const nodeData = await getNodeData(nodes, targetId); // Wait for node data
      if (!nodeData) {
        // Handle missing nodeData error
        console.error(`Node data for ID ${targetId} not found`);
      }
      loopbackList.push(nodeData);
    }
    
  }

  return loopbackList;
};

const getNodeData = async (nodes, id) => {
  try {
    const node = nodes.find(node => node.id === id);
    console.log(node)
    if (!node) {
      throw new Error(`Node with ID ${id} not found`);
    }
    return {
      value: node.id,
      label: node.data.triggerName
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default generateLoopBack;
