import {SearchOutlined} from '@ant-design/icons';
import {Button, Input, Modal, Space, Switch, Table} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';


const Table_contacts = (props) => {
    const [state, setState] = useState({
        isGoingToInfo: false,
        checkedList: []
    })
    const [pagination, setPagination] = React.useState({
        current: 1,
        pageSize: 8,
    });


    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = props.columns.length > 0 && props.columns.map((item, idx) => ({
        title: item.charAt(0).toUpperCase() + item.slice(1),
        dataIndex: item,
        key: `${idx + 1}`,
        ...getColumnSearchProps(item)
    }));
    const defaultCheckedList = columns.length > 0 && columns.map((item) => item.key);
    const newColumns = columns.length > 0 && columns.map((item, idx) => ({
        ...item,
        key: `${idx + 1}`,
        hidden: !!state?.checkedList.length > 0 && !state.checkedList.includes(item.key)
    }));
    const options = columns.length > 0 && columns.map((item, idx) => ({
        label: item.title,
        value: `${idx + 1}`
    }))

    useEffect(() => {
        console.log(defaultCheckedList, 666666244101)
        defaultCheckedList?.length > 0 && setState({
            ...state,
            checkedList: defaultCheckedList
        })
    }, []);
    console.log(state.checkedList, 66662455551)
    return <div style={{overflow: 'scroll'}}>

        <Modal
            title={'Filter'}
            open={props.filterModal}
            onOk={() => props.closeFilterModal()}
            okText={'Done'}
            onCancel={() => props.closeFilterModal()}
        >
            {
                options.length > 0 && options.map((item, idx) => <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginTop: 10
                        }}>
                        <span style={{marginRight: '8px'}}>{item.label}</span>
                        <Switch
                            disabled={item.label==='Actions'}
                            defaultChecked
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            onChange={() => {
                                let index = state.checkedList.indexOf(item.value);
                                console.log(state.checkedList, index, 55555331113)
                                if (index === -1) {
                                    let newList = [...state.checkedList];
                                    newList.push(`${idx + 1}`);
                                    setState({...state, checkedList: newList});
                                } else {
                                    let newList = [...state.checkedList];
                                    newList.splice(index, 1);
                                    setState({...state, checkedList: newList});
                                }
                            }}
                        />
                    </div>
                )
            }
        </Modal>
        <Table
            // onRow={(record, rowIndex) => ({
            //     onClick: () => {
            //         handleRowClick(record);
            //     }
            // })}
            pagination={pagination}
            onChange={(page) => {
                setPagination(page);
            }}
            style={{marginTop: 10}}
            columns={newColumns}
            dataSource={props?.tableData}
        />
    </div>
};
export default Table_contacts;