import React, { useState } from 'react';
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader';
import { FaWallet } from "react-icons/fa";
import "./Billing.scss";
import Button from '@mui/material/Button';
import { AiOutlineCheck } from "react-icons/ai";
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';


const Billing = () => {
  const { t } = useTranslation();

  const [isMonthlyBilling, setIsMonthlyBilling] = useState(true);

  const leftClick = () => {
    setIsMonthlyBilling(true);
  };

  const rightClick = () => {
    setIsMonthlyBilling(false);
  };

  const price = isMonthlyBilling ? "$400  " : "$3,936 ";
  const price2 = isMonthlyBilling ? t("Month") :  t("Year");

  const pricingPlans = [
    {
      name: "Grow",
      description: t("Support-your-customers-scale-your-team"),
      discount: isMonthlyBilling ? null : t("18% Off"),
      price: price,
      price2: price2,
      buttonText: t("Subscribe-btn"),
      features: [
        t("Everything-in-Start"),
        t("Rule"),
        t("View"),
        t("Broadcasts"),
        t("Bots"),
        t("Integrations"),
      ],
    },
    {
      name: "Scale",
      description: t("Support your customers"),
      discount: isMonthlyBilling ? null : t("15% Off"),
      price: t("Custom"),
      price2: price2,
      buttonText: t("Contact Us"),
      features: [
        t("Everything-is-Basic"),
        t("Everything-is-Premium"),
        t("Slas"),
        t("Priority-Customer"),
        t("Customer-Number-of-Team-Training"),
        t("Training-Season-and-Roadmap"),
      ],
    },
  ];


  return (
    <div className="synctic-module-container">
      <SettingsHeader
        icon={<FaWallet className='icon'/>}
        heading={t("Billing")}
        description={t("Manage-your-Workspace-plan")}
      />

      <div className='main-current-plan'>
        <div className='current-plan-text'>{t("Current-Plan")}</div>
        <Alert style={{marginTop:"1%"}} severity="error">{t("Trial-End")}</Alert>
      </div>

      <div className="form-box" style={{marginTop: "3%"}}>
        <div className="button-box">
          <div
            id="btn"
            style={{ left: isMonthlyBilling ? '0' : '110px' }}
          ></div>
          <button type="button" className="toggle-btn left" onClick={leftClick}>
            {t("Monthly-Billing")}
          </button>
          <button type="button" className="toggle-btn right" onClick={rightClick}>
            {t("Yearly-Billing")}
          </button>
        </div>
      </div>

      <div className="main-table-plan">
  {pricingPlans.map((plan, index) => (
    <div key={index} style={{  width: "50%"  }} className="cards-box">
      <div className="price-plan-text">
        <div className="grow">{t(plan.name)}</div>
        {plan.discount && <div className="isMonthlyBilling">{plan.discount}</div>}
        <p className="grow-p">{plan.description}</p>
        <div className="doller">
          <div className="doller-text">{plan.price}</div>
          <p className="doller-p">{plan.price2}</p>
        </div>
        <p className="per-every">{t("per-every-extra-user-included")}</p>
        <Button variant="contained">
          {plan.buttonText}
        </Button>
      </div>
      <hr style={{ marginTop: "8%", color: "#c3bebe" }}></hr>
      <div className="main-whats-include">
        <div className="main-whats-include-text">{t("WHAT-INCLUDED")}</div>
        {plan.features.map((feature, featureIndex) => (
          <div key={featureIndex} className="main-everyting">
            <AiOutlineCheck className='AiOutlineCheck'/>
            <p className="main-everyting-p">{feature}</p>
          </div>
        ))}
      </div>
    </div>
  ))}
</div>
    </div>
  )
}

export default Billing;
