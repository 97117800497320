import React from 'react';
import {Flex, Progress} from 'antd';
import './overview.scss';

const twoColors = {
    '0%': '#108ee9',
    '100%': '#87d068',
};
const conicColors = {
    '0%': '#87d068',
    '50%': '#ffe58f',
    '100%': '#ffccc7',
};
const ProgressChart = (props) => {
    let {heading, titleOne, titleTwo, valueOne, valueTwo} = props;
    return <div className={'cards-box'} style={{
        width: '100%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between'
    }}>
        <h6>{heading}</h6>
        <Flex vertical gap="middle">
            <Flex gap="small" wrap="wrap">
                <Progress style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
                          type="dashboard" percent={93}
                          strokeColor={conicColors}/>
            </Flex>
        </Flex>
        <div className={'progress-info-container'}>
            <div>
                <div className={'value'}>{valueOne}</div>
                <div className={'label'}>{titleOne}</div>
            </div>
            <div>
                <div className={'value'}>{valueTwo}</div>
                <div className={'label'}>{titleTwo}</div>
            </div>
        </div>
    </div>
};
export default ProgressChart;