import React from 'react'
import { IoSettings } from "react-icons/io5";
import HeaderDropDown from "./HeaderDropDown";
import './Header.scss';
import {useLocation} from 'react-router-dom';
import HelpIcon from "@mui/icons-material/Help";
import Notification from "../ResuseableComponnets/Notfication";
const Header = () => {
    const location = useLocation()
    return (
        ((location.pathname === '/') ||
            (location.pathname === '/signup') ||
            (location.pathname === '/account/add_password') ||
            (location.pathname === '/forgotPassword') ||
            (location.pathname!=='/chat-bot/settings' && location.pathname!=='/chat-bot/settings/createForm' &&
                location.pathname!=='/chat-bot' && !location.pathname.includes('/chat-bot/settings/editForm')  && location.pathname.includes('chat-bot')) ||
            (location.pathname === '/verify-email')) ?
            null :
            <div className='cards-box' style={{padding:16, margin:10, marginBottom:0}}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', alignItems:'center' }}>
                    <Notification/>
                    <HelpIcon style={{ fontSize: '20px', color:'grey' }} />
                    <IoSettings color={'grey'} style={{ fontSize: '20px' }} />
                    <HeaderDropDown />
                </div>
            </div>
    )
}

export default Header
