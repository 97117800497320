import React, { useEffect, useState } from "react";
import SettingsHeader from "../../ResuseableComponnets/SettingsHeader";
import { Switch, Button, Select } from "antd";
import "./Profilestyle.scss";
import {
  updateSettingApi,
  getlangall,
  GetAccountInfo,
} from "./Api/Api";
import { useSelector } from "react-redux";
import Upload from "./Uplaod";
import Loader from "../../ResuseableComponnets/Loader";

const My_profile = () => {
  const [state, setState] = useState({
    messageTranslation: { allow: false, language: "en" },
    enableNotifications: { desktop: false, sounds: false },
    notificationType: {
      newConversation: false,
      mentionedConversation: false,
      forwardedConversation: false,
      newMessageInOpenConversation: false,
    },
    selectedLanguage: "Please Select Language",
    Loader: false,
  });

  const Email = useSelector((state) => state?.me?.me?.email);
  const namedata = useSelector((state) => state?.me?.me?.firstName);
  const roledata = useSelector((state) => state?.me?.me?.role);
  const updatedata = useSelector((state) => state?.me?.me?.created);
  const DataId = useSelector((state) => state?.me?.me?.id);

  const updateState = () => {
    setState((prevState) => ({
      ...prevState,
      Loader: true,
    }));
    const updatedata = {
      messageTranslation: state.messageTranslation,
      enableNotifications: state.enableNotifications,
      notificationType: state.notificationType,
    };
    console.log(state.messageTranslation, 9999);

    updateSettingApi(updatedata).then((response) => {
      setState((prevState) => ({
        ...prevState,
        Loader: false,
      }));
      console.log(response);
    });
  };

  const GetSwitchData = () => {
    setState((prevState) => ({
      ...prevState,
      Loader: true,
    }));
    !!DataId &&
      GetAccountInfo(DataId)
        .then((res) => {
          const notificationType = res?.data?.data?.notificationType;
          const enableNotifications = res?.data?.data?.enableNotifications;
          const messageTranslation = res?.data?.data?.messageTranslation;

          setState((prevState) => ({
            ...prevState,
            enableNotifications: enableNotifications,
            notificationType: notificationType,
            messageTranslation: messageTranslation,
            Loader: false,
          }));
        })
        .catch(() => console.log());
  };

  const Allcountries = () => {
    getlangall().then((res) => {
      setState({ ...state, languageAllData: res.data.data });
    });
  };

  useEffect(() => {
    Allcountries();
    GetSwitchData();
  }, []);


  return (
    <>
      <div className="synctic-module-container">
        <SettingsHeader heading="My Profile" />
        {state.Loader ? (
          <Loader />
        ) : (
          <div className="cards-box" style={{ marginTop: "1%" }}>
            <div key="profile">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <Upload />
                </div>
                <div style={{ marginLeft: "5px" }}>
                  <div className="name">{namedata}</div>
                  <div className="email">{Email}</div>
                </div>
              </div>
            </div>

            <div className="main-role-data">
              <div className="role-fulid">
                <h4 className="role">Role</h4>
                <div className="discription-hed">
                  <p>Your digital connect is role: </p>
                  <span>{roledata}</span>
                </div>
              </div>

              <div className="role-fulid">
                <h4 className="role">Team</h4>
                <div className="discription-hed">
                  <p>You are assigned to the team: </p>
                  <span>No team assigned</span>
                </div>
              </div>

              <div className="role-fulid">
                <h4 className="role">Timezone</h4>
                <div className="discription-hed">
                  <p>You are located in timezone:</p>
                  <span style={{ textTransform: "uppercase" }}>
                    {updatedata}
                  </span>
                </div>
              </div>

              <div className="main-role-data">
                <div className="role-fulid">
                  <h4 className="role">Message Translation</h4>
                  <div className="discription-hed" style={{ marginTop: "1%" }}>
                    <div>
                      <Switch
                        size="small"
                        checked={state.messageTranslation.allow}
                        onChange={(checked) =>
                          setState({
                            ...state,
                            messageTranslation: {
                              ...state.messageTranslation,
                              allow: checked,
                            },
                          })
                        }
                      />
                    </div>
                    <span>Enable all translation for all incoming chats</span>
                  </div>
                </div>

                <div className="role-fulid">
                  <p style={{ opacity: ".7", marginTop: "2%" }}>
                    We will translate for all incoming Message to the Selected
                    languages
                  </p>
                  <Select value={state.messageTranslation.language} onChange={(e) => setState({
                            ...state,
                            messageTranslation: {
                                ...state.messageTranslation,
                                language: e,
                            }
                        })}>
                        {state?.languageAllData?.map((item) => (
                      <Select.Option
                        key={item.lang_code}
                        value={item.lang_code}
                      >
                        {item.lang}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div className="role-fulid" style={{ marginTop: "2%" }}>
                  <h4 className="role">Notification</h4>
                  <div>
                    <p>
                      Show desktop notification for incoming conversation, you
                      will need to configure your browser settings to allow
                      notification from
                      <br /> Tiebot,{" "}
                      <span style={{ color: "blue" }}>
                        How To configure browser notification
                      </span>
                    </p>
                  </div>

                  <Button style={{ marginTop: "2%" }}>Test Notification</Button>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "2%",
                    }}
                  >
                    <h4 className="role">Enable Notifications</h4>
                    <div
                      className="discription-hed"
                      style={{ marginTop: "1%" }}
                    >
                      <div>
                        <Switch
                          size="small"
                          checked={state.enableNotifications.desktop}
                          onChange={(checked) =>
                            setState({
                              ...state,
                              enableNotifications: {
                                ...state.enableNotifications,
                                desktop: checked,
                              },
                            })
                          }
                        />
                      </div>
                      <span>Enable desktop notification</span>
                    </div>
                    <div
                      className="discription-hed"
                      style={{ marginTop: "1%" }}
                    >
                      <div>
                        <Switch
                          size="small"
                          checked={state.enableNotifications.sounds}
                          onChange={(checked) =>
                            setState({
                              ...state,
                              enableNotifications: {
                                ...state.enableNotifications,
                                sounds: checked,
                              },
                            })
                          }
                        />
                      </div>

                      <span>Enable notification sound</span>
                    </div>
                  </div>

                  <div className="role-fulid" style={{ marginTop: "2%" }}>
                    <h4 className="role">Notification type</h4>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="discription-hed">
                      <div
                        className="discription-hed"
                        style={{ marginTop: "1%" }}
                      >
                        <div>
                          <div style={{ display: "flex" }}>
                            <Switch
                              size="small"
                              checked={state.notificationType.newConversation}
                              onChange={(checked) =>
                                setState({
                                  ...state,
                                  notificationType: {
                                    ...state.notificationType,
                                    newConversation: checked,
                                  },
                                })
                              }
                            />
                            <span style={{ marginLeft: "8px" }}>
                              New Conversation
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="discription-hed"
                        style={{ marginTop: "1%", marginLeft: "5%" }}
                      >
                        <div>
                          <Switch
                            size="small"
                            checked={
                              state.notificationType.mentionedConversation
                            }
                            onChange={(checked) =>
                              setState({
                                ...state,
                                notificationType: {
                                  ...state.notificationType,
                                  mentionedConversation: checked,
                                },
                              })
                            }
                          />
                        </div>

                        <span>Mentioned Conversation</span>
                      </div>
                    </div>
                  </div>
                  <div className="discription-hed">
                    <div
                      className="discription-hed"
                      style={{ marginTop: "1%" }}
                    >
                      <div>
                        <Switch
                          size="small"
                          checked={state.notificationType.forwardedConversation}
                          onChange={(checked) =>
                            setState({
                              ...state,
                              notificationType: {
                                ...state.notificationType,
                                forwardedConversation: checked,
                              },
                            })
                          }
                        />
                      </div>
                      <span>Forwarded Conversation</span>
                    </div>
                    <div
                      className="discription-hed"
                      style={{ marginTop: "1%", marginLeft: "1%" }}
                    >
                      <div>
                        <Switch
                          size="small"
                          checked={
                            state.notificationType.newMessageInOpenConversation
                          }
                          onChange={(checked) =>
                            setState({
                              ...state,
                              notificationType: {
                                ...state.notificationType,
                                newMessageInOpenConversation: checked,
                              },
                            })
                          }
                        />
                      </div>
                      <span>New Message in open Conversation</span>
                    </div>
                  </div>
                </div>
                <Button
                  type="primary"
                  style={{ marginTop: "-2%", float: "right" }}
                  onClick={updateState}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default My_profile;
