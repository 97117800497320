import React, {useEffect, useState} from "react";
import {
    AddContact,
    blockContactApi,
    deleteContactApi,
    GetAllContacts, GetSpecificContact,
    UnBlockContactApi,
    updateContactApi
} from "../../Api/Api";
import {useTranslation} from "react-i18next";
import ContactInfo from "../../ContactInfo";
import Button from "@mui/material/Button";
import Table_contacts from "../Table_contacts";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import {MuiTelInput} from "mui-tel-input";
import {Dropdown, Menu, Drawer, Form} from "antd";
import {MoreOutlined} from "@ant-design/icons";
import moment from "moment/moment";
import ContactForm from "../Form/Form";

export default function Contacts() {
    const [state, setState] = useState({
        isOpen: false,
        name: "",
        email: "",
        phone: "",
        data: [],
        value: false,
        contactId: "",
        isEmailError: false,
        emailErrorMsg: "",
        isContactInfo: false,
        contactData: "",
        isValueChange: false,
        filterModal: false,
        columnsData: [],
        openDrawer: false,
        contactInfo: null,
        mode: ''
    });
    const [form] = Form.useForm();
    const getContacts = () => {
        GetAllContacts().then(res => {
                let data = res.data.data
                makeColumnsData(data)
            }
        ).catch(error => console.log(error))
    }

    const BlockContact = (id) => {
        blockContactApi(id)
            .then(() => {
                    getContacts()
                }
            )
            .catch(err => console.log(err, "err"))
    }

    const UnBlockContactFunc = (id) => {
        UnBlockContactApi(id)
            .then(() => {
                    getContacts()
                }
            )
            .catch(err => console.log(err, "err"))
    }

    const GetSpecificContactFunc = (id) => {
        GetSpecificContact(id).then(res => {
            setState(prevState => ({
                ...prevState,
                contactInfo: res.data.data,
                openDrawer: true,
                mode: 'update',
                contactId: id
            }))
        }).catch(error => console.log(error, "error"))
    }
    const deleteContactApiFunc = (id) => {
        deleteContactApi(id)
            .then(() => {
                    getContacts()
                }
            )
            .catch(err => console.log(err, "err"))
    }

    const makeColumnsData = (data) => {
        let columnsData = data.length > 0 && Object.keys(data[0])
        let indexToRemove = columnsData.indexOf("_id");
        columnsData.splice(indexToRemove, 1);
        columnsData.push("actions");
        let updatedArray = data.map((obj) => ({
            ...obj,
            addedBy: `${obj.addedBy.firstName + ' ' + obj.addedBy.lastName}`,
            createdAt: moment(obj.createdAt).format('MMMM Do YYYY, h:mm a'),
            tags: obj.tags.length > 0 && obj.tags.map((item, idx) => idx !== 0 ? ` ,` + item : item),
            actions: <Dropdown overlay={(
                <Menu>
                    <Menu.Item onClick={() => {
                        GetSpecificContactFunc(obj.id)

                    }} key="1">
                        Edit
                    </Menu.Item>
                    <Menu.Item onClick={() => deleteContactApiFunc(obj.id)} key="2">
                        Delete
                    </Menu.Item>
                    <Menu.Item onClick={() => BlockContact(obj.id)} key="3">
                        Block
                    </Menu.Item>
                    <Menu.Item onClick={() => UnBlockContactFunc(obj.id)} key="4">
                        Unblock
                    </Menu.Item>
                </Menu>
            )} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                    <MoreOutlined style={{fontSize: '14px'}}/>
                </a>
            </Dropdown>
        }))

        setState({...state, columnsData, data: updatedArray, openDrawer: false})
    }

    const selectedTeam = (data) => {
        let newData = {
            ...data,
            'name': data.name
        }
        setState(prevState => ({...prevState, contactData: newData, isContactInfo: true}))
    }
    const back = () => {
        setState({...state, isContactInfo: false,})
    }
    const onSubmit = (data) => {
        AddContact(data).then(() => {
            getContacts()
        }).catch((err) => {
            console.log(err)
        })
    }
    const onUpdate = (data) => {
        updateContactApi(state.contactId, data).then(() => {
            getContacts()
        }).catch(error => console.log(error))
    }

    const closeDialogue = () => {
        setState({...state, openDrawer: false, mode: ''})
    }

    const validateEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailRegex.test(email);
    };
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setState({
            ...state,
            email: newEmail,
            isEmailError: !validateEmail(newEmail),
            emailErrorMsg: ""
        });
    };

    useEffect(() => {
        getContacts()
    }, [state.isContactInfo]);
    const {t} = useTranslation();
    return (
        <div style={{width: "100%"}}>
                        <div className='main-header'>
                            <div className='all-contacts' style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <div style={{
                                    opacity: ".7",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    marginLeft: "3%"
                                }}>{t("All-Contacts")}</div>
                                <div style={{display: "flex", gap: 10}}>
                                    <Button variant="contained"
                                            onClick={() => setState({...state, filterModal: true})}>Filter</Button>
                                    <Button variant="contained"
                                            onClick={() => setState({
                                                ...state,
                                                openDrawer: true,
                                                mode: 'new'
                                            })}>Add new contact</Button>
                                </div>
                            </div>
                        </div>

                        { state.columnsData.length > 0 && <Table_contacts
                            filterModal={state.filterModal}
                            closeFilterModal={() => setState({
                                ...state,
                                filterModal: false
                            })}
                            selectedTeam={selectedTeam}
                            tableData={state.data}
                            columns={state.columnsData}
                        />
                        }

            <Dialog
                onClose={() => setState({
                    ...state,
                    isOpen: false,
                    name: "",
                    contactId: "",
                    email: "",
                    phone: "",
                    isEmailError: false,
                    emailErrorMsg: ""
                })}
                open={state.isOpen}
            >
                <DialogTitle>
                    {!!state.contactId ? "Update Information" : t("Enter-Contact-Information")}</DialogTitle>
                <div style={{display: "flex", flexDirection: "column", gap: 10, padding: 30, paddingTop: 10}}>
                    <TextField
                        label={t("title-Name")}
                        placeholder={t("Enter-Name")}
                        fullWidth
                        variant="outlined"
                        size="small"
                        style={{width: 400}}
                        value={state.name}
                        onChange={(e) => setState({...state, name: e.target.value})}
                    />
                    <TextField
                        error={state.email === "" ? false : state.isEmailError}
                        label={t("Email-address")}
                        placeholder={t("Enter-Email")}
                        id="outlined-error-helper-text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        className="form-control bg-transparent"
                        style={{width: 400}}
                        value={state.email}
                        helperText={state.emailErrorMsg}
                        onChange={(e) => handleEmailChange(e)}
                    />
                    <MuiTelInput
                        required
                        className={'textField'}
                        size="small"
                        id="outlined-required"
                        defaultCountry="PK"
                        value={state.phone}
                        onChange={(e) => {
                            setState({...state, phone: e})
                        }}
                    />

                    <Button
                        disabled={
                            !state.name ||
                            !state.email ||
                            state.phone.length < 14 ||
                            !validateEmail(state.email)
                        }
                        onClick={!!state.contactId ? onUpdate : onSubmit} variant="contained">
                        {!!state.contactId ? "Update" : t("Submit-btn")}

                    </Button>
                </div>
            </Dialog>
            {
                state.openDrawer && <ContactForm
                    stateData={state.contactInfo}
                    getContacts={getContacts}
                    mode={state.mode}
                    form={form}
                    contactId={state.contactId}
                    openDrawer={state.openDrawer}
                    closeDialogue={closeDialogue}
                />
            }
        </div>
    )
}
