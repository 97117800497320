const getFlowDataAction = (data) => {
    return (dispatch) => {
        dispatch({
            type:"GET_FLOW_DATA",
            payload:data
        })
    }
}

export {
    getFlowDataAction
}


