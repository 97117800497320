import axios from "../../Authentication/headers";
export function getAllTemplates() {
    return axios.get("/template/all")
}
export function getApprovedTemplates() {
    return axios.get("/template/approved")
}

export function getRejectedTemplates() {
    return axios.get("/template/reject")
}

export function getPendingTemplates() {
    return axios.get("/template/pending")
}

export function createTemplates(data) {
    return axios.post("/template/create", data)
}

export function updateTemplates(id, data) {
    return axios.put(`/template/update/${id}`, data)
}