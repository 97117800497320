import React, {useEffect, useState} from 'react';
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader';
import { IoIosInformationCircle } from 'react-icons/io';
import { Input, Button } from 'antd';
import {getPasswordPolicy, savePasswordPolicy} from "./API/Api";
import Loader from "../../ResuseableComponnets/Loader";
import { useTranslation } from 'react-i18next';
import "./Paaswordpolicy.scss"

const InputSection = ({ label, width }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div className="Passwordpolicy" style={{ display: 'flex', gap: '5px' }}>
      <div style={{ fontSize: '15px', fontWeight: '600' }}>{label}</div>
      <IoIosInformationCircle style={{ fontSize: '20px' }} />
    </div>
    <Input style={{ width: width }} size="large" placeholder={`Enter ${label}`} autoComplete="off" />
  </div>
);

const Passwordpolicy = () => {
  const {t} =useTranslation();
  const [state, setState] = useState({
    name: "",
    expirationDays: null,
    reuseCount: null,
    lockoutThreshold: null,
    isLoader:false
  })
  const getFunc = () => {
    setState({...state, isLoader: true})
      getPasswordPolicy()
          .then(res=> {
            let data = res?.data?.data
            setState({
              ...data,
              isLoader: false
            })
          })
          .catch(()=>setState({
            ...state,
            isLoader: false
          }))
  }

  const saveFunc = () => {
    setState({...state, isLoader: true})
      savePasswordPolicy({
        expirationDays: state.expirationDays,
        reuseCount: state.reuseCount,
        lockoutThreshold: state.lockoutThreshold,
        name:state.name
      })
          .then(()=>getFunc())
          .catch(()=>setState({
            ...state,
            isLoader: false
          }))
  }

  useEffect(() => {
    getFunc()
  }, []);
  return (
      state.isLoader ? <Loader/> :
      <div className="synctic-module-container" style={{ display: 'flex', flexDirection: 'column' }}>
        <SettingsHeader
          heading={t("Paaswordpolicy")}
          description={t("Configurepasswordpolicy")}
        />
        <div className='main-container-password'>
          <div className='Paaswordpolicy'>
            <div className='Paaswordpolicy-label'>
            <div style={{fontWeight:"600"}}>{t("Paaswordpolicy")}</div>
            <Input
            style={{width:"41svh"}}
                onChange={(e)=>setState({
              ...state,
              name: e.target.value
            })} value={state.name} label="Policy name" width="240%" />
            </div>
            <div className='Paaswordpolicy-label'>
                         <div style={{fontWeight:"600"}}>{t("PasswordExpirationperiod")}</div>
            <Input value={state.expirationDays}
            style={{width:"41svh"}}
                          onChange={(e)=>setState({
                            ...state,
                            expirationDays: e.target.value
                          })} label="Password Expiration period (Days)" width="180%" />

             </div>
          </div>
          <div className='Paaswordpolicy'>
            <div className='Paaswordpolicy-label'>
            <div style={{fontWeight:"600"}}>{t("PasswordReuseCount")}</div>
            <Input 
            style={{width:"41svh"}}
            value={state.reuseCount} onChange={(e)=>setState({
              ...state,
              reuseCount: e.target.value
            })} label="Password Reuse Count" width="240%" />
            </div>
            <div className='Paaswordpolicy-label'>
            <div style={{fontWeight:"600"}}>{t("AccountLockoutthreshold")}</div>
            <Input
            style={{width:"41svh"}}
             value={state.lockoutThreshold}  onChange={(e)=>setState({
              ...state,
              lockoutThreshold: e.target.value
            })} label="Account Lockout threshold" width="223%" />
            </div>
          </div>
          <Button onClick={()=>saveFunc()} type="primary" className='policy-btn'>
            {t("Save")}
          </Button>
        </div>
      </div>
  );
};

export default Passwordpolicy;
