import React, {useEffect, useState} from 'react'
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader'
import "./Outofoffice.scss"
import {Input, Button} from 'antd';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {IoLogoWhatsapp} from "react-icons/io";
import {FaInstagramSquare} from "react-icons/fa";
import {FaFacebookMessenger} from "react-icons/fa";
import {FaMessage} from "react-icons/fa6";
import {getOutOfOffice, saveOutOfOffice} from "./API/Api";
import Loader from "../../ResuseableComponnets/Loader";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const Outofoffice = () => {
    const Ismobile = useMediaQuery({maxWidth: 767})
    const { t } = useTranslation();
    const [state, setState] = useState({
        weekDaysMessage: "",
        weekDayWhatsapp: false,
        weekDayMessenger: false,
        weekDayInsta: false,
        weekEndMessage: "",
        weekEndWhatsapp: false,
        weekEndMessenger: false,
        weekEndInsta: false,
        activeMode:"weekDays",
        isLoader:false
    })

    const saveFunction = () => {
        setState({...state, isLoader: true})
        let data = {
            weekDays: {
                message: state.weekDaysMessage,
                channels: {
                    whatsapp: state.weekDayWhatsapp,
                    instagram: state.weekDayInsta,
                    messenger: state.weekDayMessenger
                }
            },
            weekEnd: {
                message: state.weekEndMessage,
                channels: {
                    whatsapp: state.weekEndWhatsapp,
                    instagram: state.weekEndInsta,
                    messenger: state.weekEndMessenger
                }
            }
        }
        saveOutOfOffice(data).then(()=>getFunc()
        ).catch(()=>setState({...state, isLoader: false})
        )
    }

    const getFunc = () => {
        setState({...state, isLoader: true})
        getOutOfOffice()
            .then(res=> {
                let response = res.data.data
                setState({...state,
                    weekDaysMessage: response.weekDays.message,
                    weekDayWhatsapp: response.weekDays.channels.whatsapp,
                    weekDayMessenger: response.weekDays.channels.messenger,
                    weekDayInsta: response.weekDays.channels.instagram,
                    weekEndMessage: response.weekEnd.message,
                    weekEndWhatsapp: response.weekEnd.channels.whatsapp,
                    weekEndMessenger: response.weekEnd.channels.messenger,
                    weekEndInsta: response.weekEnd.channels.instagram,
                    isLoader: false
                })
            })
            .catch(()=>setState({...state, isLoader: false}))
    }
    const {TextArea} = Input;
    useEffect(() => {
        getFunc()
    }, []);

    return (
        state.isLoader ?
            <Loader/> :
            <div className='synctic-module-container'>

                <SettingsHeader
                    heading={t("Outofoffice")}
                    description={t("Setyouroutofoffice")}
                />
                <div className='main-container-week'>
                    <div className='cards-box'
                         onClick={()=>setState({...state, activeMode: "weekDays"})}
                         style={{
                             width: "53%",
                             fontSize: "20px",
                             textAlign: "center",
                             fontWeight: "500",
                             color:state.activeMode==="weekDays" && "white",
                             backgroundColor:state.activeMode==="weekDays" && "rgb(4, 200, 200)"
                    }}>{t("Weekdays")}
                    </div>
                    <div className='cards-box'
                         onClick={()=>setState({...state, activeMode: ""})}
                         style={{
                             color:state.activeMode!=="weekDays" && "white",
                             backgroundColor:state.activeMode!=="weekDays" && "rgb(4, 200, 200)",
                             width: "53%", fontSize: "20px", textAlign: "center", fontWeight: "500"}}>{t("Weekend")}
                    </div>
                </div>

                <TextArea onChange={
                    (e)=> state.activeMode==="weekDays" ?
                        setState({...state, weekDaysMessage: e.target.value }) :
                        setState({...state, weekEndMessage: e.target.value })
                } value={state.activeMode==="weekDays" ? state.weekDaysMessage : state.weekEndMessage} rows={4} placeholder={t("Entermessage")}
                          style={{height: "120px", width: "100%", marginTop: "1%", resize: "none"}}/>

                <div style={{display: "flex", flexDirection: "column", marginTop: "2%"}}>
                    <div style={{display: "flex", gap: "4px"}}>
                        <FormControlLabel
                        style={{marginTop: Ismobile? "1px" : null}}
                            control={
                                <Switch
                                    checked={
                                    state.activeMode==="weekDays" ? state.weekDayWhatsapp : state.weekEndWhatsapp
                                }
                                        onChange={
                                    ()=>state.activeMode==="weekDays" ?
                                    setState({...state, weekDayWhatsapp: !state.weekDayWhatsapp }) :
                                    setState({...state, weekEndWhatsapp: !state.weekEndWhatsapp })
                                }
                                        name="jason"
                                />
                            }
                        />
                        <IoLogoWhatsapp
                            style={{fontSize: "22px", marginTop: "9px", marginLeft: "-2%", color: "#25D366"}}/>
                        <div className='whatsapp' style={{fontSize: 16, fontWeight: 500, marginTop: "9px"}}>{t("Whatsapp")}</div>
                    </div>
                    <div style={{display: "flex", gap: "4px"}}>
                        <FormControlLabel
                        style={{marginTop: Ismobile? "2px" : null}}
                            control={
                                <Switch checked={
                                    state.activeMode==="weekDays" ? state.weekDayInsta : state.weekEndInsta
                                }
                                        onChange={
                                            ()=>state.activeMode==="weekDays" ?
                                                setState({...state, weekDayInsta: !state.weekDayInsta }) :
                                                setState({...state, weekEndInsta: !state.weekEndInsta })
                                        }  name="jason"/>
                            }
                        />
                        <FaInstagramSquare
                            style={{fontSize: "22px", marginTop: "9px", marginLeft: "-2%", color: "#833AB4"}}/>
                        <div className='whatsapp' style={{fontSize: 16, fontWeight: 500, marginTop: "9px"}}>{t("Instagram")}</div>
                    </div>
                    <div style={{display: "flex", gap: "4px"}}>
                        <FormControlLabel
                            style={{marginTop: Ismobile? "2px" : null}}

                            control={
                                <Switch checked={
                                    state.activeMode==="weekDays" ? state.weekDayMessenger : state.weekEndMessenger
                                }
                                        onChange={
                                            ()=>state.activeMode==="weekDays" ?
                                                setState({...state, weekDayMessenger: !state.weekDayMessenger }) :
                                                setState({...state, weekEndMessenger: !state.weekEndMessenger })
                                        } name="jason"/>
                            }
                        />
                        <FaFacebookMessenger
                            style={{fontSize: "22px", marginTop: "9px", marginLeft: "-2%", color: "#0084FF"}}/>
                        <div className='whatsapp' style={{fontSize: 16, fontWeight: 500, marginTop: "9px"}}>{t("Messenger")}</div>
                    </div>
                </div>
                <Button onClick={()=>saveFunction()} type="primary" style={{float: "right", marginTop: "1%"}}>{t("Save")}</Button>
            </div>
    )
}

export default Outofoffice
