import React, {useEffect, useState} from 'react';
import './FormBuilder.scss';
import {PlusOutlined, InboxOutlined, DeleteFilled} from '@ant-design/icons';
import {Form, Input, Button, Space, Select, message, Upload, DatePicker, InputNumber} from 'antd';
import './FormBuilder.scss'
import axios, {get} from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {createForm, getForm, updateForm} from "./Apis/api";
import config from "../../../../../config";
import {isArray} from "chart.js/helpers";

const {Dragger} = Upload;
const props = {
    name: 'file',
    multiple: true,
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
        const {status} = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

const {TextArea} = Input;
const onFinish = (values) => {
    console.log('Received values of form:', values);
};
const CreateForm = () => {
    const [state, setState] = useState({
        selectedField: 'text',
        label: '',
        formFields: [],
        change: false,
        formName: '',
        formDes: '',
        optionKey: '',
        optionValue: '',
        interactiveMessageBtnId: '',
        interactiveMessageBtnText: '',
        interactiveMessageOption: '',
        interactiveMessageText: '',
        interactiveMessageDes: '',
        exportDateRange: [],
        exportDateFormatted: [],
        fileList: []
    })
    const navigate = useNavigate()
    const {id} = useParams()

    const addFieldData = (type, label) => {
        let newArray = [
            ...state.formFields,
            type === 'text' ? {
                type: "text",
                text: label,
                title: ''
            } : type === 'list' ? {
                type: "list",
                title: '',
                text:label,
                button: "",
                list: [{
                    text: "",
                    description: "",
                    id: ''
                }]
            } : type === 'button' ? {
                type: "button",
                title: '',
                text:label,
                buttonList: [
                    {
                        text: "",
                        id: ''
                    }
                ]
            } : type === 'option' ? {
                type: "option",
                title: '',
                text:label,
                options: [
                    {
                        key: "",
                        value: ""
                    }
                ]
            } : type === 'number' ? {
                title: '',
                type: "number",
                text: label
            } : type === 'regex' ? {
                title: '',
                type: "regex",
                text: label
            } : type === 'location' ? {
                title: '',
                type: "location",
                text: label
            } : type === 'date' ? {
                title: '',
                type: "date",
                text: label
            } : type === 'email' ? {
                title: '',
                type: "email",
                text: label
            } : (type === 'document' || type === 'video' || type === 'image') && {
                title: '',
                type: type,
                text: label
        }
        ]
        return setState({...state, text: '', formFields: newArray})
    }

    const updateOptions = (objectId, mode) => {

        let data = mode === 'options' ?
            {
                key: state.optionKey,
                value: state.optionValue
            } : mode === 'interactiveMessageBtn' ?
                {
                    id: '',
                    text: ''
                } : mode === 'list' ?
                    {
                        idx: "",
                        text: "",
                        description: ""

                    } : []

        mode === 'list' ?
            state.formFields[objectId].list.push(data) :
            mode === 'interactiveMessageBtn' ?
                state.formFields[objectId].buttonList.push(data) :
                state.formFields[objectId].options.push(data)

        let newFormFields = [...state.formFields];

        setState({
            ...state,
            formFields: newFormFields
        });
    };

    const deleteOption = (objectId, optionId) => {
        state.selectedField === 'list' ?
            state.formFields[objectId]?.list?.splice(optionId, 1) :
            state.selectedField === 'button' ?
                state.formFields[objectId]?.buttonList?.splice(optionId, 1) :
                state.formFields[objectId].options.splice(optionId, 1)
        let newFormFields = [...state.formFields];
        setState({
            ...state,
            formFields: newFormFields
        });
    };


    const deleteFunc = (index, data, type) => {
        state.formFields.splice(index, 1)
        state.fileList.filter(item => item.keyName !== `${type}-${index + 1}`)
        state.fileList.splice(index, 1)
        setState(prevState => ({
            ...prevState,
            formFields: [...state.formFields],
            fileList: [...state.fileList]
        }))
    }


    const deleteAttachmentFunc = (index) => {
        state.formFields.splice(index, 1)
        setState(prevState => ({...prevState, formFields: [...state.formFields]}))
    }

    const handleDateRangeChange = (item, idx) => (date, dateStrings) => {
        const updatedFormFields = [...state.formFields];
        updatedFormFields[idx].text = dateStrings;
        setState({...state, formFields: updatedFormFields, exportDateRange: date});
    };

    const saveForm = () => {
        let data = !id ? {
            name: state.formName,
            description: state.formDes,
            fields: state.formFields
        } : {
            name: state.formName,
            description: state.formDes,
            fields: state.formFields
        }

        let formData = new FormData()
        Array.from(state.fileList).forEach((file) => {
            formData.append(file.keyName, file.file);
        });
        formData.append('data', JSON.stringify(data));
        // let url = !!id ? config.api.baseUrl + `/form/update/${id}` : config.api.baseUrl + '/form/create'

        // !id && axios.post(url, formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //         'Authorization': `Bearer ${localStorage.getItem('token')}`,
        //     }
        // })
        !id && createForm(data).then(() => navigate("/chat-bot/settings"))
            .catch((err) => console.log(err, 'Err'));

        !!id && updateForm(id, data).then(() => navigate("/chat-bot/settings"))
            .catch((err) => console.log(err, 'Err'));

        // !!id && axios.put(url, formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //         'Authorization': `Bearer ${localStorage.getItem('token')}`,
        //     }
        // })
        //     .then(() => navigate("/chat-bot/settings"))
        //     .catch((err) => console.log(err, 'Err'));

    }

    const getFormById = () => {
        return !!id ? getForm(id).then(res => setState({
            ...state,
            formName: res.data.data.name,
            formDes: res.data.data.description,
            formFields: res.data.data.fields
        })).catch(err => console.log(err)) : null
    }

    const handleFileUpload = (evt, idx) => {
        let file = evt?.file?.originFileObj;
        let index = idx + 1;
        let updatedFormFields = [...state.formFields];
        let findObject = updatedFormFields[idx];
        let newData = {
            file,
            keyName: findObject.type === 'image' ? `image-${index}` :
                findObject.type === 'video' ? `video-${index}` : `document-${index}`
        }
        let isExist = state.fileList.find(item => item.keyName === `${findObject.type}-${idx + 1}`)
        !isExist && setState({...state, fileList: state.fileList.push(newData)})

        findObject.media_key = findObject.type === 'image' ? `image-${index}` :
            findObject.type === 'video' ? `video-${index}` : `document-${index}`

        updatedFormFields[idx] = findObject;
        setState({...state, formFields: updatedFormFields});
    }

    const formValidation = () => {
        let res = state.formFields.find(data=>{
            if (data.type === 'option') {
                return data.options?.find(item => item.key === '' || item.value === '');
            }
            else if (data.type === 'button') {
                return data?.buttonList?.find(item => item.text === '' || item?.id === '' || data.button === '');
            }
            else if (data.type === 'list') {
                return data?.list?.find(item => item.text === '' || item.description === '' || item?.id === '' || data.button === '');
            }
        })
        return res
    }

    const disableAddMore = (mode, idx) => {
        if (mode === 'options') {
            return state.formFields[idx]?.options?.find(item => item.key === '' || item.value === '');
        } else if (mode === 'buttons') {
            return state.formFields[idx]?.buttonList?.find(item => item.text === '' || item?.id === '' || state.formFields[idx].button === '');
        } else if (mode === 'list') {
            return state.formFields[idx]?.list?.find(item => item.text === '' || item.description === '' || item.id === '' || state.formFields[idx].button === '');
        }
    }


    useEffect(() => {
        getFormById()
    }, []);

    return <div className="synctic-module-container">
        <div className='cards-box'>
            <div style={{width: '70%', margin: 'auto'}}>
                <h2 style={{opacity: .7}}>Create Form</h2>
                <div style={{marginTop: 20}}/>
                <Input placeholder="Add form name" allowClear
                       value={state.formName}
                       onChange={(e) => setState({...state, formName: e.target.value})}/>
                <br/>
                <br/>
                <TextArea placeholder="Add description" allowClear
                          value={state.formDes}
                          onChange={(e) => setState({...state, formDes: e.target.value})}/>

                <Form
                    name="basic"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    style={{marginTop: 20}}
                >
                    {
                        state.formFields.map((item, idx) =>
                            (item.type === 'text' ||
                                item.type === 'location' ||
                                item.type === 'number' ||
                                item.type === 'email' ||
                                item.type === 'regex'
                            )
                                ?
                                <div style={{display: 'flex', gap: '10', justifyContent: 'space-between'}} key={idx}>
                                    <div style={{width: '95%'}}>
                                        <h5 style={{fontSize: 13, marginTop: 18, opacity: .6}}>{item.text}</h5>
                                        <Input
                                            disabled={true}
                                            onChange={(e) => {
                                                const enteredValue = e.target.value.replace(/\D/, '');
                                                const updatedFormFields = [...state.formFields];
                                                updatedFormFields[idx].text = state.selectedField === 'number' ?
                                                    enteredValue : e.target.value;
                                                setState({...state, formFields: updatedFormFields});
                                            }}
                                            value={item.text}
                                            // placeholder={'Enter here'}
                                        />
                                    </div>
                                    <DeleteFilled onClick={() => {
                                        deleteFunc(idx)
                                    }} style={{opacity: .6}}/>
                                </div>
                                : (item.type === 'document' || item.type === 'image' || item.type === 'video') ?
                                    <div className={'drag-wrapper'}
                                         style={{display: 'flex', gap: '10', justifyContent: 'space-between'}} key={idx}>
                                        <div style={{width: '95%'}}>
                                            <h5 style={{fontSize: 13, marginTop: 18, opacity: .6}}>{item.text}</h5>
                                            {/*       <Dragger*/}
                                            {/*    accept={'image'}*/}
                                            {/*    {...props}*/}
                                            {/*         onChange={(e)=>handleFileUpload(e, idx)}*/}
                                            {/*>*/}
                                            {/*    <p className="ant-upload-drag-icon">*/}
                                            {/*        <InboxOutlined/>*/}
                                            {/*    </p>*/}
                                            {/*    <p className="ant-upload-text">Click or drag file to this area to upload</p>*/}
                                            {/*    <p className="ant-upload-hint">*/}
                                            {/*        Support for a single or bulk upload. Strictly prohibited from uploading*/}
                                            {/*        company*/}
                                            {/*        data*/}
                                            {/*        or other*/}
                                            {/*        banned files.*/}
                                            {/*    </p>*/}
                                            {/*</Dragger>*/}
                                        </div>
                                        <DeleteFilled onClick={() => deleteFunc(idx, state.formFields, item.type)}
                                                      style={{opacity: .6}}/>
                                    </div>
                                    : item.type === 'date' ?
                                        <div style={{display: 'flex', gap: '10', justifyContent: 'space-between'}}
                                             key={idx}>
                                            <div style={{width: '95%'}}>
                                                <h5 style={{fontSize: 13, marginTop: 18, opacity: .6}}>{item.text}</h5>
                                                <div style={{display: 'flex', gap: 10}}>
                                                    <DatePicker
                                                        onChange={handleDateRangeChange(item, idx)}
                                                    />
                                                </div>
                                            </div>
                                            <DeleteFilled onClick={() => deleteFunc(idx, state.formFields)}
                                                          style={{opacity: .6}}/>
                                        </div>
                                        : item.type === 'list' ?
                                            <div style={{display: 'flex', gap: '10', justifyContent: 'space-between'}}
                                                 key={idx}>
                                                <div style={{width: '95%'}}>
                                                    <h5 style={{fontSize: 13, marginTop: 18, opacity: .6}}>{item.text}</h5>
                                                    <Input onChange={(e) => {
                                                        const updatedFormFields = [...state.formFields];
                                                        updatedFormFields[idx].title = e.target.value;
                                                        setState({...state, formFields: updatedFormFields});
                                                    }} value={item.title} placeholder={'Enter title of message'}/>
                                                    <Input onChange={(e) => {
                                                        const updatedFormFields = [...state.formFields];
                                                        updatedFormFields[idx].button = e.target.value;
                                                        setState({...state, formFields: updatedFormFields});
                                                    }} style={{marginTop: 18}} value={item.button}
                                                           placeholder={'Enter button text (max 20 characters)'}/>
                                                    {
                                                        item.list.map((option, optionIdx) =>
                                                            <div key={optionIdx}
                                                                 style={{
                                                                     display: 'grid',
                                                                     gridTemplateColumns: '20% 30% 40% 10%',
                                                                     justifyContent: 'space-between',
                                                                     gap: 10,
                                                                     alignItems: 'center',
                                                                     marginTop: 18
                                                                 }}>
                                                                <Input onChange={(e) => {
                                                                    const updatedFormFields = [...state.formFields];
                                                                    updatedFormFields[idx].list[optionIdx].id = e.target.value;
                                                                    setState({...state, formFields: updatedFormFields});
                                                                }}
                                                                       value={option.id}
                                                                       placeholder={'List option'}
                                                                />
                                                                <Input onChange={(e) => {
                                                                    const updatedFormFields = [...state.formFields];
                                                                    updatedFormFields[idx].list[optionIdx].text = e.target.value;
                                                                    setState({...state, formFields: updatedFormFields});
                                                                }}
                                                                       value={option.text}
                                                                       placeholder={'List option text'}
                                                                />
                                                                <Input onChange={(e) => {
                                                                    const updatedFormFields = [...state.formFields];
                                                                    updatedFormFields[idx].list[optionIdx].description = e.target.value;
                                                                    setState({...state, formFields: updatedFormFields});
                                                                }}
                                                                       value={option.description}
                                                                       placeholder={'Add description for your menu'}
                                                                />
                                                                <DeleteFilled
                                                                    onClick={() => deleteOption(idx, optionIdx, state.formFields)}
                                                                    style={{opacity: .6, display:item.list.length ===1 && 'none'}}/>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        item?.list.length > 0 &&
                                                        <Button onClick={() => updateOptions(idx, 'list')}
                                                             type={'primary'}
                                                             disabled={!!disableAddMore('list', idx)}
                                                             style={{marginTop: 18}}>Add More</Button>}
                                                </div>
                                                <DeleteFilled onClick={() => deleteFunc(idx, state.formFields)}
                                                              style={{opacity: .6}}/>
                                            </div>
                                            : item.type === 'button' ?
                                                <div style={{display: 'flex', gap: '10', justifyContent: 'space-between'}}
                                                     key={idx}>
                                                    <div style={{width: '95%'}}>
                                                        <h5 style={{
                                                            fontSize: 13,
                                                            marginTop: 18,
                                                            opacity: .6
                                                        }}>{item.text}</h5>
                                                        <Input onChange={(e) => {
                                                            const updatedFormFields = [...state.formFields];
                                                            updatedFormFields[idx].title = e.target.value;
                                                            setState({...state, formFields: updatedFormFields});
                                                        }} value={item.title} placeholder={'Enter title of message'}/>
                                                        {
                                                            item.buttonList.map((option, optionIdx) =>
                                                                <div key={optionIdx}
                                                                     style={{
                                                                         display: 'grid',
                                                                         gridTemplateColumns: '45% 45% 10%',
                                                                         justifyContent: 'space-between',
                                                                         gap: 10,
                                                                         alignItems: 'center',
                                                                         marginTop: 18
                                                                     }}>
                                                                    <Input onChange={(e) => {
                                                                        const updatedFormFields = [...state.formFields];
                                                                        updatedFormFields[idx].buttonList[optionIdx].id = e.target.value;
                                                                        setState({...state, formFields: updatedFormFields});
                                                                    }} value={option.id}
                                                                           placeholder={'Enter button id'}/>
                                                                    <Input onChange={(e) => {
                                                                        const updatedFormFields = [...state.formFields];
                                                                        updatedFormFields[idx].buttonList[optionIdx].text = e.target.value;
                                                                        setState({...state, formFields: updatedFormFields});
                                                                    }} value={option.text}
                                                                           placeholder={'Enter button text'}/>
                                                                    <DeleteFilled
                                                                        onClick={() => deleteOption(idx, optionIdx, state.formFields)}
                                                                        style={{opacity: .6, display:item.buttonList.length ===1 && 'none'}}/>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            item?.buttonList.length > 0 &&
                                                            <Button
                                                            onClick={() => updateOptions(idx, 'interactiveMessageBtn')}
                                                            type={'primary'}
                                                            disabled={!!disableAddMore('buttons', idx)}
                                                            style={{marginTop: 18}}>Add More</Button>}
                                                    </div>
                                                    <DeleteFilled onClick={() => deleteFunc(idx, state.formFields)}
                                                                  style={{opacity: .6}}/>
                                                </div>
                                                : item.type === 'option' ?
                                                    <div style={{
                                                        display: 'flex',
                                                        gap: '10',
                                                        justifyContent: 'space-between'
                                                    }}
                                                         key={idx}>
                                                        <div style={{width: '95%'}}>
                                                            <h5 style={{
                                                                fontSize: 13,
                                                                marginTop: 18,
                                                                opacity: .6
                                                            }}>{item.text}</h5>
                                                            {
                                                                item.options.map((option, optionIdx) =>
                                                                    <div key={optionIdx}
                                                                         style={{
                                                                             display: 'grid',
                                                                             gridTemplateColumns: '45% 45% 10%',
                                                                             justifyContent: 'space-between',
                                                                             gap: 10,
                                                                             alignItems: 'center',
                                                                             marginTop: 18
                                                                         }}>
                                                                        <Input onChange={(e) => {
                                                                            const updatedFormFields = [...state.formFields];
                                                                            updatedFormFields[idx].options[optionIdx].key = e.target.value;
                                                                            setState({
                                                                                ...state,
                                                                                formFields: updatedFormFields
                                                                            });
                                                                        }}
                                                                               value={option.key}
                                                                               placeholder={'Key'}
                                                                        />
                                                                        <Input value={option.value}
                                                                               onChange={(e) => {
                                                                                   const updatedFormFields = [...state.formFields];
                                                                                   updatedFormFields[idx].options[optionIdx].value = e.target.value;
                                                                                   setState({
                                                                                       ...state,
                                                                                       formFields: updatedFormFields
                                                                                   });
                                                                               }}
                                                                               placeholder={'Value'}/>
                                                                        <DeleteFilled
                                                                            onClick={() => deleteOption(idx, optionIdx, state.formFields)}
                                                                            style={{opacity: .6, display:item.options.length ===1 && 'none'}}/>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                item.options.length > 0 &&
                                                            <Button onClick={() => updateOptions(idx, 'options')}
                                                                    type={'primary'}
                                                                    disabled={!!disableAddMore('options', idx)}
                                                                    style={{marginTop: 18}}>Add More</Button>
                                                            }
                                                        </div>
                                                        <DeleteFilled onClick={() => deleteFunc(idx, state.formFields)}
                                                                      style={{opacity: .6}}/>
                                                    </div>
                                                    : null
                        )
                    }
                </Form>
                <div>
                </div>


                <Space
                    style={{
                        display: 'grid',
                        marginTop: 16,
                        gridTemplateColumns: '47% 47%',
                        justifyContent: 'space-between'
                    }}
                    align="baseline"
                >
                    <Form.Item rules={[
                        {
                            required: true,
                            message: 'Missing question',
                        },
                    ]}
                    >
                        <Input value={state.text} onChange={(e) => setState({...state, text: e.target.value})}
                               placeholder="Question"/>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Missing field type',
                            },
                        ]}
                    >
                        <Select
                            onSelect={e => setState({...state, selectedField: e})}
                            placeholder="Field Type"
                            defaultValue={state.selectedField}
                        >
                            <Select.Option value="text">Text</Select.Option>
                            <Select.Option value="number">Number</Select.Option>
                            <Select.Option value="option">Option</Select.Option>
                            <Select.Option value="email">Email</Select.Option>
                            <Select.Option value="image">Image</Select.Option>
                            <Select.Option value="video">Video</Select.Option>
                            <Select.Option value="document">Document</Select.Option>
                            <Select.Option value="location">Location</Select.Option>
                            <Select.Option value="date">Date</Select.Option>
                            <Select.Option value="regex">Regular Expression</Select.Option>
                            <Select.Option value="button">Interactive Button Message</Select.Option>
                            <Select.Option value="list">Interactive List Message</Select.Option>
                        </Select>
                    </Form.Item>
                </Space>
                <Button
                    disabled={!state.text}
                    onClick={() => addFieldData(state.selectedField, state.text)} type="dashed" block
                    icon={<PlusOutlined/>}>
                    Add Field
                </Button>
                <Button
                    disabled={!state.formName || !state.formDes || !!formValidation()}
                    onClick={() => saveForm()}
                    type={'primary'}
                    style={{marginTop: 18}}>{!!id ? 'Update Form' : 'Save Form'} </Button>
            </div>
        </div>
    </div>
}

export default CreateForm