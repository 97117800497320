import {message} from "antd";

const initialState = {
    users: [],
    allConversations: [],
    allWhatsappConversations: [],
    allMessengerConversations: [],
    allInstagramConversations: [],
    data: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_WHATSAPP_CONVERSATIONS":
            return {
                ...state,
                allWhatsappConversations: action.payload
            };
        case "GET_ALL_MESSENGER_CONVERSATIONS":
            return {
                ...state,
                allMessengerConversations: action.payload
            };
        case "GET_ALL_INSTAGRAM_CONVERSATIONS":
            return {
                ...state,
                allInstagramConversations: action.payload
            };
        case "GET_WHATSAPP_CONVERSATION":
            let data = state.allWhatsappConversations.map((obj, idx) => {
                if (obj.conversationId === action.payload.conversationId) {
                    console.log(action.payload, 77555221111)
                    return {...obj, messages: [...action.payload.messages, ...obj.messages]};
                } else {
                    return obj;
                }
            });
            return {
                ...state,
                allWhatsappConversations: data
            };
        case "DELETE_WHATSAPP_CONVERSATION":
            let updatedData = state.allWhatsappConversations.map((obj, idx) => {
                if (obj.conversationId === action.payload.conversationId) {
                    return {...obj, messages: []};
                } else {
                    return obj;
                }
            });
            return {
                ...state,
                allWhatsappConversations: updatedData
            };
        case "GET_MESSENGER_CONVERSATION":
            let messengerConversation = state.allMessengerConversations.map((obj, idx) => {
                if (obj.conversationId === action.payload.conversationId) {
                    return {...obj, messages: action.payload.messages};
                } else {
                    return obj;
                }
            });
            return {
                ...state,
                allMessengerConversations: messengerConversation
            };
        case "GET_INSTAGRAM_CONVERSATION":
            let instagramConversation = state.allInstagramConversations.map((obj, idx) => {
                if (obj.conversationId === action.payload.conversationId) {
                    return {...obj, messages: action.payload.messages};
                } else {
                    return obj;
                }
            });
            return {
                ...state,
                allInstagramConversations: instagramConversation
            };
        case "UPDATE_WHATSAPP_CONVERSATION":
            let updatedConversation = state.allWhatsappConversations.map((obj, idx) => {
                if (obj.conversationId === action.payload.conversationId) {
                    return {...obj, messages: [...obj.messages, action.payload]};
                } else {
                    return obj;
                }
            });
            return {
                ...state,
                allWhatsappConversations: updatedConversation
            };
        case "UPDATE_MESSENGER_CONVERSATION":
            let updatedMessengerConversation = state.allMessengerConversations.map((obj, idx) => {
                if (obj.conversationId === action.payload.conversationId) {
                    return {...obj, messages: [...obj.messages, action.payload]};
                } else {
                    return obj;
                }
            });
            return {
                ...state,
                allMessengerConversations: updatedMessengerConversation
            };
        case "UPDATE_INSTAGRAM_CONVERSATION":
            let updatedInstagramConversation = state.allInstagramConversations.map((obj, idx) => {
                if (obj.conversationId === action.payload.conversationId) {
                    return {...obj, messages: [...obj.messages, action.payload]};
                } else {
                    return obj;
                }
            });
            return {
                ...state,
                allInstagramConversations: updatedInstagramConversation
            };
        case "UPDATE_All_WHATSAPP_CONVERSATION":
            let getMessages = state.allWhatsappConversations.find(item=>item?.conversationId===action.payload?.conversationId)
            let updatedArray = state.allWhatsappConversations.filter(obj =>
            obj.conversationId !== action.payload.conversationId
        )
            return {
                ...state,
                allWhatsappConversations: [
                    {
                        ...action.payload,
                        messages: !!getMessages?.messages ? getMessages.messages : action.payload.messages
                    },
                    ...updatedArray
                ]
            };
        default:
            return state;
    }
};

export default reducer