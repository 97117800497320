import React from 'react'
import axios from "../../../Authentication/headers"

export function updateSettingApi(data){
    return axios.post(`/account/updateSetting`, data)
}
export function getlangall(){
    return axios.get(`/account/getallLanguages`)
}

export function GetAccountInfo(id){
    return axios.get(`/account/${id}`)
}
