import React from 'react';
import "./Campaigns.scss";
import CampaignIcon from "@mui/icons-material/Campaign";
import Button from '@mui/material/Button';
import whatsappLogo from "../../assets/svg/social-logos/whatsappLogo.png";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import FavoriteIcon from "@mui/icons-material/Favorite";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";

const Campaigns = (props) => {
    return <div className="campaigns-parent synctic-module-container">
        <div className="start-div">
        <div className="cards-box">
            <div className="header">
            <div>

                {
                    props.activeTab==="Campaigns" ?
                        <CampaignIcon style={{height:40, width:40, color:"rgb(37, 99, 235)"}}/> :
                        props.activeTab==="Chat bot" ? <SmartToyIcon style={{height:40, width:40, color:"rgb(37, 99, 235)"}}/> :
                            props.activeTab==="Channels health" ? <FavoriteIcon style={{height:40, width:40, color:"red"}}/> : ""
                }
                        <div className="heading"> {`${props.activeTab==="Campaigns" ? "Campaigns" :
                            props.activeTab==="Chat bot" ? "Chat bot" : "Channels health"}`}</div>

                        <div className="sub-heading">{`${props.activeTab==="Campaigns" ? "Create and manage messaging campaigns to your users" :
                            props.activeTab==="Chat bot" ? "" : "A clear and detailed overview of your channel health"}`}</div>
                    </div>
            {
               (props.activeTab==="Campaigns" || props.activeTab==="Chat bot") && <Button variant="contained">Create a {`${props.activeTab==="Campaigns" ? "campaign" : "bot"}`}</Button>
            }
            </div>
        </div>
            {
                (props.activeTab==="Campaigns") && <div className="info-box">
                    <div className="info-box-first">
                        <div>
                            <div style={{fontSize:12, fontWeight:510, color:"#00a86b"}}>Do you know about whatsapp pricing?</div>
                            <div style={{fontSize:11, fontWeight:400, color:"#00a86b", opacity:.6}}>Whatsapp charges based on business initiated messages.Read more in their documentation.</div>
                        </div>
                        <div style={{fontSize:12, fontWeight:550, color:"#00a86b"}}>Read more</div>
                    </div>
                </div>
            }
            <div className="connect-box">
                {
                    (props.activeTab==="Campaigns" || props.activeTab==="Chat bot") && <img style={{height:34, width:34, margin:"auto"}} src={whatsappLogo}/>
                }
                {
                    (props.activeTab==="Campaigns" || props.activeTab==="Chat bot") && <div>Connect a whatsapp channel to create a {`${props.activeTab==="Campaigns" ? "campaign" : "workflow"}`}</div>
                }
                {
                    props.activeTab=="Channels health" && <div style={{fontSize:"13px", fontWeight:510, opacity:.5}}>No channel? No need for a health checkup. <br/> Connect your first channel today! </div>
                }

                <Button style={{width:"fit-content", background:props.activeTab!=="Channels health"? "#00a86b" : "rgb(37, 99, 235)", color:"white"}}> {
                    props.activeTab=="Channels health" ?
                    <span style={{display:"flex"}}>
                        <QueuePlayNextIcon
                        style={{marginRight: 10}}/> Connect a channel
                    </span> : "Connect a whatsapp"
                    }</Button>
            </div>
        </div>
    </div>
}
export default Campaigns;