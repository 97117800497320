import React from 'react'
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader'
import { AiFillStar } from "react-icons/ai";
import "./Ai.scss"
import InfoNote from '../../ResuseableComponnets/Note';
import { FaFileUpload } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

const Ai_assistant = () => {
  const Ismobile = useMediaQuery({maxWidth: 767});
  const { t } = useTranslation();
  return (
    <div className="synctic-module-container">
     <SettingsHeader
     icon={<AiFillStar className='icon'/>}
     heading={t("AI-Assistant")}
     description={t("description-Feed")}
     />
<div style={{marginTop: "4%"}}>
<InfoNote
  icon={<AiFillStar />} 
  heading={t("Feed-The-AI-Assistant")}
  description={t("Connected-Your-Knowledge-discription")}

/>
</div>
<div className='main-container-knowledge'>
<div className='knowledge-Base'>{t("Knowledge-Base")}</div>
<div className='Document'>{t("Document")}</div>
<div className='main-uplaod'>
<div className='upload'>
<div  className="main-FaFileUpload-grup"><FaFileUpload style={{fontSize: Ismobile? "14px" : '27px', color: "gray"}}/> <div className='pdf-upload-text'></div>{t("upload-file")}</div>
</div>
</div>
</div>
    </div>
  )
}

export default Ai_assistant
