// create question node
export const createQuesstionNode = (label, content, position, getId) => {
  const dynamicNode = {
    id: getId,
    type: 'option', 
    data : {},
    templateStarter : false,
    position: position,
  }  
  return dynamicNode;
};

// create option node
export const createOptionNode = (key, content,  getId, parentId) => {

    // getting y position
    let positionY = 0 ;
    if(key === 1){
      positionY = (70 + 55);
    }
    else{ 
      positionY = 70 + (key * 55);
    }

    // if(children && children.length > 0){
    //   let object = children[children.length - 1] 
    //   positionY =  object?.position?.y + 55
    // }
    // else{
    //   positionY = 125
    // }

    // getting y position
    const dynamicNode = {
      id: getId,
      type: 'list',
      data: {
        key,
        content,
      },
      position: { x: 0, y: positionY},
      parentNode: parentId,
      sourcePosition: 'right',
      extent: 'parent',
      draggable: false,
      style: { width: 250},
    };
  
    return dynamicNode;
};


// create message node
export const createMessageNode = (position, getId, type) => {
  return {
      id: getId,
      type,
      position,
      data : {},
      templateStarter : false,
      className: 'light',
      style: { backgroundColor: 'rgba(255, 0, 0, 0.2)', width: 250},
    }
}


// create condition node
export const createConditionNode = (label, content, position, getId) => {
  const dynamicNode = {
    id: getId,
    type: 'conNode', // Use your custom node type
    data: {
      label,
      content,
    },
    templateStarter : false,
    position: position,
    className: 'light',
    style: { backgroundColor: 'rgba(255, 0, 0, 0.2)', width: 250},
  }  
  return dynamicNode;
};


// create condition option
export const createConditionOption = (label, content,  getId, parentId, yAxis) => {
  // getting y position
  const dynamicNode = {
    id: getId,
    type: 'conOptNode',
    data: {
      label,
      content,
    },
    position: { x: 0, y: yAxis},
    parentNode: parentId,
    sourcePosition: 'right',
    extent: 'parent',
    draggable: false,
    style: { width: 250, height:40 },
  };

  return dynamicNode;
};

  