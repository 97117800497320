import React, { useRef, useState ,useEffect} from 'react';
import { Button, Input, Space, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { apiall , activedApi , DeactivedApi, Delete } from "./Api/Api" 
import Loader from "../../ResuseableComponnets/Loader";
import { Link } from 'react-router-dom'
import { MdEdit } from "react-icons/md";
import { Menu, Dropdown } from 'antd';
import { CheckOutlined, CloseOutlined, MoreOutlined } from '@ant-design/icons';
import { MdDelete } from "react-icons/md";



const Api_table = () => {
  const [state, setState] = useState({
    ApiData: [],
    loading: true,
  });
  

  const fetchData = () => {
    setState({...state, loading:true})
    apiall()
        .then(response => {
            setState(prevState => ({
                ...prevState,
                ApiData:response.data.data.map((obj) => ({
                  ...obj,
                  'status': obj.status ? "Active" : "De-activated"})),
                loading:false
            }));
            setState(prevState => ({...prevState, loading: false}));
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setState(prevState => ({...prevState, loading: false}));
        });
}

useEffect(() => {
  fetchData();
}, []);


const Activefuc = (id) => {
  activedApi(id)
      .then(() => fetchData())
      .catch(() => {
          setState(prevState => ({
              ...prevState,
              loading: false
          }));
      });
}

const DeActivefunc = (id) => {
  DeactivedApi(id)
      .then(() => fetchData())
      .catch(() => {
          setState(prevState => ({
              ...prevState,
              loading: false
          }));
      });
}

const Deletefunc = (id) => {
  Delete(id)
      .then(() => fetchData())
      .catch(() => {
          setState(prevState => ({
              ...prevState,
              loading: false
          }));
      });
}



  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex )}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
             
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),
    },
    {
      title: "Description",
      dataIndex: 'description',
      key: 'description',
      width: '30%',
    },
    {
      title: "Status",
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (_, record) => record.status || state.status 
    },
    {
      title: "Action",
      key: 'action',
      width: '20%',
      render: (_, record) => (
        <Dropdown overlay={(
          <Menu>
            <Menu.Item onClick={() => Activefuc(record._id)} key="1">
              <div>
                <CheckOutlined /> Active 
              </div>
            </Menu.Item>
            <Menu.Item onClick={() => DeActivefunc(record._id)} key="2">
              <div>
                <CloseOutlined /> De-activate
              </div>
            </Menu.Item>
            <Menu.Item key="3">
            <Link to={'/Settings/Api_mangement/Apiform/' + record._id}>
              <div>
                <MdEdit style={{fontSize:"15px"}}/> Edit
              </div>
              </Link>
            </Menu.Item>
            <Menu.Item onClick={() => Deletefunc(record._id)} key="4">
              <div>
                 <MdDelete style={{fontSize:"15px",marginTop:"-1%"}}/> Delete
              </div>
            </Menu.Item>
          </Menu>
        )} trigger={['click']}>
          <a>
            <MoreOutlined style={{ fontSize: '14px' }} />
          </a>
        </Dropdown>
      ),
    },
  ];
  
  

  return (
    <div className='main-table'>
    {state.loading ? (
      <Loader />
    ) : (
      <Table
        columns={columns}
        dataSource={state.ApiData}
      />
    )}



    
  </div>
  );
};

export default Api_table;
