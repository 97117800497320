import React, {useEffect, useRef, useState} from 'react';
import {DeviceFrameset} from "react-device-frameset";
import 'react-device-frameset/styles/marvel-devices.min.css'
import 'react-device-frameset/styles/device-selector.min.css'
import {Input} from "antd";
import {flowTesterMsg} from "../../Apis/Apis";
import SendIcon from '@mui/icons-material/Send';
import InstagramBackgroundImage from "../../../../assets/backgroundImages/whastappback.png";
import './DeviceFrame.scss';
import Multimedia from "./Multimedia";

const DeviceFrame = (props) => {
    const [state, setState] = useState({
        msg: '',
        allMessages: []
    })
    const [position, setPosition] = useState({x: 0, y: 0});
    const [isDragging, setIsDragging] = useState(false);
    const [offset, setOffset] = useState({x: 0, y: 0});
    const messagesRef = useRef(null);

    const handleMouseDown = (event) => {
        setIsDragging(true);
        setOffset({
            x: event.clientX - position.x,
            y: event.clientY - position.y
        });
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (event) => {
        if (isDragging) {
            setPosition({
                x: event.clientX - offset.x,
                y: event.clientY - offset.y
            });
        }
    };

    let draggable = {
        position: 'absolute',
        cursor: 'pointer',
        userSelect: 'none',
        left: `${position.x}px`,
        top: `${position.y}px`,
        zIndex: 550
    }

    const flowTesterMsgFunc = async () => {
        try {
            // Prepare message data
            let msgData = [{
                text: state.msg,
                direction: 1
            }];

            // Update state with the message data
            setState(prevState => ({
                ...prevState,
                allMessages: prevState.allMessages.concat(msgData),
                msg: ''
            }));

            // Call asynchronous function
            const response = await flowTesterMsg({
                flowId: props.flowDataId,
                message: state.msg
            });

            // Process response
            let resData = [{
                ...response.data,
                direction: 0
            }];

            // Update state with the response data
            setState(prevState => ({
                ...prevState,
                allMessages: prevState.allMessages.concat(resData)
            }));
        } catch (error) {
            console.error("Error occurred:", error);
        }
    };


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            flowTesterMsgFunc();
        }
    };
    const scrollToBottom = () => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    };
    useEffect(() => {
        scrollToBottom();
    }, [state.allMessages.length]);
    return <div style={draggable}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}>
        <DeviceFrameset
            device="iPhone X"
            color="black"
            style={{
                position: 'absolute', zIndex: 99
            }}
            zoom={'70%'}
        >
            <div style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column'
            }}>
                <div ref={messagesRef} className={'deviceMessage'} style={{
                    backgroundImage:`url(${InstagramBackgroundImage})`
                }}>
                    <div style={{height:50}}></div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding:10
                    }}>
                        {
                            state.allMessages.length > 0 && state.allMessages.map((item, index) =>
                                <div key={index} style={{display:'flex', justifyContent: item.direction === 1 ? 'end' : 'start'}}>
                                    <div style={{
                                        padding: 10,
                                        background: item.direction===1 ? 'grey' : '#25D366',
                                        margin: '10',
                                        color: 'white',
                                        maxWidth: '60%',
                                        width:'fit-content',
                                        fontSize:16,
                                        borderRadius:5
                                    }}>
                                        {
                                            item.direction===1 &&
                                            <div>{item.text}</div>
                                        }
                                        {
                                            item.direction===0 && typeof item.data === 'string'?
                                            <div>{item.data}</div> : item.direction===0 && item?.data?.text && <div>{item.data.text}</div>
                                        }

                                        {
                                            item.data?.mediaType &&
                                            <Multimedia
                                                type={item.data.mediaType}
                                                url={item.url}
                                            />
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 10,
                    padding: 10
                }}>
                    <Input
                        value={state.msg}
                        onKeyDown={handleKeyDown}
                        style={{flexGrow: 1, height: 40}}
                        placeholder="Enter Message"
                        onChange={(e) => setState({...state, msg: e.target.value})}
                    />
                    <SendIcon onClick={flowTesterMsgFunc}
                              style={{
                                  fontSize: 40,
                                  color: '#25D366'
                              }}/>
                </div>
            </div>
        </DeviceFrameset>
    </div>

}

export default DeviceFrame;