import React, { memo, useEffect } from "react";
import { Handle, Position } from "reactflow";
import { useNodes } from "../../allnode";

const ListNode = (completeNode) => {
  const {nodes, setNodes, onNodesChange} = useNodes();
  const children = nodes.filter((node) => node.parentNode === completeNode.id);
  const data = completeNode?.data;
  const selected = completeNode?.selected;
    return (
          <div className="message-inner">
            <div className="list-node">
              <span>Code: {data.label}</span>
              <p>{data.content}</p>
              <Handle type="source" position={Position.Right} id="b" />
              </div>
          </div>
    );
  }


export default memo(ListNode);
