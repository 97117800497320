import { useNodes } from "../../allnode";
import { MoreOutlined, CopyFilled, DeleteFilled, FlagFilled, EditFilled } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { createOptionNode } from "./message";



export default function NodeActions({completeNode}){
    const {nodes, setNodes, onNodesChange, edges, setEdges, onEdgesChange} = useNodes();

    const items = [
        {
            key: '1',
            label: <div onClick={()=> copyNodes(completeNode)}><CopyFilled /> Copy</div>,
        },
        {
            key: '2',
            label:<div onClick={()=> deleteNodes(completeNode)}><DeleteFilled /> Delete</div>,
        },
        {
            key: '3',
            label: <div onClick={()=> makeFirstMessage(completeNode)}><FlagFilled /> Flag</div>,
        }
    ]
        


    const copyNodes = (selectedNode) =>{
        let nodeId =`node_${new Date().getTime()}`;
        let nodeList = [];
        const childSubNodes =  nodes.filter(node => node.parentNode === selectedNode.id) || [];
        if(childSubNodes?.length > 0){
            let optionList = [];
            childSubNodes.map((subNode,index)=>{
                let content = subNode?.data?.content || '';
                let key = subNode?.data?.key || '';
                let optionNodeId = `node${key}_${new Date().getTime()}`;
                optionList.push({
                    id : optionNodeId,
                    value : content
                })
                nodeList.push(createOptionNode(key, content, optionNodeId, nodeId));
                selectedNode.data.options = optionList
            })
        }

        nodeList.push({
            ...selectedNode,
            id : nodeId,
            position : {
                x : selectedNode.xPos + 200,
                y : 400
            },
            copyFrom : selectedNode.id,
            style: { width: 250},
            templateStarter : false
        });

        setNodes(nodes => nodes.concat(nodeList));
        setNodes((prevNodes) => prevNodes.map((val) => val.id === selectedNode.id ? {...val, selected : false}  : val));
    }

    const makeFirstMessage = (selectedNode) =>{
        setNodes((nodes) => nodes.map((node) => node.id === selectedNode.id ? {...node, templateStarter : true} : {...node, templateStarter : false} ))
    }

    const deleteNodes = (selectedNode) =>{
        setNodes((prevNodes) => prevNodes.filter((node) => node.id !== selectedNode.id));
        setNodes((prevNodes) => prevNodes.filter((node) => node.parentNode !== selectedNode.id));
        setEdges((edges) => edges.filter((edge) => edge.target !== selectedNode.id));
    }

    return(
        <Dropdown
            menu={{
                items,
            }}
        >
            <a onClick={(e) => e.preventDefault()}>
                <MoreOutlined style={{color:"white"}}/>
            </a>
        </Dropdown>
    )
}
