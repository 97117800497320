import React, {useEffect, useState} from "react";
import Flow from "./Components/Flow";
import "./styles.css";
import { NodesProvider } from "./allnode";
import {useParams} from 'react-router-dom';
import {getFlow} from "./Apis/Apis";
import {getFlowDataAction} from "./states/Actions";
import {useDispatch} from "react-redux";
import Loader from "../ResuseableComponnets/Loader";

export default function ChatBotBuilder() {
    const [state, setState] = useState({
        isLoader:false
    })
    let {id}=useParams()
    const dispatch=useDispatch()
    const getFlowById = () => {
        setState({...state, isLoader: true})
        getFlow(id).then(res=> {
            console.log(res.data)
            dispatch(getFlowDataAction(res?.data?.data))
            setState({...state, isLoader: false})
         }
        ).catch(()=>setState({...state, isLoader: false})
        )
    }

    useEffect( () => {
        getFlowById()
    }, []);

  return (
      state.isLoader ? <Loader message={""}/> :
    <div style={{width:"100%"}}>
        <NodesProvider>
            <Flow/>
        </NodesProvider>
    </div>
  );
}
