import React, {useState} from "react";
import "../ForgotPassword/ForgotPassword.css";
import Logo from "../../../assets/media/logos/custom-1.png";
import LoginPic from "../../../assets/media/misc/auth-screens.png";
import SnackBar from "../../ResuseableComponnets/SnackBar";
import {useNavigate} from "react-router-dom";

const VerifyEmail = () => {
    const [state, setState] = useState({
        token:'',
        severity: "",
        message: "",
        notificationValue: false
    })
    const navigate=useNavigate()

    const VerifyEmailApi = () => {
        !!state.token ? setState({
            severity: "error",
            message: "Token cannot be empty",
            notificationValue: !state.notificationValue,
        }) : VerifyEmailApi(state).then((res) => {
            if (res?.data?.success) {
                navigate("/");
            } else {
                setState((prevState) => ({
                    ...prevState,
                    severity: "error",
                    message: "Invalid Token", // You can customize the error message
                    notificationValue: !state.notificationValue,
                }));
            }
        })
            .catch((error) => {
                setState((prevState) => ({
                    ...prevState,
                    severity: "error",
                    message: "Error", // You can customize the error message
                    notificationValue: !state.notificationValue,
                }));
            })
    }

    return <div className="d-flex flex-column flex-root">
        {/*begin::Authentication - Password reset -->*/}
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
            {/*begin::Body-->*/}
            <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                {/*begin::Form-->*/}
                <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                    {/*begin::Wrapper-->*/}
                    <div className="w-lg-500px p-10">
                        {/*begin::Form-->*/}
                        <form className="form w-100" noValidate="novalidate" id="kt_password_reset_form">
                            {/*begin::Heading-->*/}
                            <div className="text-center mb-10">
                                {/*begin::Title-->*/}
                                <h1 className="text-dark fw-bolder mb-3">Email has been sent tour your given email.</h1>
                            </div>
                        </form>
                        {/*end::Form-->*/}
                    </div>
                    {/*end::Wrapper-->*/}
                </div>
                {/*end::Form-->*/}
                {/*begin::Footer-->*/}
                {/*end::Footer-->*/}
            </div>
            {/*end::Body-->*/}
            {/*begin::Aside-->*/}
            <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" className="first-class">
                {/*begin::Content-->*/}
                <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
                    {/*begin::Logo-->*/}
                    <a className="mb-0 mb-lg-12">
                        <img alt="Logo" src={Logo} className="h-60px h-lg-75px" />
                    </a>
                    {/*end::Logo-->*/}
                    {/*begin::Image-->*/}
                    <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-400px mb-10 mb-lg-20" src={LoginPic} alt="" />
                    {/*end::Image-->*/}
                    {/*begin::Title-->*/}
                    <h1 className="d-none d-lg-block fs-2qx fw-bolder text-center mb-7" style={{color:"white"}}>Fast, Efficient and Productive</h1>
                    {/*end::Title-->*/}
                    {/*begin::Text-->*/}
                    <div className="d-none d-lg-block fs-base text-center" style={{color:"white"}}>In this kind of post,
                        <a className="opacity-75-hover text-warning fw-bold me-1">the blogger</a>introduces a person they’ve interviewed
                        <br />and provides some background information about
                        <a className="opacity-75-hover text-warning fw-bold me-1"> the interviewee</a>and their
                        <br />work following this is a transcript of the interview.</div>
                    {/*end::Text-->*/}
                </div>
                {/*end::Content-->*/}
            </div>
            {/*end::Aside-->*/}
        </div>
        <SnackBar severity={state.severity} message={state.message} notificationValue={state.notificationValue}/>
    </div>
}

export default VerifyEmail